import React from 'react';
import { Container, Form, Segment, Header } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import './CustomForm.css';
import CustomField from './CustomField';

const CustomForm = ({
  objectId,
  name,
  description,
  fields,
  handleSubmit,
  submitting,
  error,
}) => (
  <Form onSubmit={handleSubmit} error={error !== undefined}>
    <Container text>
      <Segment basic>
        <Header
          as="h2"
          content={name}
          subheader={description}
          textAlign="center"
        />
        {fields.map(field => (
          // <div key={field.objectId}>{field.type}</div>
          <Field
            key={field.objectId}
            component={CustomField}
            label={field.label}
            name={field.objectId}
            field={field}
            options={field.options}
            type={field.type}
            required={field.required}
            validate={field.validate}
          />
        ))}

        <Form.Button loading={submitting} type="submit" primary>
          Save
        </Form.Button>
      </Segment>
    </Container>
  </Form>
);

export default reduxForm({})(CustomForm);
