import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import LogoutButtonContainer from '../LogoutButtonContainer/LogoutButtonContainer';
import './ParentDashboard.css';
// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined';

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { pathname } = window.location;
    return (
      <Responsive
        style={{ height: '100%' }}
        getWidth={getWidth}
        minWidth={Responsive.onlyTablet.minWidth}
      >
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            color="teal"
            style={{ padding: 0 }}
            textAlign="center"
            vertical
            className="main-menu"
          >
            <Menu borderless inverted color="teal" size="large">
              <Container>
                <Menu.Item
                  as={Link}
                  to="/register"
                  active={pathname === '/register'}
                >
                  Home
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/user/students"
                  active={pathname === '/user/students'}
                >
                  My Children
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/user/billing"
                  active={pathname === '/user/billing'}
                >
                  Billing
                </Menu.Item>
                <Menu.Item position="right" />
                <LogoutButtonContainer component={Menu.Item} />
              </Container>
            </Menu>
          </Segment>
        </Visibility>
        {children}
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;
    const { pathname } = window.location;
    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation="push"
          inverted
          color="teal"
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as={Link} to="/register" active={pathname === '/register'}>
            Home
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/user/students"
            active={pathname === '/user/students'}
          >
            My Children
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/user/billing"
            active={pathname === '/user/billing'}
          >
            Billing
          </Menu.Item>
          <LogoutButtonContainer component={Menu.Item} />
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            color="teal"
            textAlign="center"
            style={{ minHeight: 70, padding: '1em 0em' }}
            vertical
          >
            <Container>
              <Menu inverted secondary size="large">
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name="sidebar" />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {children}
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
  <div style={{ height: '100%' }}>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const ParentDashboard = props => (
  <ResponsiveContainer>{props.children}</ResponsiveContainer>
);

export default ParentDashboard;
