import React from "react";
import { Icon, Radio, Table } from "semantic-ui-react";

const methodTypeMapping = method => {
  if (method.type === "ach") {
    return "university";
  }
  let icon = "credit card";
  const { brand } = method;
  switch (brand) {
    case "Visa":
      icon = "cc visa";
      // code block
      break;
    case "American Express":
      icon = "cc amex";
      // code block
      break;

    default:
    // code block
  }
  return icon;
};

const PaymentMethods = ({
  selectedPaymentMethodId,
  paymentMethods,
  handlePaymentMethodChange
}) => (
  <Table>
    <Table.Body>
      {Object.keys(paymentMethods).map(methodId => (
        <Table.Row key={methodId}>
          <Table.Cell width={2}>
            <Radio
              onClick={() => handlePaymentMethodChange(methodId)}
              checked={methodId === selectedPaymentMethodId}
              name="paymentMethod"
              value={methodId}
            />
          </Table.Cell>
          <Table.Cell width={14}>
            <Icon name={methodTypeMapping(paymentMethods[methodId])} />{" "}
            {`${paymentMethods[methodId].brand ||
              paymentMethods[methodId].bankName} ending in ${
              paymentMethods[methodId].last4
            }`}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default PaymentMethods;
