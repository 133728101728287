import React from "react";
import { connect } from "react-redux";
import union from "lodash/union";
import { bindActionCreators } from "redux";
import { Selectors } from "../../../../ducks/shoppingCart";
import { Actions } from "../../../../ducks/forms";
import Models from "../../../../parse/models";
import RegistrationForms from "./RegisterForms";
const { fetchRegisterForms } = Actions.thunks;

class RegisterFormsContainer extends React.Component {
  state = { loading: false };

  fetchData = async () => {
    this.setState({ loading: true });
    const { shoppingCart } = this.props;
    const { activeStudent, registrations } = shoppingCart;
    // We need all of the
    if (registrations[activeStudent]) {
      let programIds = [];
      Object.keys(registrations).forEach(studentId => {
        programIds = union(programIds, Object.keys(registrations[studentId]));
      });

      console.log(programIds);
      const programs = programIds.map(
        programId => new Models.Program({ id: programId })
      );

      const account = Models.Account.createWithoutData(
        this.props.account.objectId
      );
      const students = Object.keys(registrations).map(studentId =>
        Models.Student.createWithoutData(studentId)
      );
      await this.props.fetchRegisterForms(programs, { account, students });
    }
    this.setState({ loading: false });
  };

  componentDidMount = () => {
    this.fetchData();
  };
  render() {
    const {
      studentForms,
      accountForms,
      account,
      shoppingCart: { activeStudent },
      formStatus
    } = this.props;
    // console.log(this.props);
    return (
      <RegistrationForms
        studentForms={studentForms}
        formStatus={formStatus}
        accountForms={accountForms}
        currentAccountId={account.objectId}
        currentStudentId={activeStudent}
        disabledContinue={!formStatus.valid}
        loading={this.state.loading}
      />
    );
  }
}

const mapStateToProps = state => ({
  // forms: state.forms,
  // programs: state.programs,
  account: state.currentAccount,
  shoppingCart: state.shoppingCart,
  studentForms: Selectors.getStudentRegistrationForms(state),
  accountForms: Selectors.getAccountRegistrationForms(state),
  formStatus: Selectors.getRequiredFormStatus(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRegisterForms
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterFormsContainer);
