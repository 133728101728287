import { createSelector } from "reselect";
import { getFormValues } from "redux-form";
import startCase from "lodash/startCase";
import Immutable from "seamless-immutable";
import { RegistrationStatusDisplay } from "../parse/models/enums";

// filterValues: getFormValues(props.formName)(state),
//     programs: Selectors.getProgramTags(state, props),
//     locations: Selectors.getLocationTags(state, props),
//     categories: Selectors.getCategoryTags(state, props),

const Private = {
  getPrograms: state => state.programs,
  getCategories: state => state.categories,
  getLocations: state => state.locations,
  getFilterValues: (state, props) => getFormValues(props.formName)(state) || {},
  createTags: (filters, programs, categories, locations) => {
    return {
      programs: (filters.programs || []).map(id => ({
        id,
        name: programs[id].name
      })),
      categories: (filters.categories || []).map(id => ({
        id,
        name: categories[id].name
      })),
      locations: (filters.locations || []).map(id => ({
        id,
        name: locations[id].name
      })),
      registrationStatus: (filters.registrationStatus || []).map(id => {
        return {
          id,
          name: RegistrationStatusDisplay[id]
        };
      })
    };
  }
};
const Selectors = {
  getTags: createSelector(
    [
      Private.getFilterValues,
      Private.getPrograms,
      Private.getCategories,
      Private.getLocations
    ],
    Private.createTags
  ),

  getProgramTags: createSelector(
    [state => state.filters.programs || [], state => state.programs],
    (filter, programs) => filter.map(id => ({ id, name: programs[id].name }))
  ),
  getCategoryTags: createSelector(
    [state => state.filters.categories || [], state => state.categories],
    (filter, categories) =>
      filter.map(id => ({ id, name: categories[id].name }))
  ),
  getLocationTags: createSelector(
    [state => state.filters.locations || [], state => state.locations],
    (filter, locations) => filter.map(id => ({ id, name: locations[id].name }))
  ),
  getRegistrationStatusTags: createSelector(
    [
      state => state.filters.registrationStatus || [],
      state => state.registrationStatus
    ],
    (filter, registrationStatus) =>
      filter.map(id => ({ id, name: registrationStatus[id].name }))
  ),
  getDayTags: createSelector(
    [state => state.filters.days || [], state => state.days],
    (filter, days) => filter.map(id => ({ id, name: startCase(id) }))
  )
};

const ActionTypes = {
  FILTER_REPORT: "FILTER_REPORT"
};

const Actions = {
  creators: {
    filterForm: data => ({
      data,
      type: ActionTypes.FILTER_REPORT
    })
  }
};

export { Actions, Selectors };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (action && action.data && action.type === ActionTypes.FILTER_REPORT) {
    return Immutable(action.data);
  }
  return state;
};
