import React from 'react';
import { Redirect } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

class RegisterContinueButton extends React.Component {
  state = {
    redirect: false,
  };
  render() {
    // console.log("RENDER REGIS CONTINUE BUTTON ", this.state);
    if (this.state.redirect) {
      return <Redirect to={this.props.to} />;
    }
    return (
      <Form.Button
        onClick={() => this.setState({ redirect: true })}
        {...this.props}
      >
        Continue
      </Form.Button>
    );
  }
}

export default RegisterContinueButton;
