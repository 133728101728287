import React from 'react';
import { Form, Segment, Header } from 'semantic-ui-react';
import { reduxForm, Field } from 'redux-form';

import NumberFormatter from '../../NumberFormatter';
import Checkbox from '../../Checkbox';

const FeeForm = ({ handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <Segment basic>
      <Header>
        <Header.Content>
          Fee
          <Header.Subheader>
            Fees are used to collect annual registration fees and material fees
          </Header.Subheader>
        </Header.Content>
      </Header>
      <p />
      <Field name="name" label="Fee Name" component={Form.Input} />
      <div className="field">
        <label>Amount</label>
        <div className="ui input">
          <Field
            name="amount"
            settings={{
              // style: { maxWidth: '12em' },
              thousandSeparator: true,
              prefix: '$',
              // defaultValue: '0.00',
              placeholder: '$0.00',
            }}
            component={NumberFormatter}
          />
        </div>
      </div>
      <div className="field">
        <label>Additional Students Amount</label>
        <div className="ui input">
          <Field
            name="amountAdditionalStudents"
            settings={{
              // style: { maxWidth: '12em' },
              thousandSeparator: true,
              prefix: '$',
              // defaultValue: '0.00',
              placeholder: '$0.00',
            }}
            component={NumberFormatter}
          />
        </div>
      </div>
      <div className="field">
        <Field name="oneTime" label="One Time Fee" component={Checkbox} />
      </div>
      <Form.Button loading={submitting} content="Save" primary fluid />
    </Segment>
  </Form>
);

export default reduxForm({
  form: 'feeForm',
})(FeeForm);
