import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ParentDashboard from '../../ParentDashboard';
import RegisterContainer from '../../RegisterContainer/RegisterContainer';

const ParentRoutes = () => (
  <ParentDashboard>
    <Switch>
      <Route
        path="/register"
        component={RegisterContainer}
        // render={props => <RegisterContainer {...props} />}
      />
      <Route
        exact
        path="/user/account"
        render={props => {
          return <div>Account</div>;
        }}
      />
      <Route
        exact
        path="/user/students"
        render={props => {
          console.log(props);
          return <div>Students</div>;
        }}
      />
    </Switch>
  </ParentDashboard>
);

export default ParentRoutes;
