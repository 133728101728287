import React from "react";
import { Table, Segment, Container } from "semantic-ui-react";
import moment from "moment";

const StudentRegistrations = ({ registrations }) => {
  return (
    <Container fluid>
      <Segment basic color="grey">
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Registrations</Table.HeaderCell>
              <Table.HeaderCell>Registration Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {registrations.map(registration => (
              <Table.Row key={registration.objectId}>
                <Table.Cell>{registration.program.name}</Table.Cell>
                <Table.Cell>
                  {moment(registration.createdAt).format("MMM DD, YYYY")}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </Container>
  );
};

StudentRegistrations.propTypes = {};

export default StudentRegistrations;
