import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import Dropdown from '../../../../Dropdown';

const renderLabel = handleClick => label => ({
  color: 'purple',
  content: label.text,
  icon: 'pencil',
  onClick: () => handleClick(label.value),
});

const ChoicesField = props => {
  // console.log('PROPS: ', props);
  return (
    <Form.Field>
      <Field
        component={Dropdown}
        multiple
        selection
        search
        fluid
        allowAdditions
        onAddItem={props.onAddItem}
        options={props.options}
        // placeholder="Start typing to add a new option"
        noResultsMessage="Start typing to add a new choice"
        renderLabel={renderLabel(props.editLabel)}
        name={props.name}
      />
    </Form.Field>
  );
};

ChoicesField.propTypes = {};

export default ChoicesField;
