const DefaultCellRenderer = ({
  cellData,
  columnData,
  columnIndex,
  dataKey,
  rowData,
  rowIndex,
  isScrolling,
}) => {
  // if (isScrolling) {
  //   return "";
  // }
  return cellData;
};

export default DefaultCellRenderer;
