import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ShoppingCart from "./ShoppingCart/ShoppingCart";
import { Selectors } from "../../../../ducks/shoppingCart";

const mapStateToProps = state => ({
  paymentSummary: Selectors.getPaymentSummary(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
