import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import NumberFormatter from '../../../NumberFormatter';

const CourseAmount = () => {
  return (
    <Form.Group>
      <Form.Field width="4">
        <label>Invoice Amount</label>

        <Field
          name={`amount`}
          settings={{
            // style: { maxWidth: '12em' },
            thousandSeparator: true,
            prefix: '$',
            // defaultValue: '0.00',
            placeholder: '$0.00',
          }}
          component={NumberFormatter}
        />
      </Form.Field>
    </Form.Group>
  );
};

export default CourseAmount;
