import React from 'react';
import { Form, Segment } from 'semantic-ui-react';
import { reduxForm, Field } from 'redux-form';

import NumberFormatter from '../../NumberFormatter';

const ChoiceForm = ({ handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <Segment basic>
      <Field name="name" label="Choice Name" required component={Form.Input} />
      <Form.Field>
        <label>Capacity</label>
        <Field name="capacity" component={NumberFormatter} />
      </Form.Field>
      <Form.Field>
        <label>Additional Fee</label>
        <Field name="amount" component={NumberFormatter} />
      </Form.Field>
      <Form.Button loading={submitting} content="Save" primary fluid />
    </Segment>
  </Form>
);

export default reduxForm({
  form: 'choiceForm',
})(ChoiceForm);
