import Immutable from 'seamless-immutable';
import { normalize } from 'normalizr';
import { createSelector } from 'reselect';
import Parse from 'parse';
import orderBy from 'lodash/orderBy';

import { SubmissionError } from 'redux-form';
import schema from '../schema';
import Models from '../parse/models';

const ActionTypes = {
  ADD_LOCATION: 'ADD_LOCATION',
  RECEIVED_LOCATIONS: 'RECEIVED_LOCATIONS',
};

const Selectors = {
  getLocationOptions: createSelector(
    [state => state.locations],
    obj =>
      orderBy(
        Object.keys(obj).map(key => ({
          key,
          text: obj[key].name,
          value: key,
        })),
        ['text'],
      ),
  ),
};
const Actions = {
  creators: {
    addLocation: data => ({
      data,
      type: ActionTypes.ADD_LOCATION,
    }),

    receivedLocations: data => ({
      data,
      type: ActionTypes.RECEIVED_LOCATIONS,
    }),
  },
  thunks: {
    addLocation: data => async dispatch => {
      try {
        const location = new Models.Location();
        await location.save(data);
        const result = normalize(location.toJSON(), schema.location);
        dispatch(Actions.creators.addLocation(result));
        return location.toJSON();
      } catch (error) {
        const err = {
          _error: error.message,
        };
        throw new SubmissionError(err);
      }
    },
    fetchLocations: business => async dispatch => {
      try {
        const query = new Parse.Query(Models.Location);
        query.equalTo('business', business);
        const locations = await query.find();
        return dispatch(
          Actions.creators.receivedLocations(
            normalize(
              locations.map(location => location.toJSON()),
              schema.locations,
            ),
          ),
        );
      } catch (error) {
        // const err = {
        //   _error: error.message,
        // };
        console.error(error);
      }
    },
  },
};

export { Actions, Selectors };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.locations
  ) {
    return state.merge(action.data.entities.locations);
  }
  return state;
};
