import React from "react";
import { Field } from "redux-form";
import { Form } from "semantic-ui-react";
import startCase from "lodash/startCase";
import ProgramsFilterContainer from "../ProgramsFilterContainer";
import CategoriesFilterContainer from "../CategoriesFilterContainer";
import LocationsFilterContainer from "../LocationsFilterContainer";
import {
  RegistrationStatus,
  RegistrationStatusDisplay
} from "../../parse/models/enums";

const MultipleDropdown = props => (
  <Form.Dropdown
    onChange={(e, d) => props.input.onChange(d.value)}
    selection
    {...props}
    value={props.input.value || []}
  />
);

const FilterForm = props => {
  // console.log(props.days);
  const { filters } = props;
  return (
    <div className="ui form">
      {filters.indexOf("programs") >= 0 && (
        <Field
          name="programs"
          multiple
          label="Program"
          component={ProgramsFilterContainer}
        />
      )}
      {filters.indexOf("programs") >= 0 && (
        <Field
          name="categories"
          multiple
          label="Category"
          component={CategoriesFilterContainer}
        />
      )}
      {filters.indexOf("locations") >= 0 && (
        <Field
          name="locations"
          multiple
          label="Location"
          component={LocationsFilterContainer}
        />
      )}
      {filters.indexOf("registrationStatus") >= 0 && (
        <Field
          name="registrationStatus"
          multiple
          label="Registration Status"
          options={[
            {
              key: RegistrationStatus.enrolled,
              text: RegistrationStatusDisplay.enrolled,
              value: RegistrationStatus.enrolled
            },
            {
              key: RegistrationStatus.waitlisted,
              text: RegistrationStatusDisplay.waitlisted,
              value: RegistrationStatus.waitlisted
            },
            {
              key: RegistrationStatus.pendingApproval,
              text: RegistrationStatusDisplay.pendingApproval,
              value: RegistrationStatus.pendingApproval
            },
            {
              key: RegistrationStatus.pendingDiscount,
              text: RegistrationStatusDisplay.pendingDiscount,
              value: RegistrationStatus.pendingDiscount
            }
          ]}
          component={MultipleDropdown}
        />
      )}
      {filters.indexOf("days") >= 0 && (
        <Field
          name="days"
          multiple
          label="Days"
          options={props.days.map(day => ({
            key: day,
            value: day,
            text: startCase(day)
          }))}
          component={MultipleDropdown}
        />
      )}
    </div>
  );
};

export default FilterForm;
