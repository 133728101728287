import React from 'react';
import Parse from 'parse';
import ImageUpload from '../../../ImageUpload';

class CustomFormFileField extends React.Component {
  handleChange = file => {
    const name = 'logo.jpg';
    this.props.input.onChange(new Parse.File(name, file));
  };
  render() {
    const { input } = this.props;
    const url =
      input.value && input.value.url && typeof input.value.url === 'string'
        ? input.value.url
        : null;
    return <ImageUpload url={url} onChange={this.handleChange} />;
  }
}

export default CustomFormFileField;
