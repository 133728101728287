import Immutable from "seamless-immutable";
import { normalize } from "normalizr";
import Parse from "parse";

import { SubmissionError } from "redux-form";
import schema from "../schema";
import Models from "../parse/models";

const ActionTypes = {
  UPSET_ACCOUNT_SETTINGS: "UPSET_ACCOUNT_SETTINGS",
  RECEIVED_ACCOUNT_SETTINGS: "RECEIVED_ACCOUNT_SETTINGS"
};

const Actions = {
  creators: {
    upsertAccountSettings: data => ({
      data,
      type: ActionTypes.UPSET_ACCOUNT_SETTINGS
    }),

    receivedAccountSettings: data => ({
      data,
      type: ActionTypes.RECEIVED_ACCOUNT_SETTINGS
    })
  },
  thunks: {
    upsertAccountSettings: data => async dispatch => {
      try {
        const accountSettings = new Models.AccountSettings();
        await accountSettings.save(data);
        const result = normalize(
          accountSettings.toJSON(),
          schema.accountSettings
        );
        dispatch(Actions.creators.upsertAccountSettings(result));
        return accountSettings.toJSON();
      } catch (error) {
        const err = {
          _error: error.message
        };
        throw new SubmissionError(err);
      }
    },
    fetchAccountSettings: account => async dispatch => {
      try {
        const query = new Parse.Query(Models.AccountSettings);
        query.equalTo("account", account);
        const accountSettings = await query.first();
        if (accountSettings) {
          dispatch(
            Actions.creators.receivedAccountSettings(
              normalize(accountSettings.toJSON(), schema.accountSettings)
            )
          );
        }
        return accountSettings;
      } catch (error) {
        // const err = {
        //   _error: error.message,
        // };
        console.error(error);
      }
    }
  }
};

export { Actions };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.accountSettings &&
    action.data.result
  ) {
    return Immutable(action.data.entities.accountSettings[action.data.result]);
  }
  return state;
};
