import reduce from "lodash/reduce";
import SubTotals from "./subTotals";
import { formatCurrencyDisplay } from "../formatCurrrency";
const Public = {
  getPaymentSummary: (
    registrations,
    students,
    programs,
    discounts,
    fees,
    paymentMethods,
    businessSettings,
    paymentMethodId
  ) => {
    if (Object.keys(programs).length === 0) {
      return {};
    }
    const paymentMethod = paymentMethods[paymentMethodId];
    // console.log("GET PAYMENT SUMMARY");

    const items = SubTotals.getItems(
      registrations,
      students,
      programs,
      discounts,
      fees
    );

    // console.log(items);

    const amount = reduce(items, (sum, item) => sum + item.amount, 0);
    const amountDisplay = formatCurrencyDisplay(amount, true, "usd");

    // This is the transaction amount
    // If the customer pays a fee, it gets added to the charge
    // if the business pays a fee - that does not get added to the charge

    // Transaction amount = sum of all invoices
    // Charge amounts includes any fees paid for by the customer
    // Fee amount = total fee that includes the business and the customer
    console.log("Business Settingsz: ", businessSettings);

    let charge = {
      amount,
      amountDisplay,
      feeAmount: 0
    };

    const { processingFee } = businessSettings;
    if (processingFee) {
      console.log(processingFee, paymentMethod);
      const feeDefinition = paymentMethod
        ? processingFee[paymentMethod.type]
        : null;
      console.log("FEE DEFGINI: ", feeDefinition);
      if (feeDefinition) {
        const feeRate = feeDefinition.percent / 10000;
        // console.log(feeRate);
        const feeAmount = amount * feeRate + feeDefinition.amount;

        charge.amount = feeAmount + amount;
        charge.feeAmount = feeAmount;
        charge.feeAmountDisplay = formatCurrencyDisplay(
          charge.feeAmount,
          true,
          "usd"
        );
        charge.amountDisplay = formatCurrencyDisplay(
          charge.amount,
          true,
          "usd"
        );
        charge.feeName = processingFee.name;
        console.log(amount);
        console.log(processingFee[paymentMethod.type]);
      }
    }

    // console.log(items);
    const result = {
      items,
      amount,
      amountDisplay,
      charge
    };
    // console.log(result);
    return result;
  }
};

export default Public;
