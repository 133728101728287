import React from 'react';
import ProgramDiscountsContainer from './ProgramDiscountsContainer';
import ProgramSetupSection from '../../ProgramSetupSection';

const ProgramDiscounts = () => (
  <ProgramSetupSection header="Discounts">
    <ProgramDiscountsContainer />
  </ProgramSetupSection>
);

export default ProgramDiscounts;
