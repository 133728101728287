import React from "react";
import { Container, Segment } from "semantic-ui-react";
import VisibleFormFieldsContainer from "../../VisibleFormFieldsContainer";
import Spreadsheet from "../../Spreadsheet";
import utils from "../../../utils";
import RegistrationsFilterContainer from "../RegistrationsFilterContainer";

const Registrations = ({ cols, data, isLoading, handleFilter, submit }) => {
  return (
    <Container fluid>
      <Container fluid>
        <Segment basic>
          <VisibleFormFieldsContainer report="registration" />
          <p />
          <RegistrationsFilterContainer
            isLoading={isLoading}
            onSubmit={handleFilter}
            onChange={value => {
              if (value.search) {
                utils.delay(() => submit("registrationsFiltersForm"), 500)();
              } else {
                submit("registrationsFiltersForm");
              }
            }}
            filterFields={["registrationStatus", "days"]}
            days={["mon", "tue", "wed"]} // todo: make this the actual meeting days for the program
          />
        </Segment>
      </Container>
      <Container fluid>
        <Segment basic>
          <Spreadsheet
            cols={cols}
            data={data}
            rowHeight={60}
            loading={isLoading}
          />
        </Segment>
      </Container>
    </Container>
  );
};

export default Registrations;
