import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Dropdown,
  Icon,
  Image,
  Menu,
  Segment
} from "semantic-ui-react";
import PropTypes from "prop-types";
import LogoutButtonContainer from "../../LogoutButtonContainer/LogoutButtonContainer";

const TopbarContainer = props => (
  <Segment
    basic
    style={{
      padding: 0,
      margin: 0
    }}
  >
    <Menu
      borderless
      style={{
        borderWidth: "0px 0px 1px 0px",
        borderRadius: 0
      }}
      size="small"
    >
      <Container fluid>
        <Menu.Item as="a" header onClick={props.toggleMenu}>
          <Icon name="sidebar" size="big" />
        </Menu.Item>

        {/* <Menu.Item position="right"> */}
        <Dropdown
          trigger={
            <Menu.Item style={{ padding: 0 }}>
              <Image
                style={{ paddingRight: "1em" }}
                circular
                size="mini"
                src={
                  props.business &&
                  props.business.logo &&
                  props.business.logo.url
                }
                // onError={() =>
                //   'https://react.semantic-ui.com/images/wireframe/image.png'
                // }
              />
              {props.business && props.business.name}
            </Menu.Item>
          }
          // pointing
          style={{ marginRight: "2em" }}
          className="link right item"
        >
          <Dropdown.Menu>
            <Dropdown.Item>My Profile</Dropdown.Item>
            <Dropdown.Item as={Link} to="/business/settings">
              Business Settings
            </Dropdown.Item>

            <Dropdown.Divider />

            <LogoutButtonContainer component={Dropdown.Item} />
          </Dropdown.Menu>
        </Dropdown>
        {/* </Menu.Item> */}
      </Container>
    </Menu>
  </Segment>
);

TopbarContainer.defaultProps = {
  handleToggleMenuClick: () => false
};
TopbarContainer.propTypes = {
  handleToggleMenuClick: PropTypes.func
};

const mapStateToProps = state => ({
  business: state.business
});

export default connect(mapStateToProps)(TopbarContainer);
