import React from "react";
// import PropTypes from 'prop-types';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CustomForm from "./CustomForm";

import { Actions, Selectors } from "../../ducks/formValues";
const { upsertFormValue } = Actions.thunks;

class CustomFormContainer extends React.Component {
  handleSubmit = data => {
    // console.log(data);
    // console.log(this.props);
    return this.props.upsertFormValue(
      data,
      this.props.originalFormValue,
      this.props.objectId,
      this.props.studentId,
      this.props.accountId
    );
  };

  render() {
    const { props } = this;
    return (
      <CustomForm
        form={props.objectId}
        {...props}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const getFormFieldValues = Selectors.getFormFieldValues();
  const getOriginalFormValue = Selectors.getOriginalFormValue();
  const mapStateToProps = (state, props) => {
    return {
      enableReinitialize: true,
      initialValues: getFormFieldValues(state, props),
      originalFormValue: getOriginalFormValue(state, props)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      upsertFormValue
    },
    dispatch
  );
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CustomFormContainer);
