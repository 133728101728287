import React from "react";
import { Container, Segment, Grid, Button } from "semantic-ui-react";

class Overlay extends React.PureComponent {
  state = { isOpen: this.props.open || false };
  componentDidUpdate = prevProps => {
    if (prevProps.isOpen !== this.props.open) {
      this.setState({ isOpen: this.props.open });
    }
  };
  handleClose = () => {
    if (this.props.handleClose) {
      this.props.handleClose();
    } else {
      this.setState({ isOpen: false });
    }
  };
  render = () => {
    const { isOpen } = this.state;
    const { content } = this.props;
    return (
      <Container fluid style={{ position: "relative" }}>
        {isOpen && (
          <Container
            fluid
            style={{
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "#fff",
              zIndex: 9999
            }}
          >
            <Segment basic style={{ paddingBottom: 0, marginBottom: 0 }}>
              <Grid>
                <Grid.Column floated="right">
                  <Button
                    // size="huge"
                    // color="black"
                    type="button"
                    onClick={this.handleClose}
                    icon="cancel"
                    floated="right"
                    style={{ marginRight: "1em", marginTop: "1em" }}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
            {content}
          </Container>
        )}
        <Container fluid>{this.props.children}</Container>
      </Container>
    );
  };
}

Overlay.propTypes = {};

export default Overlay;
