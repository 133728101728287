const formatCurrency = (value, isLowest = true, currency = "usd") => {
  if (!value || value === "") {
    return value;
  }
  if (currency === "usd") {
    if (isLowest) {
      return value / 100;
    }
    return value * 100;
  }
  return value;
};

const formatCurrencyDisplay = (value, isLowest = true, currency = "usd") => {
  const val = formatCurrency(value, isLowest, currency);
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(val);
};

export { formatCurrencyDisplay };

export default formatCurrency;
