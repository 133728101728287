const StaffType = {
  owner: "owner",
  admin: "admin",
  director: "director",
  teacher: "teacher"
};

const StaffStatus = {
  active: "Active",
  pending: "Pending Approval",
  inactive: "Inactive"
};

export { StaffStatus, StaffType };

export default StaffType;
