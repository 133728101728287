import Immutable from 'seamless-immutable';
import { ActionTypes } from './currentUser';

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (action && action.type === ActionTypes.LOGIN_USER) {
    if (action.data.result.currentStaff) {
      return Immutable(
        action.data.entities.staff[action.data.result.currentStaff],
      );
    }
  } else if (action.type === ActionTypes.LOGOUT_USER) {
    return defaultState;
  }
  return state;
};
