import Immutable from 'seamless-immutable';
import { normalize } from 'normalizr';
import Parse from 'parse';
import { createSelector } from 'reselect';

import { SubmissionError } from 'redux-form';
import schema from '../schema';
import Models from '../parse/models';
import { PlanInterval } from '../parse/models/enums';
import moment from 'moment';

const ActionTypes = {
  ADD_SUBSCRIPTION: 'ADD_SUBSCRIPTION',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  RECEIVED_SUBSCRIPTIONS: 'RECEIVED_SUBSCRIPTIONS',
};

const Private = {
  getSubscriptions: state => state.subscriptions,
  getPrograms: state => state.programs,
  getAccounts: state => state.accounts,
  getUsers: state => state.users,
  getStudents: state => state.students,
  createSubscriptionsSummary: (
    subscriptions,
    programs,
    accounts,
    users,
    students,
  ) => {
    // console.log(subscriptions);
    console.log(students);
    let results = {};
    Object.keys(subscriptions).forEach(objectId => {
      const subscription = subscriptions[objectId];

      // const today = moment();
      // console.log(today);
      if (subscription.planInterval === PlanInterval.monthly) {
      } else if (subscription.planInterval === PlanInterval.weekly) {
      }

      // console.log(subscription);

      const student = students[subscription.student];
      const program = programs[subscription.program];
      results[objectId] = {
        objectId,
        studentName: `${student.firstName} ${student.lastName}`,
        planInterval:
          subscription.planInterval === PlanInterval.weekly
            ? 'Weekly'
            : subscription.planInterval === PlanInterval.monthly
            ? 'Monthly'
            : 'One-time',
        amount: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(subscription.amount / 100),
        nextDueDate: moment().format('MMM D, YYYY'),
        planEndDate: moment(subscription.planEndDate).format('MMM D, YYYY'),
        program: program.name,
        manage: {
          objectId,
        },
      };
    });
    return results;
  },
};

// View next
// Edit Plan
// Pause Plan
// Cancel Plan

const Selectors = {
  getSubscriptions: createSelector(
    [
      Private.getSubscriptions,
      Private.getPrograms,
      Private.getAccounts,
      Private.getUsers,
      Private.getStudents,
    ],
    Private.createSubscriptionsSummary,
  ),
};

const Actions = {
  creators: {
    addSubscription: data => ({
      data,
      type: ActionTypes.ADD_SUBSCRIPTION,
    }),
    updateSubscription: data => ({
      data,
      type: ActionTypes.UPDATE_SUBSCRIPTION,
    }),
    receivedSubscriptions: data => ({
      data,
      type: ActionTypes.RECEIVED_SUBSCRIPTIONS,
    }),
  },
  thunks: {
    addSubscription: data => async dispatch => {
      try {
        const subscription = new Models.Subscription();
        await subscription.save(data);
        const result = normalize(subscription.toJSON(), schema.subscription);
        dispatch(Actions.creators.addSubscription(result));
        return subscription.toJSON();
      } catch (error) {
        const err = {
          _error: error.message,
        };
        throw new SubmissionError(err);
      }
    },
    updateSubscription: ({
      objectId,
      planEndDate,
      amount,
    }) => async dispatch => {
      const subscription = Models.Subscription.createWithoutData(objectId);
      await subscription.save({ planEndDate, amount });
      const result = normalize(subscription.toJSON(), schema.subscription);
      dispatch(Actions.creators.updateSubscription(result));
      return result;
    },
    fetchSubscriptions: business => async dispatch => {
      try {
        const query = new Parse.Query(Models.Subscription);
        query.equalTo('business', business);
        query.include('account');
        query.include('account.user');
        query.include('registration');
        query.include('program');
        query.include('student');
        const subscriptions = await query.find();
        return dispatch(
          Actions.creators.receivedSubscriptions(
            normalize(
              subscriptions.map(subscription => subscription.toJSON()),
              schema.subscriptions,
            ),
          ),
        );
      } catch (error) {
        // const err = {
        //   _error: error.message,
        // };
        console.error(error);
      }
    },
  },
};

export { Actions, Selectors };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.subscriptions
  ) {
    return state.merge(action.data.entities.subscriptions);
  }
  return state;
};
