import React from 'react';

import CourseAmount from './CourseAmount';
import DailyAmount from './DailyAmount';
import { RegistrationType } from '../../../parse/models/enums';

const AmountField = ({ registrationType, planInterval, days }) => {
  if (registrationType === RegistrationType.course) {
    return <CourseAmount />;
  }
  return <DailyAmount days={days || []} />;
};

AmountField.propTypes = {};

export default AmountField;
