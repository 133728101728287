import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import { Container, Button } from 'semantic-ui-react';
import FormModal from '../../../../FormModal';
import DiscountFormContainer from '../../../../DiscountFormContainer/DiscountFormContainer';

import { Actions } from '../../../../../ducks/discounts';
import Models from '../../../../../parse/models';
import CheckboxArray from '../../../../CheckboxArray';

const { fetchDiscounts } = Actions.thunks;

// Selectors
const orderDiscounts = state => {
  const discounts = sortBy(
    Object.keys(state.discounts).map(key => {
      const discount = state.discounts[key];
      return discount;
      // return {
      //   key: discount.objectId,
      //   text: discount.name,
      //   value: discount.objectId,
      // };
    }),
    ['name'],
  );
  return discounts;
};
const getDiscounts = () => {
  return createSelector(
    orderDiscounts,
    discounts => discounts,
  );
};
// =============

class ProgramDiscountsContainer extends React.Component {
  state = {
    modalOpen: false,
  };

  closeDiscountModal = () => {
    this.setState({
      modalOpen: false,
    });
  };
  handleDiscountComplete = discount => {
    this.closeDiscountModal();
  };
  handleAddition = (e, d) => {
    this.setState({
      modalOpen: true,
      discountName: d.value,
    });
  };

  componentDidMount() {
    if (this.props.business && this.props.business.objectId) {
      this.props.fetchDiscounts(new Models.Business({id: this.props.business.objectId}));
    }
  }
  render() {
    const { discounts } = this.props;
    const { loading } = this.state;
    return (
      <div className="field">
        <FormModal
          open={this.state.modalOpen}
          close={this.closeDiscountModal}
          modal={{
            className: 'modal-form',
            // size: 'small'
          }}
        >
          <Container>
            <DiscountFormContainer
              handleComplete={this.handleDiscountComplete}
            />
          </Container>
        </FormModal>

        <Field name="discounts" options={discounts} component={CheckboxArray} />

        <Button
          type="button"
          loading={loading}
          onClick={() => this.setState({ modalOpen: true })}
        >
          Add Discount
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const discountsSelector = getDiscounts();
  return {
    business: state.business,
    discounts: discountsSelector(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDiscounts,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramDiscountsContainer);
