import DefaultCellRenderer from '../Spreadsheet/DefultCellRenderer';
import SubscriptionEditCellRenderer from './SubscriptionEditCellRenderer';

const cols = [
  {
    dataKey: 'studentName',
    label: 'Student',
    minWidth: 90,
    flexGrow: 1,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'planInterval',
    label: 'Plan',
    width: 90,
    minWidth: 90,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'program',
    label: 'Program',
    minWidth: 90,
    flexGrow: 1,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'amount',
    label: 'Amount',
    width: 90,
    minWidth: 90,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'nextDueDate',
    label: 'Next Due Date',
    width: 130,
    minWidth: 130,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'planEndDate',
    label: 'Plan End Date',
    width: 130,
    minWidth: 130,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'objectId',
    label: 'Actions',
    width: 140,
    minWidth: 140,
    cellRenderer: SubscriptionEditCellRenderer,
    className: 'edit-cell-no-overflow',
    handleCellChange: true,
    // flexGrow: 1,
  },
];

export default cols;
