import React from "react";
import Parse from "parse";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AvatarEditor from "../../../../AvatarEditor";
import { Actions } from "../../../../../ducks/students";
import Models from "../../../../../parse/models";

const { updateStudent } = Actions.thunks;

class AvatarContainer extends React.Component {
  state = { loading: false };
  handleChange = file => {
    const name = "logo.jpg";
    const avatar = new Parse.File(name, file);
    const { studentId } = this.props;
    const student = Models.Student.createWithoutData(studentId);
    this.setState({ loading: true });
    this.props
      .updateStudent(student, { avatar })
      .then(() => this.setState({ loading: false }));
  };
  render() {
    const { initials, url } = this.props;
    return (
      <AvatarEditor
        // loading={this.props.loading}
        loading={this.state.loading}
        url={url}
        initials={initials}
        onChange={this.handleChange}
      />
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateStudent
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(AvatarContainer);
