import Immutable from 'seamless-immutable';
import Parse from 'parse';
import { normalize } from 'normalizr';
import schema from '../schema';

import Models from '../parse/models';

const ActionTypes = {
  RECEIVED_FIELD_VALUES: 'RECEIVED_FIELD_VALUES',
  SAVED_FIELD_VALUES: 'SAVED_FIELD_VALUES',
};

const Actions = {
  creators: {
    receivedFieldValues: data => ({
      data,
      type: ActionTypes.RECEIVED_FIELD_VALUES,
    }),
    savedFieldValues: data => ({
      data,
      type: ActionTypes.SAVED_FIELD_VALUES,
    }),
  },
  thunks: {
    fetchVisibleFieldValues: (studentIds, fieldIds) => async dispatch => {
      // const fields = await new Parse.Query(Models.FieldValue).containedIn('student', students).containedIn('field', fields).limit(1000).find();
      const results = await Parse.Cloud.run('reportData', {
        studentIds,
        fieldIds,
      });
      dispatch(
        Actions.creators.receivedFieldValues(
          normalize(results.map(fv => fv.toJSON()), schema.fieldValues),
        ),
      );
    },
    fetchFieldValues: (fields, account, student) => async dispatch => {
      const query = new Parse.Query(Models.FieldValue);
      query.containedIn('field', fields);
      if (account) {
        // console.log('query account');
        query.equalTo('account', account);
      }
      if (student) {
        // console.log('query student');
        query.equalTo('student', student);
      }
      const fieldValues = await query.find();
      dispatch(
        Actions.creators.receivedFieldValues(
          normalize(fieldValues.map(fv => fv.toJSON()), schema.fieldValues),
        ),
      );
      // console.log('==================== ', fieldValues);
    },
    saveFieldValues: (data, formId, studentId, accountId) => async dispatch => {
      // console.log(data);
      // // if (data.currentUser);
      // if (data.currentUser) {

      // }
      const fieldValues = Object.keys(data).map(fieldId => {
        const fieldValue = new Models.FieldValue();
        const field = new Models.Field({ id: fieldId });

        fieldValue.set('field', field);
        fieldValue.set(field.get('type'), data[fieldId]);
        fieldValue.set('type', field.get('type'));
        const form = Models.Form.createWithoutData(formId);
        fieldValue.set('form', form);
        if (field.get('type') !== 'upload') {
          if (field.get('type') === 'date') {
            fieldValue.set('value', data[fieldId].toISOString());
          } else {
            fieldValue.set('value', `${data[fieldId]}`);
          }
        }
        fieldValue.set('label', field.get('label'));
        fieldValue.set('active', true);

        if (studentId) {
          fieldValue.set('student', new Models.Student({ id: studentId }));
        }
        if (accountId) {
          fieldValue.set('account', new Models.Account({ id: accountId }));
        }
        console.log(fieldValue);
        return fieldValue;
      });
      await Parse.Object.saveAll(fieldValues);
      dispatch(
        Actions.creators.savedFieldValues(
          normalize(fieldValues.map(fv => fv.toJSON()), schema.fieldValues),
        ),
      );

      return true;

      // return data;
    },
  },
};
export { Actions };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.fieldValues
  ) {
    return state.merge(action.data.entities.fieldValues);
  }
  return state;
};
