import React from 'react';
import ChoicesField from '../ChoicesField';

const CourseChoices = props => {
  const { visible } = props;
  if (!visible) {
    return <React.Fragment />;
  }
  return (
    <ChoicesField
      onAddItem={props.onAddItem}
      options={props.options}
      editLabel={props.editLabel}
      name="choices"
    />
  );
};

export default CourseChoices;
