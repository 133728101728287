import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../../ducks/students';
import StudentForm from './StudentForm';
import Models from '../../../parse/models';

const { addStudent } = Actions.thunks;

class StudentFormContainer extends React.Component {
  handleSubmit = args => {
    const account = Models.Account.createWithoutData(
      this.props.account.objectId,
    );
    const business = Models.Business.createWithoutData(
      this.props.business.objectId,
    );
    business.id = this.props.business.objectId;
    return this.props.addStudent(Object.assign({ account, business }, args));
  };

  render() {
    return (
      <StudentForm
        onSubmit={this.handleSubmit}
        initialValues={{
          active: true,
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.currentAccount,
    business: state.business,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addStudent }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudentFormContainer);
