import React from "react";
import { Segment, Table, Header } from "semantic-ui-react";
import shortid from "shortid";
import RegisterPaymentItemContainer from "./RegisterPaymentItemContainer";

const RegisterPaymentitems = ({ items, amountDisplay }) => {
  return (
    <Segment basic>
      <Table singleLine color="teal">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">Selections</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Amount Due</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items &&
            items.map(item => (
              <RegisterPaymentItemContainer
                key={shortid.generate()}
                item={item}
              />
            ))}
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Grand Total: </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {amountDisplay && <Header as="h5">{amountDisplay}</Header>}
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

export default RegisterPaymentitems;
