import React from 'react';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import Models from '../../../../../parse/models';

import { Actions } from '../../../../../ducks/categories';

const { addCategory, fetchCategories } = Actions.thunks;

// Selectors
const orderCategories = state => {
  // console.log('States: ', state);
  const categories = sortBy(
    Object.keys(state.categories).map(key => {
      const category = state.categories[key];
      // console.log('Category: ', category);
      return {
        key: category.objectId,
        text: category.name,
        value: category.objectId,
      };
    }),
    ['text'],
  );
  return categories;
};
const getCategories = () => {
  return createSelector(
    orderCategories,
    categories => categories,
  );
};
// =============

class ProgramCategoryContainer extends React.Component {
  state = {
    loading: false,
  };
  handleAddition = (e, { value }) => {
    this.setState({ loading: true });
    const business = new Models.Business({ id: this.props.business.objectId });
    const data = { business, name: value };
    this.props.addCategory(data).then(category => {
      this.setState({ loading: false });
      this.props.input.onBlur(category.objectId);
      this.props.input.onChange(category.objectId);
    });
  };

  componentDidMount() {
    if (this.props.business && this.props.business.objectId) {
      this.props.fetchCategories(
        new Models.Business({ id: this.props.business.objectId }),
      );
    }
  }
  render() {
    const { loading } = this.state;
    const { categories, input } = this.props;

    return (
      <Form.Dropdown
        onAddItem={this.handleAddition}
        onChange={(e, data) => {
          this.props.input.onChange(data.value);
          return data.value;
        }}
        label="Category"
        fluid
        search
        selection
        allowAdditions
        noResultsMessage={null}
        options={categories}
        loading={loading}
        disabled={loading}
        name={input.name}
        value={input.value}
      />
    );
  }
}

const mapStateToProps = state => {
  const categoriesSelector = getCategories();
  return {
    business: state.business,
    categories: categoriesSelector(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addCategory,
      fetchCategories,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramCategoryContainer);
