import React from 'react';
import { Form } from 'semantic-ui-react';
import DatePicker from '../../../DatePicker/DatePicker';
import NumberFormatter from '../../../NumberFormatter';
import CustomFormFileField from '../CustomFormFileField';

const fieldTypes = {
  Upload: 'upload',
  Short: 'short',
  Long: 'long',
  MultipleSelect: 'multipleSelect',
  SingleSelect: 'singleSelect',
  Date: 'date',
  Email: 'email',
  Phone: 'phone',
};

class CustomField extends React.Component {
  render() {
    const {
      type,
      required,
      meta: { touched, error },
      ...props
    } = this.props;
    // console.log(this.props);
    let Component;
    let specialProps = {
      className: required ? 'is-required' : '',
      error: error !== undefined && touched ? error : false,
    };
    const { value } = props.input;
    switch (type) {
      case fieldTypes.Upload:
        specialProps.control = CustomFormFileField;
        Component = Form.Field;
        break;
      case fieldTypes.Short:
        Component = Form.Input;
        break;
      case fieldTypes.Long:
        specialProps.onChange = (e, d) => this.props.input.onChange(d.value);
        specialProps.value = value;
        Component = Form.TextArea;
        break;
      case fieldTypes.MultipleSelect:
        specialProps.multiple = true;
        specialProps.onChange = (e, d) => this.props.input.onChange(d.value);
        specialProps.options = props.options || [];
        specialProps.value = Array.isArray(value) ? props.input.value : [];
        Component = Form.Select;
        break;
      case fieldTypes.SingleSelect:
        specialProps.onChange = (e, d) => this.props.input.onChange(d.value);
        specialProps.value = value;
        specialProps.options = props.options || [];
        Component = Form.Select;
        break;
      case fieldTypes.Date:
        specialProps.control = DatePicker;
        Component = Form.Field;
        break;
      case fieldTypes.Email:
        Component = Form.Input;
        break;
      case fieldTypes.Phone:
        specialProps.settings = { format: '(###) ###-####' };
        specialProps.control = NumberFormatter;
        Component = Form.Field;
        break;
      default:
        Component = Form.Input;
    }
    return <Component {...props} {...specialProps} />;
  }
}

export default CustomField;
