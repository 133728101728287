import React from "react";
import { reduxForm, Field } from "redux-form";
import {
  Form,
  Container,
  Segment,
  Button,
  Icon,
  Label
} from "semantic-ui-react";
import BusinessLogoField from "./BusinessLogoField/BusinessLogoField";
import NumberFormatter from "../../NumberFormatter";
import config from "../../../config";

class BusinessSettingsForm extends React.Component {
  render() {
    const { handleSubmit, submitting, gateway } = this.props;
    console.log(gateway);
    return (
      <Container>
        <Segment basic>
          <Form onSubmit={handleSubmit} loading={submitting}>
            <Field label="Business Name" name="name" component={Form.Input} />
            <Field label="Website" name="website" component={Form.Input} />
            <Form.Field>
              <label>EIN/Tax Id</label>
              <Field
                name="ein"
                settings={{ format: "##-#######" }}
                component={NumberFormatter}
              />
            </Form.Field>
            <Form.Field>
              {gateway && gateway.objectId && (
                <React.Fragment>
                  <Label>
                    <Icon name="stripe" color="blue" />
                    Connected to Stripe
                  </Label>
                </React.Fragment>
              )}
              {!gateway ||
                (!gateway.objectId && (
                  <Button
                    type="button"
                    as="a"
                    href={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${window.location.origin}/stripe&client_id=${config.stripe.clientId}&state={STATE_VALUE}`}
                  >
                    <Icon name="stripe" /> Connect with Stripe
                  </Button>
                ))}
            </Form.Field>

            <Form.Field>
              <Field name="logo" component={BusinessLogoField} />
            </Form.Field>
            {/* <Field label="Business Logo" name="logo" component={Form.Input} /> */}
            <Form.Button primary loading={submitting}>
              Save
            </Form.Button>
          </Form>
        </Segment>
      </Container>
    );
  }
}

export default reduxForm({ form: "businessSettingsForm" })(
  BusinessSettingsForm
);
