import stringify from "csv-stringify";

export default args => {
  const { fields, data, filename = "export.csv" } = args;
  const json = data.map(d => {
    const obj = {};
    fields.forEach(field => {
      const key = isNaN(field.dataKey)
        ? field.dataKey
        : parseInt(field.dataKey, 10);
      let val = d[key];
      if (val && typeof val === "object") {
        val = d[key].dataValue; // add support for image url
      }
      obj[field.label] = val;
    });
    return obj;
  });
  // console.log(csv);

  // Content is the csv generated string above
  const download = (content, fileName, mt) => {
    const a = document.createElement("a");
    const mimeType = mt || "application/octet-stream";

    if (navigator.msSaveBlob) {
      // IE10
      navigator.msSaveBlob(
        new Blob([content], {
          type: mimeType
        }),
        fileName
      );
    } else if (URL && "download" in a) {
      // html5 A[download]
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType
        })
      );
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      // eslint-disable-next-line max-len
      window.location.href = `data:application/octet-stream,${encodeURIComponent(
        content
      )}`; // only this mime type is supported
    }
  };

  stringify(json, { header: true }, (err, output) => {
    download(output, filename);
  });
};
