import React from 'react';
import Immutable from 'seamless-immutable';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Container, Segment, Form } from 'semantic-ui-react';
import shortid from 'shortid';

import Models from '../../../parse/models';
import FieldRenderer from './FieldRenderer';
import RichTextBox from '../../RichTextBox';

const defaultField = Immutable({
  objectId: null,
  label: '',
  type: 'short',
  options: [],
  required: false,
});

// const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '',
  class: 'hello-world',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class FormBuilder extends React.Component {
  state = {
    editingId: null,
    editingNumber: null,
    name: this.props.name,
    description: this.props.description,
  };

  addField = () => {
    const { formId, fields } = this.props;
    const number = fields.length + 1;
    const newField = defaultField.merge({
      formId,
      number,
      label: `Field ${number}`,
    });
    this.setState({ editingNumber: number, editingId: null }, () =>
      this.props.addField(newField),
    );
  };

  handleChange = (value, name) => {
    const { formId } = this.props;
    let field = {};
    field[name] = value;
    this.props.updateForm(field, formId);
  };

  toggleEdit = editingId => {
    this.setState({
      editingId,
      editingNumber: null,
    });
  };

  handleDeleteField = fieldId => {
    const { formId, fields } = this.props;
    let newFields = [];
    let number = 1;
    fields.forEach(item => {
      const field = new Models.Field({ id: item.objectId });

      if (field.id === fieldId) {
        field.set('active', false);
      } else {
        field.set('number', number);
        number += 1;
      }
      newFields.push(field);
    });
    this.props.updateFields(formId, newFields);
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const { formId, fields } = this.props;
    const items = reorder(
      fields,
      result.source.index,
      result.destination.index,
    ).map((item, index) => {
      const field = new Models.Field({ id: item.objectId });
      field.set('number', index + 1);
      return field;
    });
    this.props.updateFields(formId, items);
  };

  handleBackgroundClick = (e, d) => {
    this.setState({
      editingId: null,
    });
  };

  render() {
    const { name, description, fields } = this.props;
    return (
      <Container
        fluid
        style={{ backgroundColor: '#eee', height: '100%', overflowY: 'auto' }}
        onClick={this.handleBackgroundClick}
      >
        <Form>
          <div style={{ backgroundColor: '#fff', padding: '1em' }}>
            <div style={{ margin: 'auto', maxWidth: 512 }}>
              <Form.Field size="huge" onClick={e => e.stopPropagation()}>
                <RichTextBox
                  placeholder={'Form'}
                  textarea={false}
                  value={name}
                  onChange={value => this.handleChange(value, 'name')}
                  el={'h1'}
                  key={shortid.generate()}
                />
              </Form.Field>
              <Form.Field size="huge" onClick={e => e.stopPropagation()}>
                <RichTextBox
                  placeholder={'Form Description'}
                  textarea={true}
                  value={description}
                  onChange={value => this.handleChange(value, 'description')}
                  onSave={true}
                  el={'div'}
                  key={shortid.generate()}
                />
              </Form.Field>
            </div>
          </div>
          <div style={{ margin: 'auto', maxWidth: 512 }}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {fields &&
                      fields.map((item, index) => (
                        <Draggable
                          key={item.objectId || item.localId}
                          draggableId={item.objectId || item.localId}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              <FieldRenderer
                                isEditing={
                                  this.state.editingId === item.objectId ||
                                  this.state.editingNumber === item.number
                                }
                                field={item}
                                toggleEdit={this.toggleEdit}
                                handleChangeField={this.props.updateField}
                                handleDeleteField={this.handleDeleteField}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* {fields.length > 0 && ( */}

            <Container fluid>
              <Segment basic>
                <Form.Field size="huge">
                  <Button onClick={this.addField}>Add Field</Button>
                </Form.Field>
              </Segment>
            </Container>
          </div>
        </Form>
      </Container>
    );
  }
}

export default FormBuilder;
