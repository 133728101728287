import Immutable from "seamless-immutable";

import { ActionTypes } from "./currentUser";

const defaultState = {};
export default (state = defaultState, action) => {
  if (action) {
    if (
      action.type === ActionTypes.LOGIN_USER ||
      action.type === ActionTypes.SIGNUP_USER
    ) {
      if (action.data.result.currentAccount) {
        return Immutable(
          action.data.entities.accounts[action.data.result.currentAccount]
        );
      }
    }
  } else if (action.type === ActionTypes.LOGOUT_USER) {
    return defaultState;
  }
  return state;
};
