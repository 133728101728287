import DefaultCellRenderer from "../Spreadsheet/DefultCellRenderer";

const cols = [
  // {
  //   dataKey: "id",
  //   label: "Account",
  //   cellRenderer: DefaultCellRenderer
  // },
  {
    dataKey: "name",
    label: "Name",
    width: 190,
    minWidth: 190,
    flexGrow: 1,
    cellRenderer: DefaultCellRenderer
  },
  {
    dataKey: "email",
    label: "Email",
    width: 200,
    flexGrow: 1,
    cellRenderer: DefaultCellRenderer
  },
  {
    dataKey: "access",
    label: "Access Level",
    width: 80,
    cellRenderer: DefaultCellRenderer
  },
  {
    dataKey: "status",
    label: "Status",
    width: 80,
    cellRenderer: DefaultCellRenderer
  }
  // {
  //   dataKey: "status",
  //   label: "Status",
  //   cellRenderer: DefaultCellRenderer
  // }
];

export default cols;
