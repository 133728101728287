import Immutable from "seamless-immutable";
import Parse from "parse";
import { normalize } from "normalizr";
import { createSelector } from "reselect";
import union from "lodash/union";
import orderBy from "lodash/orderBy";
import filter from "lodash/filter";
import schema from "../schema";
import shoppingCartSummary from "../utils/shoppingCartSummary";
import { ActionTypes as PaymentMethoActionTypes } from "./paymentMethods";
// const Helpers = {};

const required = value =>
  value || typeof value === "number"
    ? undefined
    : "This field is required. You have to enter something.";

const emailIsValid = email => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};
const email = value =>
  !value || emailIsValid(value)
    ? undefined
    : "Please enter a valid email address";

const ActionTypes = {
  ADD_STUDENT_SHOPPING_CART: "ADD_STUDENT_SHOPPING_CART",
  PAYMENT_METHOD_CHANGE: "PAYMENT_METHOD_CHANGE",
  UPDATE_PROGRAM_SHOPPING_CART: "UPDATE_PROGRAM_SHOPPING_CART",
  REMOVE_STUDENT_SHOPPING_CART: "REMOVE_STUDENT_SHOPPING_CART",
  RECEIVED_SUMMARY: "RECEIVED_SUMMARY",
  SUBMIT_REGISTRATION: "SUBMIT_REGISTRATION",
  SUBMITTED_REGISTRATION: "SUBMITTED_REGISTRATION",
  RESET_SHOPPING_CART: "RESET_SHOPPING_CART"
};

const Helpers = {
  programSelectedForStudent: (programId, shoppingCart) => {
    const { activeStudent, registrations } = shoppingCart;
    if (!registrations) {
      return false;
    }
    if (!registrations[activeStudent]) {
      return false;
    }
    const programSelection = registrations[activeStudent][programId] || {};
    if (
      programSelection.days &&
      Object.keys(programSelection.days).length > 0
    ) {
      return true;
    } else if (
      programSelection.dropin &&
      Object.keys(programSelection.dropin).length > 0
    ) {
      return true;
    } else if (programSelection.course) {
      return true;
    }
    return false;
  },

  disabledProgramContinue: shoppingCart => {
    const { registrations } = shoppingCart;
    if (Object.keys(registrations).length > 0) {
      let disabled = true;
      Object.keys(registrations).forEach(studentId => {
        if (Object.keys(registrations[studentId]).length > 0) {
          disabled = false;
        }
      });
      return disabled;
    }
    return true;
  },
  toggleSelection: (
    shoppingCart,
    studentId,
    programId,
    selected,
    waitlist,
    day
  ) => {
    if (selected) {
      // cart = shoppingCart
      if (day) {
        return Immutable.setIn(
          shoppingCart,
          ["registrations", studentId, programId, "days", day],
          waitlist ? "waitlist" : true
        );
      }
      return Immutable.setIn(
        shoppingCart,
        ["registrations", studentId, programId, "course"],
        waitlist ? "waitlist" : true
      );
    } else {
      const selections = shoppingCart.registrations[studentId];
      console.log("SELECTIONS!!!!!!!!!!!!!!!!!!!!!!!!!");
      // There is only one selection for this student and it must be this program
      // so if it's a day and there are other days, just remove that day.
      // Otherwise, remove the whole student
      // debugger;
      if (Object.keys(selections).length === 1) {
        if (day && Object.keys(selections[programId].days).length > 1) {
          return Immutable.updateIn(
            shoppingCart,
            ["registrations", studentId, programId, "days"],
            days => days.without(day)
          );
        }
        return Immutable.updateIn(shoppingCart, ["registrations"], studentIds =>
          studentIds.without(studentId)
        );
      } else {
        // there are other programs - just remove the necessary program and/or days
        if (day && Object.keys(selections[programId].days).length > 1) {
          return Immutable.updateIn(
            shoppingCart,
            ["registrations", studentId, programId, "days"],
            days => days.without(day)
          );
        }
        return Immutable.updateIn(
          shoppingCart,
          ["registrations", studentId],
          programs => programs.without(programId)
        );
      }
    }
  }
};

const Private = {
  getActiveStudentProgramSelections: state => {
    // console.log("STATE: ", state);
    if (
      state.shoppingCart &&
      state.shoppingCart.registrations &&
      state.shoppingCart.registrations[state.shoppingCart.activeStudent]
    ) {
      return Object.keys(
        state.shoppingCart.registrations[state.shoppingCart.activeStudent]
      );
    }
    return null;
  },
  getDiscounts: state => state.discounts,
  getStudents: state => state.students,
  getForms: state => state.forms,
  getFieldValues: state => state.fieldValues,
  getShoppingCart: state => state.shoppingCart,
  getFields: state => state.fields,
  getFees: state => state.fees,
  getPrograms: state => state.programs,
  getActiveStudent: state => state.shoppingCart.activeStudent,
  getRegistrations: state => state.shoppingCart.registrations,
  getPaymentMethods: state => state.paymentMethods,
  getBusinessSettings: state => state.businessSettings,
  getShoppingCartPaymentMethodId: state =>
    state.shoppingCart.selectedPaymentMethodId,

  validateField: field => {
    let validation = [];
    if (field.required) {
      validation.push(required);
    }
    if (field.type === "email") {
      validation.push(email);
    }
    return validation;
  },

  fieldOptions: field => {
    if (field.options) {
      return orderBy(field.options, ["number"], ["asc"]).map(option => {
        return {
          key: `${option.number}`,
          text: option.value,
          value: option.value
        };
      });
    }
  },

  // mergeNecessaryFields: field => {
  //   let data = {};
  //   if (field.type === 'multipleSelect' || field.type === 'singleSelect') {
  //     data.type = field.options.map(option => )
  //   }
  // },

  getActiveForms: (programIds, programs, forms, fields, programFormKey) => {
    if (
      !programIds ||
      Object.keys(programs).length === 0 ||
      programIds.length === 0 ||
      Object.keys(forms).length === 0
    ) {
      return [];
    }
    const relativePrograms = programIds.map(programId => programs[programId]);
    let formIds = [];
    relativePrograms.forEach(program => {
      formIds = union(formIds, program[programFormKey] || []);
    });
    return formIds.map(formId => {
      let form = forms[formId];
      const formFields = form.fields || [];
      return form.merge({
        fields: orderBy(
          formFields.map(fieldId =>
            fields[fieldId].merge({
              validate: Private.validateField(fields[fieldId]),
              options: Private.fieldOptions(fields[fieldId])
            })
          ),
          ["number"],
          ["asc"]
        )
      });
    });
  },

  // Gets the active students relative forms based on program selection
  getActiveAccountForms: (programIds, programs, forms, fields) =>
    Private.getActiveForms(programIds, programs, forms, fields, "accountForms"),

  getActiveStudentForms: (programIds, programs, forms, fields) =>
    Private.getActiveForms(programIds, programs, forms, fields, "studentForms"),

  getStudent: (students, studentId) => {
    if (studentId) {
      return students[studentId];
    }
    return null;
  },

  // Gets the min for the shopping cart student
  getMinProgramsSelectedForStudent: shoppingCart => {
    let valid = false;
    const { activeStudent, registrations } = shoppingCart;
    if (!activeStudent) {
      return valid;
    }
    if (!registrations) {
      return valid;
    }
    const studentRegistrations = registrations[activeStudent];
    if (!studentRegistrations) {
      return valid;
    }
    Object.keys(studentRegistrations).forEach(programId => {
      if (valid) {
        return;
      }
      const programSelection = studentRegistrations[programId];
      // place for drop in
      if (
        programSelection.days &&
        Object.keys(programSelection.days).length > 0
      ) {
        valid = true;
      } else if (
        programSelection.dropins &&
        Object.keys(programSelection.dropins).length > 0
      ) {
        valid = true;
      } else if (programSelection.course) {
        valid = true;
      }
    });
    return valid;
  },

  requiredFormStatus: (
    registrations,
    programs,
    forms,
    fields,
    formValues,
    fieldValues
  ) => {
    let formSummaries = {
      students: {},
      accounts: {},
      valid: Object.keys(registrations).length > 0
    };

    let studentFieldValues = {};
    Object.keys(fieldValues).forEach(fieldValueId => {
      const fieldValue = fieldValues[fieldValueId];
      const studentField = studentFieldValues[fieldValue.student] || {};
      studentField[fieldValue.field] = fieldValue;
      studentFieldValues[fieldValue.student] = studentField;
    });

    let studentFormValues = {};
    Object.keys(formValues).forEach(formValueId => {
      const formValue = formValues[formValueId];
      const studentFormValue = studentFormValues[formValue.student] || {};
      studentFormValue[formValue.form] = formValue;
      studentFormValues[formValue.student] = studentFormValue;
    });
    // console.log(studentFormValues);

    Object.keys(registrations).forEach(studentId => {
      Object.keys(registrations[studentId]).forEach(programId => {
        // console.log('Program ID: ', programId);
        // console.log(programs);
        const program = programs[programId] || {};
        // console.log(program);
        // console.log(studentId);
        const studentForms = program.studentForms || [];
        // console.log('TODO: Add account forms');
        // const accountForms = program.accountForms || [];
        studentForms.forEach(formId => {
          // console.log('GO THROUGH!!! ', formId);
          const form = forms[formId];

          // There are required form fields
          // Although, we don't yet know if this student has any filled out
          const formFields = (form.fields || []).map(
            formFieldId => fields[formFieldId]
          );
          const requiredFormFields = filter(
            formFields,
            field => field.required
          );

          // This checks the data
          const incompleteRequiredForms = filter(
            requiredFormFields,
            field =>
              !studentFieldValues[studentId] ||
              !studentFieldValues[studentId][field.objectId]
          );

          // console.log(formFields.length);
          const totalFields = formFields.length;
          const totalComplete =
            studentFormValues[studentId] && studentFormValues[studentId][formId]
              ? studentFormValues[studentId][formId].fieldValues.length
              : 0;
          // console.log(
          //   "TOTAL COMPLETE: ",
          //   totalComplete,
          //   " student id ",
          //   studentId,
          //   " form id ",
          //   formId
          // );
          // if (totalComplete === undefined) {
          //   console.log(
          //     " UNDEFINED ",
          //     studentFormValues[studentId],
          //     studentFormValues[studentId][formId]
          //   );
          // }
          const totalIncompleteRequiredFields = incompleteRequiredForms.length;

          const studentFormSummary = formSummaries.students[studentId] || {};
          studentFormSummary[formId] = {
            totalFields,
            totalComplete,
            totalIncompleteRequiredFields,
            valid: totalIncompleteRequiredFields === 0
          };
          formSummaries.students[studentId] = studentFormSummary;
          if (!studentFormSummary[formId].valid) {
            formSummaries.valid = false;
          }
        });
      });
    });
    return formSummaries;
  }
};

const Selectors = {
  getStudentRegistrationForms: createSelector(
    [
      Private.getActiveStudentProgramSelections,
      Private.getPrograms,
      Private.getForms,
      Private.getFields
    ],
    Private.getActiveStudentForms
  ),
  getAccountRegistrationForms: createSelector(
    [
      Private.getActiveStudentProgramSelections,
      Private.getPrograms,
      Private.getForms,
      Private.getFields
    ],
    Private.getActiveAccountForms
  ),
  getStudents: createSelector(
    [Private.getStudents, Private.getActiveStudent],
    Private.getStudent
  ),
  getPaymentSummary: createSelector(
    [
      Private.getRegistrations,
      Private.getStudents,
      Private.getPrograms,
      Private.getDiscounts,
      Private.getFees,
      Private.getPaymentMethods,
      Private.getBusinessSettings,
      Private.getShoppingCartPaymentMethodId
    ],
    shoppingCartSummary.getPaymentSummary
  ),
  getMinProgramsSelectedForStudent: createSelector(
    [Private.getShoppingCart],
    Private.getMinProgramsSelectedForStudent
  ),
  getRequiredFormStatus: createSelector(
    [
      Private.getRegistrations,
      Private.getPrograms,
      Private.getForms,
      Private.getFields,
      state => state.formValues,
      Private.getFieldValues
    ],
    Private.requiredFormStatus
  )
};

const Actions = {
  creators: {
    paymentMethodChange: data => ({
      data,
      type: ActionTypes.PAYMENT_METHOD_CHANGE
    }),
    addStudentShoppingCart: data => ({
      data,
      type: ActionTypes.ADD_STUDENT_SHOPPING_CART
    }),
    updateProgramShoppingCart: data => ({
      data,
      type: ActionTypes.UPDATE_PROGRAM_SHOPPING_CART
    }),

    receivedSummary: data => ({
      data,
      type: ActionTypes.RECEIVED_SUMMARY
    }),
    submitRegistration: data => ({
      data,
      type: ActionTypes.SUBMIT_REGISTRATION
    }),
    submittedRegistration: data => ({
      data,
      type: ActionTypes.SUBMITTED_REGISTRATION
    }),
    resetShoppingCart: () => ({
      type: ActionTypes.RESET_SHOPPING_CART
    })
  },
  thunks: {
    fetchSummary: (programs, students) => async dispatch => {
      await Promise.all([
        Parse.Object.fetchAllWithInclude(programs, [
          "studentForms",
          "studentForms.fields",
          "accountForms",
          "discounts",
          "fees"
        ]),
        Parse.Object.fetchAll(students)
      ]);
      const programResults = programs.map(p => p.toJSON());
      const studentResults = students.map(s => s.toJSON());

      const result = {
        students: studentResults,
        programs: programResults
      };
      const data = normalize(result, schema.summary);
      dispatch(Actions.creators.receivedSummary(data));
      return data;
    },
    submitRegistration: data => async dispatch => {
      // dispatch(Actions.creators.submitRegistration({ submitting: false }));
      // return;
      try {
        // console.log(data);
        // return;
        dispatch(Actions.creators.submitRegistration({ submitting: true }));
        const result = await Parse.Cloud.run("register", data);
        console.log("SUBMITTED RESULT: ", result);
        let params = {
          submitting: false,
          succeeded: true,
          error: false
        };

        if (result && result.status === "failed") {
          params = {
            submitting: false,
            succeeded: false,
            error: result.error
          };
        }
        dispatch(Actions.creators.submittedRegistration(params));
        return result;
      } catch (error) {
        dispatch(
          Actions.creators.submittedRegistration({
            submitting: false,
            succeeded: false,
            error
          })
        );
        throw error;
      }
    }
  }
};

export { Actions, Selectors, Helpers };

const defaultState = Immutable({
  activeStudent: {},
  registrations: {},
  submitting: false,
  succeeded: false,
  error: false
});
export default (state = defaultState, action) => {
  if (action && action.type === ActionTypes.RESET_SHOPPING_CART) {
    return defaultState;
  } else if (action && action.type === ActionTypes.ADD_STUDENT_SHOPPING_CART) {
    // This would make it so whatever is in there does not get replaced
    return state.merge(action.data, { deep: true });
    // return state.merge(action.data);
  } else if (
    action &&
    action.type === ActionTypes.REMOVE_STUDENT_SHOPPING_CART
  ) {
    // This would make it so whatever is in there does not get replaced
    // return state.merge(action.data, { deep: true });
    return state.without(action.data);
  } else if (
    action &&
    action.type === ActionTypes.UPDATE_PROGRAM_SHOPPING_CART
  ) {
    // This would make it so whatever is in there does not get replaced
    // return state.merge(action.data, { deep: true });
    return state.merge(action.data);

    // return state.merge(action.data);
  } else if (action && action.type === "LOGOUT_USER") {
    // This would make it so whatever is in there does not get replaced
    // return state.merge(action.data, { deep: true });
    return defaultState;
  } else if (
    (action &&
      action.type === PaymentMethoActionTypes.RECEIVED_PAYMENT_METHODS) ||
    action.type === ActionTypes.PAYMENT_METHOD_CHANGE
  ) {
    // This would make it so whatever is in there does not get replaced
    // return state.merge(action.data, { deep: true });
    if (action.data.selectedPaymentMethodId) {
      // console.log('PAYMENT_METHOD_CHANGE ', action.data);
      return state.merge(
        { selectedPaymentMethodId: action.data.selectedPaymentMethodId },
        { deep: true }
      );
    } else if (action.data.defaultPaymentMethodId) {
      return state.merge(
        { selectedPaymentMethodId: action.data.defaultPaymentMethodId },
        { deep: true }
      );
    }
    // return state.merge()
  } else if (action.type === ActionTypes.SUBMIT_REGISTRATION) {
    return state.merge(action.data, { deep: true });
  } else if (action.type === ActionTypes.SUBMITTED_REGISTRATION) {
    return state.merge(action.data, { deep: true });
  }
  return state;
};
