import React from 'react';
import { Form } from 'semantic-ui-react';
import './SelectOrCreateNew.css';

const SelectOrCreateNew = props => {
  const clone = Object.assign({}, props.input);
  console.log(clone);
  delete clone.onChange;
  delete clone.onBlur;
  return (
    <Form.Dropdown
      onAddItem={props.onAddItem}
      onChange={(e, data) => {
        console.log('Data: ', data);
        props.input.onChange(data.value);
        return data.value;
      }}
      onBlur={(e, data) => {
        console.log('BLUR: ', data);
        props.input.onBlur(data.value);
        return data.value;
      }}
      {...clone}
      {...props.select}
      options={props.options}
      loading={props.loading}
      value={clone.value}
    />
  );
};

export default SelectOrCreateNew;
