import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FeeForm from './FeeForm';
import { Actions } from '../../ducks/fees';
import Models from '../../parse/models';
import formatCurrency from '../../utils/formatCurrrency';
const { addFee } = Actions.thunks;

class FeeFormContainer extends React.Component {
  handleSubmit = async values => {
    const business = new Models.Business({ id: this.props.business.objectId });

    let amount = values.amount;
    let amountAdditionalStudents = values.amountAdditionalStudents;

    const currency = 'usd';
    if (amount) {
      amount = formatCurrency(amount, false, currency);
    }
    if (amountAdditionalStudents) {
      amountAdditionalStudents = formatCurrency(
        amountAdditionalStudents,
        false,
        currency,
      );
    }
    const fee = await this.props.addFee(
      Object.assign({ business }, values, {
        currency,
        amount,
        amountAdditionalStudents,
      }),
    );
    if (this.props.handleComplete) {
      this.props.handleComplete(fee);
    }
    return fee;
  };
  render() {
    return (
      <FeeForm
        initialValues={{ name: this.props.feeName }}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addFee,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeeFormContainer);
