import React from 'react';
import { Field } from 'redux-form';
import LabelHelper from '../../LabelHelper';
import Checkbox from '../../Checkbox';

const ChargeActive = ({ visible }) => {
  if (!visible) {
    return <React.Fragment />;
  }
  return (
    <Field
      name="chargeActive"
      label={
        <LabelHelper label="Charge Active">
          <div>
            <p>
              Students are admitted into the program, but are not charged
              payment until you decide activate the charges. If you chose to
              cancel the program, parents will not be charged.
            </p>
          </div>
        </LabelHelper>
      }
      component={Checkbox}
    />
  );
};

ChargeActive.propTypes = {};

export default ChargeActive;
