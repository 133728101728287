import React from "react";
import { Modal } from "semantic-ui-react";

const FormModal = props => (
  <Modal
    open={props.open}
    onClose={props.close}
    closeOnDimmerClick={true}
    closeOnEscape={true}
    {...props.modal}
    mountNode={document.body}
  >
    {props.children}
  </Modal>
);

export default FormModal;
