import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import NewProgramForm from "./NewProgramForm";
import { Actions } from "../../../ducks/programs";
import Models from "../../../parse/models";

class NewProgramContainer extends React.Component {
  state = { modalOpen: false, redirect: null };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  handleSubmit = args => {
    const { business } = this.props;
    this.props
      .onSubmit(new Models.Business({ id: business.objectId }), args)
      .then(program => {
        this.setState({ redirect: program.objectId });
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const { business } = this.props;
    if (this.state.redirect) {
      return <Redirect to={`/programs/edit/${this.state.redirect}`} />;
    }
    return (
      <div>
        <Button icon="add" content="Add Program" onClick={this.handleOpen} />
        <Modal
          className="modal-form"
          open={this.state.modalOpen}
          onClose={this.handleClose}
        >
          <NewProgramForm onSubmit={this.handleSubmit} business={business} />
        </Modal>
      </div>
    );
  }
}

const { addProgram } = Actions.thunks;

const mapStateToProps = state => {
  return {
    business: state.business
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators({ onSubmit: addProgram }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewProgramContainer);
