import React from 'react';
import shortid from 'shortid';
import { Form, TextArea } from 'semantic-ui-react';
import ImageUpload from '../../../../ImageUpload';

const FieldRender = ({ type, label, options }) => {
  const inputs = ['short', 'date', 'email', 'phone'];
  const selects = ['multipleSelect', 'singleSelect'];
  if (inputs.indexOf(type) > -1) {
    return (
      <Form.Field>
        <div className="editable-component">{label}</div>
        <input />
      </Form.Field>
    );
  } else if (selects.indexOf(type) > -1) {
    const selects = options || [];
    options = selects.map(option => ({
      key: shortid.generate(),
      value: option.value,
      text: option.value,
    }));
    return (
      <Form.Field>
        <div className="editable-component">{label}</div>
        <Form.Select
          placeholder={label}
          options={options.map(option => ({
            key: shortid.generate(),
            value: option.value,
            text: option.value,
          }))}
        />
      </Form.Field>
    );
  } else if (type === 'upload') {
    return <ImageUpload />;
  } else if (type === 'statement') {
    return (
      <Form.Field>
        <div className="editable-component">{label}</div>
      </Form.Field>
    );
  } else if (type === 'long') {
    return (
      <Form.Field>
        <div className="editable-component">{label}</div>
        <TextArea placeholder="" />
      </Form.Field>
    );
  }
  return <div />;
};

export default FieldRender;
