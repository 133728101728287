import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Button, Segment } from 'semantic-ui-react';

class PaymentMethodForm extends Component {
  state = { loading: false };
  submit = async ev => {
    this.setState({ loading: true });
    const paymentMethod = await this.props.stripe.createToken({ type: 'card' });
    this.props.handleSubmit(paymentMethod);
  };

  render() {
    return (
      <div className="checkout">
        <CardElement />
        <Segment basic>
          <Button
            loading={this.state.loading}
            disabled={this.state.loading}
            fluid
            primary
            onClick={this.submit}
          >
            Save
          </Button>
        </Segment>
      </div>
    );
  }
}

export default injectStripe(PaymentMethodForm);
