import React from 'react';
import ProgramFeesContainer from './ProgramFeesContainer';
import ProgramSetupSection from '../../ProgramSetupSection';

const ProgramFees = () => (
  <ProgramSetupSection header="Fees">
    <ProgramFeesContainer />
  </ProgramSetupSection>
);

export default ProgramFees;
