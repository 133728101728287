import Parse from "parse";

const Models = {
  Account: Parse.Object.extend("Account"),
  AccountSettings: Parse.Object.extend("AccountSettings"),
  Activity: Parse.Object.extend("Activity"),
  Business: Parse.Object.extend("Business"),
  BusinessRates: Parse.Object.extend("BusinessRates"),
  BusinessSettings: Parse.Object.extend("BusinessSettings"),
  User: Parse.User,
  Category: Parse.Object.extend("Category"),
  Charge: Parse.Object.extend("Charge"),
  Choice: Parse.Object.extend("Choice"),
  Discount: Parse.Object.extend("Discount"),
  Fee: Parse.Object.extend("Fee"),
  Field: Parse.Object.extend("Field"),
  Form: Parse.Object.extend("Form"),
  FieldValue: Parse.Object.extend("FieldValue"),
  FormValue: Parse.Object.extend("FormValue"),
  Gateway: Parse.Object.extend("Gateway"),
  Location: Parse.Object.extend("Location"),
  Parent: Parse.Object.extend("Parent"),
  Payment: Parse.Object.extend("Payment"),
  PaymentMethod: Parse.Object.extend("PaymentMethod"),
  Program: Parse.Object.extend("Program"),
  Registration: Parse.Object.extend("Registration"),
  Room: Parse.Object.extend("Room"),
  Staff: Parse.Object.extend("Staff"),
  Student: Parse.Object.extend("Student"),
  Subscription: Parse.Object.extend("Subscription"),
  Transaction: Parse.Object.extend("Transaction"),
  TransactionItem: Parse.Object.extend("TransactionItem")
};

export default Models;
