import React from 'react';
import startCase from 'lodash/startCase';
import { Form, Table } from 'semantic-ui-react';
import { Field } from 'redux-form';
import NumberFormatter from '../../../NumberFormatter';

const DailyAmount = props => {
  const { days } = props || [];
  return (
    <React.Fragment>
      <Form.Field style={{ padding: 0, margin: 0 }}>
        <label>Invoice Amount</label>
      </Form.Field>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={7}>Selected Days</Table.HeaderCell>
            <Table.HeaderCell width={9}>Total Cost</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {days.map((day, i) => (
            <Table.Row key={`1-${day}`}>
              <Table.Cell>
                {i + 1} Day{i > 0 ? 's' : ''}
              </Table.Cell>
              <Table.Cell>
                <div className="field">
                  <Field
                    name={`daysSelectedAmount[${(i + 1).toString()}]`}
                    settings={{
                      // style: { maxWidth: '10em' },
                      thousandSeparator: true,
                      prefix: '$',
                      // defaultValue: '0.00',
                      placeholder: '$0.00',
                    }}
                    component={NumberFormatter}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={7}>Day</Table.HeaderCell>
            <Table.HeaderCell width={9}>Additional Cost</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {days.map((day, i) => (
            <Table.Row key={`2-${day}`}>
              <Table.Cell>{startCase(day)}</Table.Cell>
              <Table.Cell>
                <div className="field">
                  <Field
                    name={`dayAmount[${day}]`}
                    settings={{
                      // style: { maxWidth: '10em' },
                      thousandSeparator: true,
                      prefix: '$',
                      // defaultValue: '0.00',
                      placeholder: '$0.00 (optional)',
                    }}
                    component={NumberFormatter}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default DailyAmount;
