import React from "react";
import {
  RegistrationStatus,
  RegistrationStatusDisplay
} from "../../../parse/models/enums";
import { Button, Label } from "semantic-ui-react";
// import { Image } from 'semantic-ui-react';
const RegistrationStatusCellRenderer = ({
  cellData,
  columnData,
  columnIndex,
  dataKey,
  rowData,
  rowIndex,
  isScrolling
}) => {
  let cell = "";
  switch (cellData) {
    case RegistrationStatus.enrolled:
      cell = (
        <Label basic color="teal">
          {RegistrationStatusDisplay.enrolled}
        </Label>
      );
      break;
    case RegistrationStatus.waitlisted:
      cell = (
        <Button size="mini" color="yellow">
          {RegistrationStatusDisplay.waitlisted}
        </Button>
      );
      break;
    case RegistrationStatus.pendingApproval:
      cell = (
        <Button size="mini" color="yellow">
          {RegistrationStatusDisplay.pendingApproval}
        </Button>
      );
      break;
    case RegistrationStatus.pendingDiscountApproval:
      cell = (
        <Button size="mini" color="yellow">
          {RegistrationStatusDisplay.pendingDiscountApproval}
        </Button>
      );
      break;
    default:
      cell = "";
      break;
  }
  return cell;

  //   return (
  //     <div>
  //     {days.map(day)}
  //     </div>
  //   );
};

export default RegistrationStatusCellRenderer;
