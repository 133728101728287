const config = {
  development: {
    stripe: {
      apiKey: "pk_test_W0IvjMsBpMJbmzf3kM6VauEE",
      clientId: "ca_87rSxXX0v2Lb8b2SbnnxvPRqlImD6rPp"
    },
    plaid: {
      env: "sandbox",
      clientName: "CommunityRoot",
      key: "da44bb5a0b80c10d94e4f3835cc811"
    }
  },
  production: {
    stripe: {
      apiKey: "pk_live_bNpFpvEtaItyOuqiLsQS3uYL",
      clientId: "ca_87rSn61apLBMRfSBRvjQkPMm3PVF0bBb"
    },
    plaid: {
      env: "production",
      clientName: "CommunityRoot",
      key: "da44bb5a0b80c10d94e4f3835cc811"
    }
  }
};

export default config[process.env.NODE_ENV || "development"];
