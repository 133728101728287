import React from 'react';
import { Icon, Button, Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const ProgramManageCellRenderer = ({ cellData, rowData, handleChange }) => {
  // console.log(rowData);
  return (
    <Dropdown
      trigger={
        <Button icon>
          Options
          <Icon name="caret down" />
        </Button>
      }
      onChange={(e, d) => handleChange(d.value, cellData)}
      icon={null}
    >
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to={`/programs/copy/${cellData}`}>
          <Icon name="copy outline" />
          Copy
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={`/programs/edit/${cellData}`}>
          <Icon name="edit outline" />
          Edit
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProgramManageCellRenderer;
