import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StaffForm from './StaffForm';

import { Actions } from '../../../ducks/staff';

const { addStaff } = Actions.thunks;

class StaffFormContainer extends React.Component {
  state = { modalOpen: false };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  handleSubmit = async args => {
    console.log(args);
    const { business } = this.props;
    try {
      await this.props.onSubmit({ business, ...args });
      this.handleClose();
    } catch (error) {
      throw error;
    }
  };

  render() {
    const { business } = this.props;
    return (
      <div>
        <Button icon="user" content="Add Staff" onClick={this.handleOpen} />
        <Modal open={this.state.modalOpen} onClose={this.handleClose}>
          <StaffForm onSubmit={this.handleSubmit} business={business} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business,
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators({ onSubmit: addStaff }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StaffFormContainer);
