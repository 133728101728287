import React from 'react';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import ProgramSetupSection from '../../ProgramSetupSection';
import { RegistrationType } from '../../../../parse/models/enums';
import CourseChoices from './CourseChoices';
import DailyChoices from './DailyChoices';
import { Actions } from '../../../../ducks/choices';
import FormModal from '../../../FormModal';
import { Container } from 'semantic-ui-react';
import ChoiceFormContainer from '../../../ChoiceFormContainer';
import Models from '../../../../parse/models';

const { fetchChoices, addChoice } = Actions.thunks;

class ProgramChoicesContainer extends React.Component {
  state = { open: false, name: '', choice: null };
  // console.log('REGISTRATION TYPE: ', props);

  componentDidMount = () =>
    this.props.fetchChoices(
      Models.Business.createWithoutData(this.props.business.objectId),
    );

  editLabel = choiceId => {
    console.log(' EDIT CHOICE ID: ', choiceId);
    this.setState({
      choice: this.props.choices[choiceId],
      open: true,
    });
  };
  handleAddition = (e, { value }) => {
    // console.log('E, v', e, value);
    this.setState({ open: true, choice: { name: value } });
  };
  handleComplete = choice => {
    // this.props.input.onChange([choice.objectId]);
    this.closeModal();
  };
  closeModal = () => {
    this.setState({
      open: false,
      name: '',
    });
  };

  render = () => {
    // return <DropdownExampleAdditionLabelString />;
    const choiceOptions = Object.keys(this.props.choices).map(choiceId => ({
      key: choiceId,
      text: this.props.choices[choiceId].name,
      value: choiceId,
    }));
    return (
      <ProgramSetupSection header="Choices">
        <FormModal
          open={this.state.open}
          close={this.closeModal}
          modal={{ className: 'modal-form' }}
        >
          <Container style={{ maxWidth: '400px' }}>
            <ChoiceFormContainer
              handleComplete={this.handleComplete}
              choice={this.state.choice}
            />
          </Container>
        </FormModal>
        <CourseChoices
          visible={this.props.registrationType === RegistrationType.course}
          options={choiceOptions}
          onAddItem={this.handleAddition}
          editLabel={this.editLabel}
        />
        <DailyChoices
          visible={this.props.registrationType === RegistrationType.daily}
          days={this.props.days}
          options={choiceOptions}
          onAddItem={this.handleAddition}
          editLabel={this.editLabel}
        />
      </ProgramSetupSection>
    );
  };
}

const mapStateToProps = state => {
  const selector = formValueSelector('programSetup');
  return {
    business: state.business,
    days: selector(state, 'days'),
    planInterval: selector(state, 'planInterval'),
    registrationType: selector(state, 'registrationType'),
    choices: state.choices,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchChoices,
      addChoice,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramChoicesContainer);
