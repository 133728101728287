import Immutable from 'seamless-immutable';
import { normalize } from 'normalizr';
import Parse from 'parse';

import { SubmissionError } from 'redux-form';
import schema from '../schema';
import Models from '../parse/models';

const ActionTypes = {
  ADD_DISCOUNT: 'ADD_DISCOUNT',
  RECEIVED_DISCOUNTS: 'RECEIVED_DISCOUNTS',
};

const Actions = {
  creators: {
    addDiscount: data => ({
      data,
      type: ActionTypes.ADD_DISCOUNT,
    }),

    receivedDiscounts: data => ({
      data,
      type: ActionTypes.RECEIVED_DISCOUNTS,
    }),
  },
  thunks: {
    addDiscount: data => async dispatch => {
      try {
        const discount = new Models.Discount();
        await discount.save(data);
        const result = normalize(discount.toJSON(), schema.discount);
        dispatch(Actions.creators.addDiscount(result));
        return discount.toJSON();
      } catch (error) {
        const err = {
          _error: error.message,
        };
        throw new SubmissionError(err);
      }
    },
    fetchDiscounts: business => async dispatch => {
      try {
        const query = new Parse.Query(Models.Discount);
        query.equalTo('business', business);
        const discounts = await query.find();
        return dispatch(
          Actions.creators.receivedDiscounts(
            normalize(
              discounts.map(discount => discount.toJSON()),
              schema.discounts,
            ),
          ),
        );
      } catch (error) {
        // const err = {
        //   _error: error.message,
        // };
        console.error(error);
      }
    },
  },
};

export { Actions };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.discounts
  ) {
    return state.merge(action.data.entities.discounts);
  }
  return state;
};
