import DefaultCellRenderer from '../Spreadsheet/DefultCellRenderer';
import EditCellRenderer from './EditCellRenderer';

const cols = [
  // {
  //   dataKey: "id",
  //   label: "Account",
  //   cellRenderer: DefaultCellRenderer
  // },
  {
    dataKey: 'name',
    label: 'Name',
    minWidth: 150,
    flexGrow: 1,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'edit',
    label: 'Edit',
    maxWidth: 150,
    flexGrow: 1,
    cellRenderer: EditCellRenderer,
  },

  // {
  //   dataKey: "status",
  //   label: "Status",
  //   cellRenderer: DefaultCellRenderer
  // }
];

export default cols;
