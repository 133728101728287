import accounts from "./accounts";
import accountSettings from "./accountSettings";
import business from "./business";
import businessSettings from "./businessSettings";
import categories from "./categories";
import choices from "./choices";
import currentUser from "./currentUser";
import currentAccount from "./currentAccount";
import currentStaff from "./currentStaff";
import discounts from "./discounts";
import fees from "./fees";
import fields from "./fields";
import forms from "./forms";
import fieldValues from "./fieldValues";
import formValues from "./formValues";
import filters from "./filters";
import transactions from "./transactions";
import transactionItems from "./transactionItems";

import locations from "./locations";
import loadingState from "./loadingState";
import paymentMethods from "./paymentMethods";
import programs from "./programs";
import registrations from "./registrations";

import students from "./students";
import staff from "./staff";
import stripe from "./stripe";
import shoppingCart from "./shoppingCart";
import users from "./users";
import visibleFields from "./visibleFields";
import subscriptions from "./subscriptions";
import gateway from "./gateway";

export default {
  accounts,
  accountSettings,
  business,
  businessSettings,
  categories,
  choices,
  currentUser,
  currentAccount,
  currentStaff,
  discounts,
  fees,
  forms,
  fieldValues,
  formValues,
  fields,
  filters,
  gateway,
  transactions,
  transactionItems,
  locations,
  paymentMethods,
  loadingState,
  programs,
  registrations,
  staff,
  stripe,
  students,
  shoppingCart,
  subscriptions,
  users,
  visibleFields
};
