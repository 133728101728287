import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

const LinkMenuItem = props => {
  const { active, to, name, icon, title, toggleOpen } = props;
  // console.log(props);
  return (
    <Menu.Item
      active={active}
      // active={false}
      onClick={toggleOpen}
      as={Link}
      // exact
      to={to}
      className="desktop-menu-item"
      name={name}
    >
      <Icon
        style={{ float: 'left', margin: '0 .35714286em 0 0' }}
        name={icon}
        // size="large"
      />
      <span className="menu-text">{title}</span>
    </Menu.Item>
  );
};

export default LinkMenuItem;
