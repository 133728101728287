import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Actions, Selectors } from '../../ducks/forms';

import FormBuilder from './FormBuilder/FormBuilder';

const {
  fetchForm,
  updateForm,
  updateField,
  updateFields,
  addField,
} = Actions.thunks;

class FormBuilderContainer extends React.Component {
  componentDidMount() {
    const {
      match: {
        params: { formId },
      },
    } = this.props;
    this.props.fetchForm(formId);
  }

  render() {
    const {
      addField,
      updateField,
      updateFields,
      updateForm,
      form: { objectId, name, description, fields },
    } = this.props;
    return (
      <FormBuilder
        formId={objectId}
        name={name}
        description={description}
        fields={fields}
        addField={addField}
        updateField={updateField}
        updateFields={updateFields}
        updateForm={updateForm}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    business: state.business,
    form: Selectors.formBuilder(state, props),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { addField, updateField, updateFields, fetchForm, updateForm },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormBuilderContainer);
