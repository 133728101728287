import React from "react";
import { Container, Segment, Menu } from "semantic-ui-react";
import "./DashboardSidebar.css";
import TopbarContainer from "../TopbarContainer";
import TitleMenuItem from "./TitleMenuItem";
import LinkMenuItem from "./LinkMenuItem";
import AnimateHeight from "react-animate-height";
const backgroundColor = "teal";

class DashboardSidebar extends React.Component {
  constructor(props) {
    super(props);
    const { mobile } = props;
    this.state = {
      sidebarOpen: !mobile
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleItem = this.toggleItem.bind(this);
  }
  toggleMenu() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    });
  }
  toggleItem(e, v) {
    console.log("toggle item: ", v);
    const { name } = v;
    this.setState({
      activeMenu: name
    });
  }
  toggleSubItem(e, v) {}
  render() {
    const { children, mobile } = this.props;
    const {
      // activeMenu,
      sidebarOpen
    } = this.state;

    const sidebarClass = sidebarOpen ? "sidebar-open" : "sidebar-closed";
    const bodyClass = sidebarOpen ? "body-sidebar-open" : "body-sidebar-closed";
    const mobileClass = mobile ? "body-mobile" : "";
    // console.log(window.location);
    // const { pathname } = window.location;
    // const pathsegments = pathname.split('/');
    // console.log(this.props);
    const {
      location: { pathname }
    } = this.props;

    return (
      <Container fluid style={{ width: "100%", height: "100%" }}>
        <Container fluid className={`vertical-sidebar ${sidebarClass}`}>
          <Segment
            style={{ height: "100%", padding: 0 }}
            color={backgroundColor}
            inverted
            basic
            id="desktop-vertical-sidebar-container"
          >
            <Menu
              vertical
              compact
              color={backgroundColor}
              inverted
              size="huge"
              className="desktop-menu"
              borderless
            >
              <TitleMenuItem />
              <LinkMenuItem
                to="/programs"
                name="programs"
                icon="sun"
                title="Programs"
                active={
                  pathname.indexOf("/programs") >= 0 ||
                  pathname.indexOf("/registrations") >= 0
                }
              />
              <LinkMenuItem
                to="/students"
                name="students"
                icon="child"
                title="Students"
                active={pathname.indexOf("/students") >= 0}
              />
              <LinkMenuItem
                to="/forms"
                name="forms"
                icon="wpforms"
                title="Forms"
                active={pathname.indexOf("/forms") >= 0}
              />
              <LinkMenuItem
                to="/billing/transactions"
                name="billing"
                icon="dollar"
                title="Billing"
                // active={pathname.indexOf('/billing') >= 0}
              />
              <AnimateHeight
                duration={500}
                height={pathname.indexOf("/billing") >= 0 ? "auto" : 0} // see props documentation below
              >
                <Menu.Menu>
                  <LinkMenuItem
                    to="/billing/transactions"
                    name="transactions"
                    icon="check square outline"
                    title="Transactions"
                    active={pathname.indexOf("/billing/transactions") >= 0}
                  />
                  <LinkMenuItem
                    to="/billing/plans"
                    name="plans"
                    icon="redo"
                    title="Plans"
                    active={pathname.indexOf("/billing/plans") >= 0}
                  />
                </Menu.Menu>
              </AnimateHeight>

              <LinkMenuItem
                to="/staff"
                name="staff"
                icon="users"
                title="Staff"
                active={pathname.indexOf("/staff") >= 0}
              />
            </Menu>
          </Segment>
        </Container>
        <Container
          fluid
          className={`body-container ${bodyClass} ${mobileClass}`}
        >
          <TopbarContainer toggleMenu={this.toggleMenu} />
          <Container fluid className="content-container">
            {children}
          </Container>
        </Container>
      </Container>
    );
  }
}

export default DashboardSidebar;
