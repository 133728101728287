import React from 'react';
import { reduxForm, Field } from 'redux-form';

import { Form } from 'semantic-ui-react';

import { PlanInterval } from '../../../parse/models/enums';
import DatePicker from '../../DatePicker';
import Dropdown from '../../Dropdown';
import NumberFormatter from '../../NumberFormatter';

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const daysOfMonth = [
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  '13th',
  '14th',
  '15th',
  '16th',
  '17th',
  '18th',
  '19th',
  '20th',
  '21st',
  '22nd',
  '23rd',
  '24th',
  '25th',
  '26th',
  '27th',
  '28th',
  'Last day of the month',
];

const SubscriptionForm = props => {
  const { planInterval } = props.initialValues;
  let dues = daysOfWeek;
  if (planInterval === PlanInterval.monthly) {
    dues = daysOfMonth;
  }
  dues = dues.map(period => {
    return {
      key: period,
      value: period,
      text: period,
    };
  });
  const { submitting } = props;
  // console.log(props);
  return (
    <Form onSubmit={props.handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Billing Interval</label>
          <Field
            name="planInterval"
            selection
            disabled
            options={[
              {
                key: PlanInterval.oneTime,
                text: 'One time Payment',
                value: PlanInterval.oneTime,
              },
              {
                key: PlanInterval.weekly,
                text: 'Weekly',
                value: PlanInterval.weekly,
              },
              {
                key: PlanInterval.monthly,
                text: 'Monthly',
                value: PlanInterval.monthly,
              },
            ]}
            fluid
            component={Dropdown}
          />
        </Form.Field>
        <Form.Field>
          <label>Due On The</label>
          <Field
            disabled
            name="planDueDate"
            selection
            options={dues}
            component={Dropdown}
            fluid
          />
        </Form.Field>
        <Form.Field>
          <label>Plan Start Date</label>
          <Field
            settings={{ disabled: true }}
            name="planStartDate"
            component={DatePicker}
            fluid
          />
        </Form.Field>
        <Form.Field>
          <label>Plan End Date</label>
          <Field
            settings={{ placeholderText: 'Optional' }}
            name="planEndDate"
            component={DatePicker}
            fluid
            disabled={planInterval === PlanInterval.oneTime}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <label>Invoice Amount</label>
          <Field
            name="amount"
            // fluid
            settings={{
              // style: { maxWidth: '10em' },
              prefix: '$',
              thousandSeparator: true,
              placeholder: '0',
            }}
            component={NumberFormatter}
          />
        </Form.Field>
      </Form.Group>
      <Form.Button loading={submitting} primary>
        Save
      </Form.Button>
    </Form>
  );
};

export default reduxForm({ form: 'subscriptionForm' })(SubscriptionForm);
