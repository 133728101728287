import React from 'react';
import shortid from 'shortid';
import DatePicker from 'react-datepicker';
import { Form, Grid } from 'semantic-ui-react';
import { Field } from 'redux-form';

class CalendarPicker extends React.Component {
  state = {
    lastSelectedDate: null,
  };
  handleChange = date => {
    const { onChange, value } = this.props.input;
    const values = value || [];
    const arr = [...values];
    let inArray = false;
    let index = 0;
    arr.forEach((a, i) => {
      if (new Date(a).getTime() === new Date(date).getTime()) {
        console.log(new Date(a), new Date(date));
        inArray = true;
        index = i;
        return;
      }
    });
    // console.log(inArray);
    if (!inArray) {
      arr.push(date);
    } else {
      arr.splice(index, 1);
    }
    onChange(arr);
  };

  render() {
    const { input, settings } = this.props;
    const { lastSelectedDate } = this.state;
    const values = input.value || [];
    const highlighted = values.map(date => {
      console.log('DATE: ', date);
      if (date.iso) {
        return new Date(date.iso);
      }
      return new Date(date);
    });
    return (
      // <ReactCalendarPicker
      //   {...settings}
      //   selected={input.value}
      //   onChange={this.handleChange}
      // />
      <Grid>
        <Grid.Column width={8}>
          <DatePicker
            inline
            selected={
              highlighted.length > 0
                ? highlighted[highlighted.length - 1]
                : lastSelectedDate
            }
            key={shortid.generate()}
            highlightDates={highlighted}
            onSelect={this.handleChange}
            calendarClassName="inline-schedule"
            {...settings}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          {highlighted.map(date => (
            <Form.Group widths="equal">
              <Form.Field key={date}>
                <label>Start Time</label>
                <Field
                  name={`startTime[${date}]`}
                  settings={{
                    showTimeSelect: true,
                    showTimeSelectOnly: true,
                    timeIntervals: 5,
                    dateFormat: 'h:mm aa',
                    timeCaption: 'Time',
                  }}
                  component={DatePicker}
                />
              </Form.Field>
              <Form.Field key={date}>
                <label>End Time</label>
                <Field
                  name={`endTime[${date}]`}
                  settings={{
                    showTimeSelect: true,
                    showTimeSelectOnly: true,
                    timeIntervals: 5,
                    dateFormat: 'h:mm aa',
                    timeCaption: 'Time',
                  }}
                  component={DatePicker}
                />
              </Form.Field>
            </Form.Group>
          ))}
        </Grid.Column>
      </Grid>
    );
  }
}

export default CalendarPicker;
