import React from 'react';
import { Form } from 'semantic-ui-react';
import NumberFormatter from '../../NumberFormatter';
import { Field } from 'redux-form';

const DepositField = ({ visible }) => {
  if (!visible) {
    return <React.Fragment />;
  }
  return (
    <Form.Group>
      <Form.Field width={4}>
        <label>Deposit (optional)</label>
        <Field
          name="deposit"
          settings={{
            // style: { maxWidth: '10em' },
            thousandSeparator: true,
            prefix: '$',
            // defaultValue: '0.00',
            placeholder: '$0.00',
          }}
          component={NumberFormatter}
        />
      </Form.Field>
    </Form.Group>
  );
};

export default DepositField;
