import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions, Selectors } from "../../../ducks/registrations";
import StudentRegistrations from "./StudentRegistrations/StudentRegistrations";
import Models from "../../../parse/models";
const { fetchStudentRegistrations } = Actions.thunks;

class StudentRegistrationsContainer extends React.Component {
  componentDidMount = () => {
    const student = Models.Student.createWithoutData(this.props.studentId);
    this.props.fetchStudentRegistrations(student);
  };
  render = () => {
    const { registrations } = this.props;
    return <StudentRegistrations registrations={registrations} />;
  };
}

StudentRegistrationsContainer.propTypes = {};

const mapStateToProps = state => ({
  registrations: Selectors.getStudentRegistrations(state)
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStudentRegistrations
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentRegistrationsContainer);
