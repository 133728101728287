import Immutable from 'seamless-immutable';
import { normalize } from 'normalizr';
import Parse from 'parse';

import { SubmissionError } from 'redux-form';
import schema from '../schema';
import Models from '../parse/models';

const ActionTypes = {
  ADD_CHOICE: 'ADD_CHOICE',
  RECEIVED_CHOICES: 'RECEIVED_CHOICES',
};

const Actions = {
  creators: {
    addChoice: data => ({
      data,
      type: ActionTypes.ADD_CHOICE,
    }),

    receivedChoices: data => ({
      data,
      type: ActionTypes.RECEIVED_CHOICES,
    }),
  },
  thunks: {
    addChoice: data => async dispatch => {
      try {
        const choice = new Models.Choice();
        await choice.save(data);
        const result = normalize(choice.toJSON(), schema.choice);
        dispatch(Actions.creators.addChoice(result));
        return choice.toJSON();
      } catch (error) {
        const err = {
          _error: error.message,
        };
        throw new SubmissionError(err);
      }
    },
    fetchChoices: business => async dispatch => {
      try {
        const query = new Parse.Query(Models.Choice);
        query.equalTo('business', business);
        const choices = await query.find();
        return dispatch(
          Actions.creators.receivedChoices(
            normalize(choices.map(choice => choice.toJSON()), schema.choices),
          ),
        );
      } catch (error) {
        // const err = {
        //   _error: error.message,
        // };
        console.error(error);
      }
    },
  },
};

export { Actions };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.choices
  ) {
    return state.merge(action.data.entities.choices);
  }
  return state;
};
