import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../ducks/paymentMethods';
import Models from '../../parse/models';
import PaymentMethods from './PaymentMethods';

const { fetchUserPaymentMethods } = Actions.thunks;

class PaymentMethodsContainer extends React.Component {
  componentDidMount = () => {
    const user = new Models.User({ id: this.props.user.objectId });
    this.props.fetchUserPaymentMethods(user);
  };
  render = () => {
    const { paymentMethods, shoppingCart } = this.props;
    const { selectedPaymentMethodId } = shoppingCart;
    return (
      <PaymentMethods
        paymentMethods={paymentMethods}
        selectedPaymentMethodId={selectedPaymentMethodId}
        handlePaymentMethodChange={this.props.handlePaymentMethodChange}
      />
    );
  };
}

const mapStateToProps = state => ({
  user: state.currentUser,
  paymentMethods: state.paymentMethods,
  shoppingCart: state.shoppingCart,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserPaymentMethods,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentMethodsContainer);
