import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Actions } from "../../ducks/students";
import { Actions as SCActions } from "../../ducks/shoppingCart";

import Register from "./Register";
import Models from "../../parse/models";

const { resetShoppingCart } = SCActions.creators;
const { fetchAccountStudents } = Actions.thunks;

class RegisterContainer extends React.Component {
  componentDidMount() {
    const { currentAccount, shoppingCart } = this.props;
    // console.log('mounted with ', currentAccount);
    if (shoppingCart.succeeded) {
      this.props.resetShoppingCart();
    }
    if (currentAccount && currentAccount.objectId) {
      const account = Models.Account.createWithoutData(currentAccount.objectId);
      this.props.fetchAccountStudents(account);
    }
  }
  render() {
    return <Register />;
  }
}

const mapStateToProps = state => {
  return {
    shoppingCart: state.shoppingCart,
    currentAccount: state.currentAccount
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccountStudents,
      resetShoppingCart
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
