import { createStore, applyMiddleware } from "redux";
import Immutable from "seamless-immutable";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
// import localForage from 'localforage';
import { createTransform, persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native

import rootReducer from "../reducers";

const middlewares = [thunkMiddleware];

// if (process.env.NODE_ENV === 'development') {
// if (process.env.NODE_ENV === true) {
middlewares.push(createLogger());
// }

const immutableTransform = createTransform(
  inboundState => {
    if (inboundState && inboundState.asMutable) {
      return inboundState.asMutable({ deep: true });
    }
    return inboundState;
  },
  outboundState => Immutable(outboundState)
);

const persistConfig = {
  key: "cradmin",
  storage,
  transforms: [immutableTransform],
  // TODO: Undow comment
  // blacklist: ['business', 'startTime', 'endTime'], // navigation will not be persisted
  whitelist: ["business", "currentAccount", "currentStaff", "shoppingCart"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore(preloadedState) {
  // with async await
  return createStore(
    persistedReducer,
    preloadedState,
    applyMiddleware(...middlewares)
  );
}

export default () => {
  const store = configureStore();
  const persistor = persistStore(store);
  return { store, persistor };
};
