import React from "react";
import { Segment, Container } from "semantic-ui-react";
import shortid from "shortid";
import PaymentMethodsContainer from "../../../../../../PaymentMethodsContainer";
import PaymentMethodFormModal from "../../../../../../PaymentMethodFormModal";

const RegisterPaymentMethod = ({
  accountSettings,
  businessSettings,
  paymentMethodChange
}) => {
  // const options =
  //   accountSettings && accountSettings.paymentOptions
  //     ? accountSettings.paymentOptions
  //     : businessSettings.paymentOptions || [];
  // const offline = options.indexOf("offline") >= 0;
  return (
    <Segment basic>
      <Segment>
        <PaymentMethodsContainer
          handlePaymentMethodChange={selectedPaymentMethodId =>
            paymentMethodChange({ selectedPaymentMethodId })
          }
        />
        {/* {offline && <div>Pay Later</div>} */}
        <p />
        <Container>
          <PaymentMethodFormModal
            accountSettings={accountSettings}
            busienssSettings={businessSettings}
            key={shortid.generate()}
          />
        </Container>
      </Segment>
    </Segment>
  );
};

RegisterPaymentMethod.propTypes = {};

export default RegisterPaymentMethod;
