import Immutable from "seamless-immutable";

export const ActionTypes = {
  FETCHING: "FETCHING"
};

export const LoadingState = {
  loading: "loading", // is currently loading
  loaded: "loaded", // has loaded
  unloaded: "unloaded" // hasn't loaded
};

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (action && action.type === ActionTypes.FETCHING) {
    return state.merge(action.state);
  } else if (action.type.includes("RECEIVED")) {
    const els = action.type.replace("RECEIVED_", "").toLowerCase();
    if (state[els]) {
      const data = {};
      data[els] = LoadingState.loaded;
      return state.merge(data);
    }
  }
  return state;
};
