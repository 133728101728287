import React from "react";
import { Dropdown, Icon, Table } from "semantic-ui-react";
import startCase from "lodash/startCase";
import "./ShoppingCart.css";

const ShoppingCart = ({ paymentSummary }) => {
  console.log(paymentSummary);
  return (
    <Dropdown
      icon={
        <React.Fragment>
          <Icon name="shopping cart" /> {paymentSummary.amountDisplay}{" "}
          <Icon color="teal" name="angle down" />
        </React.Fragment>
      }
      style={{ paddingRight: "1em" }}
      compact
      button
      basic
      className="link item shoppingcart-summary"
    >
      <Dropdown.Menu>
        {paymentSummary && paymentSummary.items && (
          <React.Fragment>
            {paymentSummary.items.map(item => (
              <React.Fragment key={item.id}>
                <Dropdown.Header>{item.name}</Dropdown.Header>
                {item.items.map(i => (
                  <Dropdown.Item key={i.id} onClick={e => e.stopPropagation()}>
                    <Table basic="very">
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell width={12}>
                            {" "}
                            {i.name}
                            {i.days && i.days.length > 0 && (
                              <div>
                                {i.days.map(day => startCase(day)).join(", ")}
                              </div>
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="right" width={4}>
                            {i.amountDisplay}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
              </React.Fragment>
            ))}
            <Dropdown.Item onClick={e => e.stopPropagation()}>
              <Table basic="very">
                <Table.Footer>
                  <Table.Row>
                    <Table.Cell width={12}>
                      <b>Total</b>
                    </Table.Cell>
                    <Table.Cell textAlign="right" width={4}>
                      {paymentSummary.amountDisplay}
                    </Table.Cell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Dropdown.Item>
          </React.Fragment>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ShoppingCart.propTypes = {};

export default ShoppingCart;
