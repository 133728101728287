import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import "./Register.css";
import RegisterProgressBarContainer from "./RegisterProgressBarContainer";
import RegisterProgramsContainer from "./RegisterProgramsContainer";
import RegisterStudentsContainer from "./RegisterStudentsContainer";
import RegisterStudentHeaderContainer from "./RegisterStudentHeaderContainer";
import RegisterFormsContainer from "./RegisterFormsContainer";
import RegisterPaymentContainer from "./RegisterPaymentContainer";

class Register extends React.Component {
  render() {
    return (
      <Container>
        <Route
          path={["/register/programs", "/register/forms"]}
          render={props => <RegisterStudentHeaderContainer />}
        />
        <Route
          path={["/register/programs", "/register/forms"]}
          render={props => <RegisterProgressBarContainer {...props} />}
        />
        <Switch>
          <Route
            exact
            path="/register"
            render={props => <RegisterStudentsContainer {...props} />}
          />
          <Route
            exact
            path={["/register/programs"]}
            render={props => <RegisterProgramsContainer {...props} />}
          />
          <Route
            path="/register/forms"
            render={props => <RegisterFormsContainer {...props} />}
          />
          <Route
            path="/register/payment"
            render={props => <RegisterPaymentContainer {...props} />}
          />
        </Switch>
      </Container>
    );
  }
}

export default Register;
