import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Login from "../Login";
import Signup from "../Signup";
import ProtectedRouteContainer from "./ProtectedRouteContainer";

import ParentRoutes from "./ParentRoutes";
import AdminRoutes from "./AdminRoutes/AdminRoutes";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import CustomFormViewContainer from "../CustomFormViewContainer";

const Router = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <ScrollToTop />
    <div className="App">
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/" component={Signup} />
        <Route exact path="/form/:formId" component={CustomFormViewContainer} />
        <ProtectedRouteContainer
          path={["/user", "/register"]}
          component={ParentRoutes}
        />
        <ProtectedRouteContainer path="/" component={AdminRoutes} />
      </Switch>
    </div>
  </BrowserRouter>
);

export default Router;
