import React from "react";
import startCase from "lodash/startCase";
import { Table, Header, Icon, Label, List } from "semantic-ui-react";
import { RegistrationStatus } from "../../../../../../../../parse/models/enums";
import { Helpers } from "../../../../../../../../ducks/shoppingCart";

const RegisterPaymentItem = ({
  item,
  shoppingCart,
  updateProgramShoppingCart
}) => (
  <React.Fragment>
    <Table.Row>
      <Table.Cell active colSpan="4">
        <Header as="h5">{item.name}</Header>
      </Table.Cell>
    </Table.Row>
    {item.items.map((programItem, index) => (
      <Table.Row key={`programItem-${index}`}>
        {console.log("Program ITEM: ", item)}
        <Table.Cell></Table.Cell>
        <Table.Cell>
          <Header as="h5">
            {programItem.name}{" "}
            {programItem.registrationStatus ===
              RegistrationStatus.waitlisted && (
              <Label size="tiny" color="yellow" basic>
                Waitlist
              </Label>
            )}
            <Header.Subheader>{programItem.description}</Header.Subheader>
          </Header>
          {programItem.days && programItem.days.length > 0 && (
            <List>
              {programItem.days.map(day => (
                <List.Item key={`${item.id}-${programItem.id}-${day}`}>
                  {startCase(day)}{" "}
                  <Icon
                    color="blue"
                    name="cancel"
                    className="clickable"
                    onClick={() => {
                      const data = Helpers.toggleSelection(
                        shoppingCart,
                        item.id,
                        programItem.id,
                        false,
                        false,
                        day
                      );
                      console.log(" NEW DATA: ", data);
                      updateProgramShoppingCart(data);
                    }}
                  />
                </List.Item>
              ))}
            </List>
          )}
        </Table.Cell>
        <Table.Cell textAlign="right">{programItem.amountDisplay}</Table.Cell>
        <Table.Cell textAlign="right">
          {programItem.type === "program" && (
            <Icon
              color="blue"
              name="cancel"
              className="clickable"
              onClick={() => {
                const data = Helpers.toggleSelection(
                  shoppingCart,
                  item.id,
                  programItem.id,
                  false,
                  false
                );
                console.log(" NEW DATA: ", data);
                updateProgramShoppingCart(data);
              }}
            />
          )}
        </Table.Cell>
      </Table.Row>
    ))}
    <Table.Row>
      <Table.Cell />
      <Table.Cell>Student Total: </Table.Cell>
      <Table.Cell textAlign="right">
        <Header as="h5">{item.amountDisplay}</Header>
      </Table.Cell>
      <Table.Cell />
    </Table.Row>
  </React.Fragment>
);

RegisterPaymentItem.propTypes = {};

export default RegisterPaymentItem;
