import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import sortBy from "lodash/sortBy";
import { Container, Form } from "semantic-ui-react";
import FormModal from "../../../../FormModal";
import LocationFormContainer from "../../../../LocationFormContainer";

import { Actions } from "../../../../../ducks/locations";
import Models from "../../../../../parse/models";

const { fetchLocations } = Actions.thunks;

// Selectors
const orderLocations = state => {
  const locations = sortBy(
    Object.keys(state.locations).map(key => {
      const location = state.locations[key];
      return {
        key: location.objectId,
        text: location.name,
        value: location.objectId
      };
    }),
    ["text"]
  );
  return locations;
};
const getLocations = () => {
  return createSelector(orderLocations, locations => locations);
};
// =============

class ProgramLocationContainer extends React.Component {
  state = {
    locationModalOpen: false,
    locationName: "",
    loading: false
  };

  closeLocationModal = () => {
    this.setState({
      locationModalOpen: false,
      locationName: ""
    });
  };
  handleLocationComplete = location => {
    this.props.input.onChange(location.objectId);
    this.closeLocationModal();
  };
  handleAddition = (e, d) => {
    this.setState({
      locationModalOpen: true,
      locationName: d.value
    });
  };

  componentDidMount() {
    if (this.props.business && this.props.business.objectId) {
      this.props.fetchLocations(
        new Models.Business({ id: this.props.business.objectId })
      );
    }
  }
  render() {
    const { input, locations } = this.props;
    const { loading } = this.state;

    return (
      <div className="field">
        <FormModal
          open={this.state.locationModalOpen}
          close={this.closeLocationModal}
          modal={{ className: "modal-form" }}
        >
          <Container style={{ maxWidth: "400px" }}>
            <LocationFormContainer
              handleComplete={this.handleLocationComplete}
              locationName={this.state.locationName}
            />
          </Container>
        </FormModal>
        <Form.Dropdown
          label="Location (optional)"
          onAddItem={this.handleAddition}
          onChange={(e, data) => {
            input.onChange(data.value);
            return data.value;
          }}
          name={input.name}
          fluid
          search
          selection
          allowAdditions
          noResultsMessage={null}
          options={locations}
          loading={loading}
          value={input.value}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const locationsSelector = getLocations();
  return {
    business: state.business,
    locations: locationsSelector(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLocations
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramLocationContainer);
