import React from 'react';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import TextArea from '../../../TextArea';
import ProgramCategoryContainer from './ProgramCategoryContainer';
import ProgramLocationContainer from './ProgramLocationContainer';
import ProgramImageField from './ProgramImageField';
import LabelHelper from '../../../LabelHelper';
import ProgramSetupSection from '../../ProgramSetupSection';

const ProgramDescription = props => (
  <ProgramSetupSection header="Description">
    <Field
      label="Program"
      name="name"
      placeholder=""
      fluid
      required
      component={Form.Input}
    />
    <Field
      label="Program Description"
      rows={2}
      name="description"
      inline
      placeholder=""
      component={TextArea}
    />
    <Form.Field>
      <LabelHelper label="Program Image">
        <div>
          <p>Images are visible to your customers when they register</p>
        </div>
      </LabelHelper>
      <Field name="image" component={ProgramImageField} />
    </Form.Field>
    <Field name="category" component={ProgramCategoryContainer} />
    <Field name="location" component={ProgramLocationContainer} />
  </ProgramSetupSection>
);

export default ProgramDescription;
