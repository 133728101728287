import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Container, Form, Segment, Header } from 'semantic-ui-react';
import SmartDropdown from './SmartDropdown';

const renderField = props => {
  const {
    input,
    type,
    meta: { error },
    placeholder,
  } = props;
  return (
    <Form.Input
      type={type}
      placeholder={placeholder}
      {...input}
      size="large"
      fluid
      error={error !== undefined}
    />
  );
};

const StaffForm = ({
  handleSubmit,
  submitting,
  // error
}) => (
  <Container style={{ maxWidth: '300px' }}>
    <Segment basic>
      <Header>Add Staff Member</Header>
      <Form onSubmit={handleSubmit}>
        <Field
          type="text"
          name="name"
          placeholder="Staff Name"
          component={renderField}
        />
        <Field
          type="text"
          name="email"
          placeholder="Staff Email"
          component={renderField}
        />
        <Field
          type="text"
          name="access"
          placeholder="Access Level"
          component={SmartDropdown}
        />
        <Form.Button loading={submitting} color="teal" fluid size="large">
          Save
        </Form.Button>
      </Form>
    </Segment>
  </Container>
);

export default reduxForm({
  form: 'staff', // a unique identifier for this form
})(StaffForm);
