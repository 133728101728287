import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SignupForm from "./SignupForm";
import { Actions } from "../../../ducks/currentUser";

const mapStateToProps = state => {
  return {
    enableReinitialize: true,
    initialValues: {
      business: state.business
      // email: 'lauren@gmail.com',
      // password: 'community',
    }
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onSubmit: Actions.thunks.signupUser
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
