import Parse from "parse";
import Immutable from "seamless-immutable";
import Models from "../parse/models";
const ActionTypes = {
  HYDRATE: "HYDRATE",
  RECEIVED_BUSINESS: "RECEIVED_BUSINESS",
  SIGNUP_BUSINESS: "SIGNUP_BUSINESS",
  SAVE_BUSINESS: "SAVE_BUSINESS"
};

const Actions = {
  saveBusiness: data => ({
    data,
    type: ActionTypes.SAVE_BUSINESS
  }),
  creators: {
    receivedBusiness: data => ({
      data,
      type: ActionTypes.RECEIVED_BUSINESS
    }),
    saveBusiness: data => ({
      data,
      type: ActionTypes.SAVE_BUSINESS
    }),
    signupBusiness: data => ({
      data,
      type: ActionTypes.SIGNUP_BUSINESS
    })
  },
  thunks: {
    hydrate: () => async dispatch => {
      const { hostname } = window.location;
      const arr = hostname.split(".");
      let username = arr[0];
      if (username === "localhost") {
        // TODO: remove before prod
        // username = "base";
        username = "springboard";
        // return;
      }

      const result = await new Parse.Query(Models.Business)
        .equalTo("usernameLower", username.toLowerCase())
        .first();
      const business = result ? result.toJSON() : {};
      // console.log("BUSINESS: ", business);
      dispatch(Actions.creators.receivedBusiness({ business }));
    },
    businessSettingsFormHandler: form => async dispatch => {
      const business = Models.Business.createWithoutData(form.objectId);
      const { name, website, logo, ein } = form;
      await business.save({ name, website, logo, ein });
      const result = business.toJSON();
      dispatch(Actions.creators.saveBusiness({ business: result }));
      return result;
    },
    saveBusiness: (business, { form }) => async dispatch => {
      await business.save(form);
      const result = business.toJSON();
      dispatch(Actions.creators.saveBusiness({ business: result }));
      return result;
    },
    signupBusiness: ({ name, phone, enrolledStudents }) => async dispatch => {
      try {
        const business = new Models.Business();
        await business.save({ name, phone, enrolledStudents });
        const result = business.toJSON();
        dispatch(Actions.creators.signupBusiness({ business: result }));
        return result;
      } catch (error) {
        // Show the error message somewhere and let the user try again.
        throw error;
      }
    }
  }
};

export { Actions };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.business
  ) {
    return Immutable(
      action.data.entities.business[
        Object.keys(action.data.entities.business)[0]
      ]
    );
  } else if (action && action.data && action.data.business) {
    // console.log("ACTION: ", action);
    return Immutable(action.data.business);
  }
  return state;
};
