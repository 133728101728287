import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Feed, Label, Message, Segment } from 'semantic-ui-react';

const Home = () => (
  <Segment>
    <Message>
      <Message.Header>Let's get started, Kevin Flynn!</Message.Header>
      <Message.Content>
        Below is a checklist of steps that we recomment going through to learn
        more about our product features
      </Message.Content>
    </Message>
    <Feed size="large">
      <Feed.Event>
        <Feed.Label>
          <Label size="large" circular>
            1
          </Label>
        </Feed.Label>
        <Feed.Content>
          <Feed.Summary>Account created with Demo Students</Feed.Summary>
        </Feed.Content>
      </Feed.Event>
      <Feed.Event>
        <Feed.Label>
          <Label size="large" circular>
            2
          </Label>
        </Feed.Label>
        <Feed.Content>
          <Feed.Date>Customize</Feed.Date>
          <Feed.Summary>Add your program or school logo</Feed.Summary>
          <br />
          <Button basic as={Link} to="">
            Customize
          </Button>
        </Feed.Content>
      </Feed.Event>
    </Feed>
  </Segment>
);

export default Home;
