import numeral from "numeral";

const Public = {
  delay: (fn, ms) => {
    console.log("RUN DELAY: ", fn, ms);
    let timer = 0;
    return (...args) => {
      console.log("ARGSSS ", args);
      clearTimeout(timer);
      timer = setTimeout(fn.bind(this, ...args), ms || 0);
    };
  },

  // dynamicall compares either string or number
  dynamicCompare: (valA, valB) => {
    const test = numeral(valA).value();
    if (test === null) {
      return Public.stringCompare(valB, valA);
    }
    return numeral(valB).value() - numeral(valA).value();
  },

  // compares two strings
  stringCompare: (a, b) => {
    const strA = a.charAt().toUpperCase(); // ignore upper and lowercase
    const strB = b.charAt().toUpperCase(); // ignore upper and lowercase
    return strA.localeCompare(strB);
  },
  camelDisplay: cml =>
    cml.replace(/([A-Z])/g, " $1").replace(/^./, str => str.toUpperCase()),
  uppercaseFirst: string => string.charAt(0).toUpperCase() + string.slice(1),
  valueFromDate: date => {
    if (!date) {
      return null;
    }
    if (date.iso) {
      return new Date(date.iso);
    }
    return new Date(date);
  }
};

export default Public;
