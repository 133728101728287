import Immutable from "seamless-immutable";
import { createSelector } from "reselect";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import union from "lodash/union";
// import startCase from 'lodash/startCase';
// import camelCase from 'lodash/camelCase';

import DefaultCellRenderer from "../components/Spreadsheet/DefultCellRenderer";
import FileCellRenderer from "../components/Spreadsheet/FileCellRenderer";
import ScheduleCellRenderer from "../components/Spreadsheet/ScheduleCellRenderer";
import RegistrationStatusCellRenderer from "../components/Spreadsheet/RegistrationStatusCellRenderer";
import StudentCellRenderer from "../components/Spreadsheet/StudentCellRenderer";

const ActionTypes = {
  ADD_VISIBLE_FIELD: "ADD_VISIBLE_FIELD",
  REMOVE_VISIBLE_FIELD: "REMOVE_VISIBLE_FIELD"
};

const DefaultFields = {
  registration: {
    key: "registration",
    text: "Registration",
    options: [
      {
        key: "schedule",
        text: "Schedule",
        value: "schedule",
        type: "schedule"
      },
      {
        key: "registrationStatus",
        text: "Status",
        value: "registrationStatus",
        type: "registrationStatus"
      }
    ]
  },
  studentFields: {
    key: "studentFields",
    text: "Student",
    options: [
      { key: "avatar", text: "Avatar", value: "avatar", type: "upload" },
      {
        key: "studentName",
        text: "Student Name",
        value: "studentName",
        type: "link"
      },
      {
        key: "studentFirstName",
        text: "Student First Name",
        value: "studentFirstName",
        type: "string"
      },
      {
        key: "studentLastName",
        text: "Student Last Name",
        value: "studentLastName",
        type: "string"
      },
      {
        key: "studentGrade",
        text: "Student Grade",
        value: "studentGrade",
        type: "string"
      },
      { key: "studentDOB", text: "dob", value: "dob", type: "date" }
    ]
  },
  accountFields: {
    key: "accountFields",
    text: "Account",
    options: [
      {
        key: "accountName",
        text: "Account Name",
        value: "accountName",
        type: "string"
      },
      {
        key: "accountFirstName",
        text: "Account First Name",
        value: "accountFirstName",
        type: "string"
      },
      {
        key: "accountLastName",
        text: "Account Last Name",
        value: "accountLastName",
        type: "string"
      },
      {
        key: "accountEmail",
        text: "Account Email Address",
        value: "accountEmail",
        type: "email"
      }
    ]
  }
};

const createFields = defaultFields => {
  const fields = {};
  if (!defaultFields) {
    return fields;
  }
  defaultFields.options.forEach((option, i) => {
    fields[option.key] = {
      label: option.text,
      objectId: option.key,
      number: i + 1,
      type: option.type
    };
  });
  return fields;
};

const Helpers = {
  // getAccounts: state => state.accounts,
  getFields: state => state.fields,
  getVisibleFields: (state, props) => {
    // console.log(props);
    return state.visibleFields[props.report];
  },
  getReport: (state, props) => props.report,

  createColumns: (fields, visibleFields, report) => {
    const accountFields = createFields(DefaultFields.accountFields);
    const studentFields = createFields(DefaultFields.studentFields);
    const defaultReportFields = createFields(DefaultFields[report]);
    const reportFields = fields
      .merge(studentFields)
      .merge(accountFields)
      .merge(defaultReportFields);
    // console.log(visibleFields);

    return Object.keys(visibleFields).map(visibleFieldId => {
      const field = reportFields[visibleFieldId];
      let cellRenderer = DefaultCellRenderer;
      let width = field.width || null;
      let minWidth = field.minWidth || null;
      let flexGrow = 1;

      if (field.type === "upload") {
        cellRenderer = FileCellRenderer;
        minWidth = 50;
        width = 50;
        flexGrow = 0;
      } else if (field.type === "schedule") {
        cellRenderer = ScheduleCellRenderer;
        minWidth = 150;
        width = 150;
      } else if (field.type === "registrationStatus") {
        cellRenderer = RegistrationStatusCellRenderer;
        minWidth = 150;
        width = 150;
      } else if (field.type === "link") {
        cellRenderer = StudentCellRenderer;
      }
      return {
        // width: 100,
        flexGrow,
        width,
        minWidth,
        dataKey: visibleFieldId,
        label: field.label,
        cellRenderer
      };
    });
  },

  getForms: state => state.forms,

  createReportFields: (forms, fields, visibleFields, report) => {
    // console.log('CREATE REPORT WITH: ', forms, fields);

    // Has it's own report specific fields
    let reportFields;
    if (DefaultFields[report]) {
      reportFields = Object.assign({}, DefaultFields[report], {
        options: DefaultFields[report].options
          ? DefaultFields[report].options.map(option =>
              Object.assign({}, option, {
                selected: visibleFields[option.key] === true
              })
            )
          : []
      });
    }

    // if (reportFields) {
    //   reportFields.options =
    // }
    const studentFields = Object.assign({}, DefaultFields.studentFields, {
      options: DefaultFields.studentFields.options.map(option =>
        Object.assign({}, option, {
          selected: visibleFields[option.key] === true
        })
      )
    });

    // console.log(studentFields);
    const accountFields = Object.assign({}, DefaultFields.accountFields, {
      options: DefaultFields.accountFields.options.map(option =>
        Object.assign({}, option, {
          selected: visibleFields[option.key] === true
        })
      )
    });

    const activeFormIds = filter(
      Object.keys(forms),
      formId => forms[formId].fields && forms[formId].fields.length > 0
    );

    const defaultFields = [studentFields, accountFields];
    if (reportFields) {
      defaultFields.push(reportFields);
    }
    // console.log(defaultFields);
    return union(
      defaultFields,
      orderBy(
        activeFormIds.map(formId => {
          const form = forms[formId];
          const row = {
            key: form.objectId,
            text: form.name,
            options: orderBy(
              form.fields.map(fieldId => ({
                key: fieldId,
                text: fields[fieldId].label,
                value: fieldId,
                number: fields[fieldId].number,
                selected: visibleFields[fieldId] === true
              })),
              ["number"],
              ["asc"]
            )
          };
          return row;
        }),
        ["name", "asc"]
      )
    );
  }
};

const Selectors = {
  getColumns: createSelector(
    [Helpers.getFields, Helpers.getVisibleFields, Helpers.getReport],
    Helpers.createColumns
  ),
  getReportFields: createSelector(
    [
      Helpers.getForms,
      Helpers.getFields,
      Helpers.getVisibleFields,
      Helpers.getReport
    ],
    Helpers.createReportFields
  )
};

const Actions = {
  creators: {
    addVisibleField: data => ({
      data,
      type: ActionTypes.ADD_VISIBLE_FIELD
    }),
    removeVisibleField: data => ({
      data,
      type: ActionTypes.REMOVE_VISIBLE_FIELD
    })
  }
};

export { Actions, Selectors };

const defaultState = Immutable({
  registration: {
    avatar: true,
    studentName: true,
    schedule: true,
    registrationStatus: true
  },
  students: {
    avatar: true,
    studentName: true,
    accountName: true,
    accountEmail: true
  }
});
export default (state = defaultState, action) => {
  if (action && action.type === ActionTypes.ADD_VISIBLE_FIELD) {
    return state.updateIn(
      [action.data.report, action.data.visibleFieldId],
      visibleFieldId => true
    );
  } else if (action && action.type === ActionTypes.REMOVE_VISIBLE_FIELD) {
    return state.updateIn([action.data.report], report =>
      report.without(action.data.visibleFieldId)
    );
  }
  return state;
};
