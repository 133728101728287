import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import union from "lodash/union";
import { Actions, Selectors } from "../../../../ducks/shoppingCart";
import { Actions as BSActions } from "../../../../ducks/businessSettings";
import { Actions as ASActions } from "../../../../ducks/accountSettings";

import Models from "../../../../parse/models";
import RegisterPayment from "./RegisterPayment/RegisterPayment";

const { fetchBusinessSettings } = BSActions.thunks;
const { fetchAccountSettings } = ASActions.thunks;
const { resetShoppingCart } = Actions.creators;
const { fetchSummary, submitRegistration } = Actions.thunks;

class RegisterPaymentContainer extends React.Component {
  componentDidMount = () => {
    const { shoppingCart } = this.props;
    const { registrations } = shoppingCart;
    let programIds = [];
    Object.keys(registrations).forEach(studentId => {
      programIds = union(programIds, Object.keys(registrations[studentId]));
    });
    const programs = programIds.map(
      programId => new Models.Program({ id: programId })
    );
    const students = Object.keys(registrations).map(
      studentId => new Models.Student({ id: studentId })
    );
    this.props.fetchSummary(programs, students);
    // this.props.submitRegistration();

    const business = Models.Business.createWithoutData(
      this.props.business.objectId
    );
    this.props.fetchBusinessSettings(business);

    const account = Models.Account.createWithoutData(
      this.props.currentAccount.objectId
    );
    this.props.fetchAccountSettings(account);
  };

  componentWillUnmount = () => {
    // const { shoppingCart } = this.props;
    // if (shoppingCart.succeeded) {
    //   // console.log('TRIGGER RESET SHOPPING CART');
    //   this.props.resetShoppingCart();
    // }
  };

  submit = () => {
    const {
      currentAccount,
      business,
      paymentSummary,
      shoppingCart
    } = this.props;
    this.props.submitRegistration({
      accountId: currentAccount.objectId,
      businessId: business.objectId,
      paymentMethodId: shoppingCart.selectedPaymentMethodId,
      ...paymentSummary
    });
  };

  render = () => {
    const { paymentSummary, shoppingCart } = this.props;
    // console.log(paymentSummary);
    const { selectedPaymentMethodId } = shoppingCart;
    // console.log("PROPS: ", this.props);
    return (
      <RegisterPayment
        paymentSummary={paymentSummary}
        handleSubmit={this.submit}
        valid={
          selectedPaymentMethodId !== null &&
          selectedPaymentMethodId !== undefined
        }
        submitting={this.props.shoppingCart.submitting}
        succeeded={this.props.shoppingCart.succeeded}
        error={this.props.shoppingCart.error}
      />
    );
  };
}

const mapStateToProps = (state, props) => {
  return {
    business: state.business,
    currentAccount: state.currentAccount,
    currentUser: state.currentUser,
    shoppingCart: state.shoppingCart,
    paymentSummary: Selectors.getPaymentSummary(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSummary,
      submitRegistration,
      resetShoppingCart,
      fetchBusinessSettings,
      fetchAccountSettings
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPaymentContainer);
