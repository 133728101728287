import React from 'react';
import { Image } from 'semantic-ui-react';
const FileCellRenderer = ({
  cellData,
  columnData,
  columnIndex,
  dataKey,
  rowData,
  rowIndex,
  isScrolling,
}) => {
  // console.log(cellData);
  return (
    <div>
      {cellData && <Image size="mini" avatar src={cellData} />}
      {cellData === null && (
        <div>
          <div
            style={{
              marginRight: '.5em',
              display: 'inline-block',
              padding: '0.59em',
              // height: '2em',
              borderRadius: 50,
              backgroundColor: 'rgb(182, 177, 166)',
              textAlign: 'center',
              color: 'white',
              verticalAlign: 'middle',
            }}
          >
            {rowData.initials}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileCellRenderer;
