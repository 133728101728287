import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BusinessSettingsForm from "./BusinessSettingsForm";
import { Actions } from "../../ducks/business";
import { Actions as GatewayActions } from "../../ducks/gateway";
import Models from "../../parse/models";
const { thunks } = Actions;
const { businessSettingsFormHandler } = thunks;
const { fetchGateway } = GatewayActions.thunks;

class BusinessSettingsContainer extends React.Component {
  componentDidMount = () => {
    const business = Models.Business.createWithoutData(
      this.props.initialValues.objectId
    );
    this.props.fetchGateway(business);
  };
  render = () => (
    <BusinessSettingsForm gateway={this.props.gateway} {...this.props} />
  );
}

const mapStateToProps = state => ({
  gateway: state.gateway,
  initialValues: { ...state.business }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { onSubmit: businessSettingsFormHandler, fetchGateway },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessSettingsContainer);
