import React from "react";
import { Form, Segment } from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import states from "../../../utils/states.json";
import SelectOrCreateNew from "../../SelectOrCreateNew";

const LocationForm = ({ handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <Segment basic>
      <Field name="name" label="Location Name" component={Form.Input} />
      <Field name="address" label="Address" component={Form.Input} />
      <Field
        name="state"
        component={SelectOrCreateNew}
        label="State"
        placeholder=""
        select={{
          search: true,
          selection: true
        }}
        options={Object.keys(states).map(key => {
          return {
            key,
            value: key,
            text: states[key]
          };
        })}
      />
      <Field name="zip" label="Zip Code" component={Form.Input} />
      <Form.Button
        type="submit"
        loading={submitting}
        content="Save"
        primary
        fluid
      />
    </Segment>
  </Form>
);

export default reduxForm({
  form: "locationForm"
})(LocationForm);
