import React from "react";
import { Container, Form, List, Grid, Segment } from "semantic-ui-react";

import RegisterContinueButton from "../../RegisterContinueButton";
import RegisterProgramItemContainer from "./RegisterProgramItemContainer";
import BlurredLoader from "../../../../BlurredLoader";

const RegisterPrograms = ({ programs, disabledContinue, student, loading }) => (
  <Container>
    {/* <Segment basic style={{ padding: 0 }}> */}
    {/* <Dimmer > */}
    <BlurredLoader loading={loading}>
      <List selection verticalAlign="middle">
        {programs.map(program => (
          <RegisterProgramItemContainer
            key={program.objectId}
            program={program}
            student={student}
          />
        ))}
      </List>
      <Form>
        <Segment basic>
          <Grid columns={1} textAlign="right">
            <Grid.Column>
              {/* <Segment basic> */}
              <RegisterContinueButton
                to="/register/forms"
                primary
                disabled={disabledContinue}
                size="big"
                // fluid
              />
              {/* </Segment> */}
            </Grid.Column>
          </Grid>
        </Segment>
      </Form>
    </BlurredLoader>
    {/* </Segment> */}
  </Container>
);

export default RegisterPrograms;
