import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DiscountForm from './DiscountForm';
import { Actions } from '../../ducks/discounts';
import Models from '../../parse/models';
import formatCurrency from '../../utils/formatCurrrency';
const { addDiscount } = Actions.thunks;

class DiscountFormContainer extends React.Component {
  handleSubmit = async values => {
    const business = new Models.Business({id: this.props.business.objectId});
    let value = values.value;
    const currency = 'usd';
    if (values.unit === 'dollars') {
      value = formatCurrency(value, false, 'usd');
    }
    const discount = await this.props.addDiscount(
      Object.assign({ business, active: true }, values, { currency, value }),
    );
    if (this.props.handleComplete) {
      this.props.handleComplete(discount);
    }
    return discount;
  };
  render() {
    return (
      <DiscountForm
        // initialValues={ }
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addDiscount,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscountFormContainer);
