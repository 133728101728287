import React from "react";
import { Icon, Image, Button } from "semantic-ui-react";
// Some examples
// https://github.com/Semantic-Org/Semantic-UI-React/issues/2103

class ImageUpload extends React.Component {
  state = {
    imagePreviewUrl: this.props.imagePreviewUrl
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
    this.props.onChange(file);
  }

  render() {
    // const { buttonProps, imageProps } = this.props;
    const { imageProps, url } = this.props;
    // console.log(this.props);
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    // console.log(this.props);
    if (url) {
      $imagePreview = (
        <Image {...imageProps} src={url} size="mini" floated="right" />
      );
    } else if (imagePreviewUrl) {
      $imagePreview = (
        <Image
          {...imageProps}
          src={imagePreviewUrl}
          size="mini"
          floated="right"
        />
      );
    } else {
      $imagePreview = <div className="previewText" />;
    }

    return (
      <span className="previewComponent">
        {$imagePreview}
        <Button width="4" as="label" htmlFor="file">
          <Icon name="upload" />
          Upload
        </Button>
        <input
          onChange={e => this._handleImageChange(e)}
          id="file"
          hidden
          type="file"
        />
      </span>
    );
  }
}

export default ImageUpload;
