import React from 'react';
import { Icon, Button, Dropdown } from 'semantic-ui-react';

// const options = [
//   { key: 'edit', icon: 'edit', text: 'Edit Plan', value: 'edit' },
//   { key: 'pause', icon: 'pause', text: 'Pause Plan', value: 'pause' },
//   { key: 'cancel', icon: 'delete', text: 'Cancel Plan', value: 'cancel' },
//   {
//     key: 'view',
//     icon: 'info',
//     text: 'View Plan',
//     value: 'hide',
//     onClick: handleChange,
//   },
// ];100

const SubscriptionEditCellRenderer = ({ cellData, handleChange }) => (
  <Dropdown
    trigger={
      <Button icon>
        Options
        <Icon name="caret down" />
      </Button>
    }
    icon={null}
    options={[
      {
        key: 'edit',
        icon: 'edit',
        text: 'Edit Plan',
        value: 'edit',
        onClick: (e, d) => handleChange(d.value, cellData),
      },
      {
        key: 'pause',
        icon: 'pause',
        text: 'Pause Plan',
        value: 'pause',
        onClick: (e, d) => handleChange(d.value, cellData),
      },
      {
        key: 'cancel',
        icon: 'delete',
        text: 'Cancel Plan',
        value: 'cancel',
        onClick: (e, d) => handleChange(d.value, cellData),
      },
      {
        key: 'view',
        icon: 'info',
        text: 'View Plan',
        value: 'hide',
        onClick: (e, d) => handleChange(d.value, cellData),
      },
    ]}
  />
);

export default SubscriptionEditCellRenderer;
