import React from "react";
import { Form } from "semantic-ui-react";
import { Field } from "redux-form";
import { PlanInterval } from "../../../parse/models/enums";
import Dropdown from "../../Dropdown";
import DatePicker from "../../DatePicker";

const BillingPlan = ({ planInterval, dues }) => {
  return (
    <Form.Group>
      <Form.Field width={planInterval === PlanInterval.oneTime ? 16 : 4}>
        <label>Billing Interval</label>
        <Field
          name="planInterval"
          selection
          options={[
            {
              key: PlanInterval.oneTime,
              text: "One time",
              value: PlanInterval.oneTime
            },
            {
              key: PlanInterval.weekly,
              text: "Weekly",
              value: PlanInterval.weekly
            },
            {
              key: PlanInterval.monthly,
              text: "Monthly",
              value: PlanInterval.monthly
            }
          ]}
          fluid
          component={Dropdown}
        />
      </Form.Field>
      {planInterval && planInterval !== PlanInterval.oneTime && (
        <React.Fragment>
          <Form.Field width="4">
            <label>
              Due On{" "}
              {planInterval === PlanInterval.monthly && (
                <React.Fragment>The</React.Fragment>
              )}{" "}
            </label>
            <Field
              name="planDueDate"
              selection
              options={dues}
              component={Dropdown}
              fluid
              disabled={planInterval === PlanInterval.oneTime}
            />
          </Form.Field>
          <Form.Field width="4">
            <label>Plan Start Date</label>
            <Field
              name="planStartDate"
              component={DatePicker}
              fluid
              settings={{ disabled: planInterval === PlanInterval.oneTime }}
            />
          </Form.Field>
          <Form.Field width="4">
            <label>Plan End Date</label>
            <Field
              name="planEndDate"
              component={DatePicker}
              fluid
              settings={{ disabled: planInterval === PlanInterval.oneTime }}
            />
          </Form.Field>
        </React.Fragment>
      )}
    </Form.Group>
  );
};

BillingPlan.propTypes = {};

export default BillingPlan;
