import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Actions } from '../../ducks/forms';
import {
  Actions as VisibleFieldActions,
  Selectors,
} from '../../ducks/visibleFields';

import VisibleFormFields from './VisibleFormFields';
import Models from '../../parse/models';
const { fetchForms } = Actions.thunks;

const { addVisibleField, removeVisibleField } = VisibleFieldActions.creators;

class VisibleFormFieldsContainer extends React.Component {
  componentDidMount = () => {
    const { business } = this.props;
    this.props.fetchForms(new Models.Business({ id: business.objectId }));
  };

  handleChange = (visibleFieldId, visible) => {
    console.log(visibleFieldId, visible);
    const data = {
      report: this.props.report,
      visibleFieldId,
    };
    if (visible) {
      this.props.addVisibleField(data);
    } else {
      this.props.removeVisibleField(data);
    }
  };

  render = () => {
    const { formFields } = this.props;
    // console.log(formFields);
    return (
      <VisibleFormFields
        handleChange={this.handleChange}
        formFields={formFields}
      />
    );
  };
}

const mapStateToProps = (state, props) => ({
  business: state.business,
  formFields: Selectors.getReportFields(state, props),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addVisibleField,
      removeVisibleField,
      fetchForms,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VisibleFormFieldsContainer);
