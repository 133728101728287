import Immutable from 'seamless-immutable';

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.fields
  ) {
    return state.merge(action.data.entities.fields);
  }
  return state;
};
