import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRouteContainer = props => {
  const { currentUser, component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props =>
        currentUser && currentUser.objectId ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps)(ProtectedRouteContainer);
