import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container,
  Grid,
  Segment,
  Header,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import { Actions } from '../../ducks/subscriptions';
import SubscriptionForm from './SubscriptionForm';
import formatCurrency from '../../utils/formatCurrrency';

const { updateSubscription } = Actions.thunks;

class SubscriptionContainer extends React.Component {
  handleSubmit = data => {
    console.log(data);
    let planEndDate;
    if (data.planEndDate.iso) {
      planEndDate = new Date(data.planEndDate.iso);
    } else if (data.planEndDate) {
      planEndDate = new Date(data.planEndDate);
    }

    const amount = formatCurrency(data.amount, false, 'usd');

    return this.props.updateSubscription({
      objectId: data.objectId,
      planEndDate,
      amount,
    });
  };
  render = () => {
    const subscription = this.props.subscriptions[this.props.subscriptionId];
    if (!subscription) {
      return (
        <Container fluid style={{ height: '100%' }}>
          <Dimmer inverted active>
            <Loader inverted />
          </Dimmer>
        </Container>
      );
    }

    // const account = this.props.accounts[subscription.account];
    const student = this.props.students[subscription.student];
    const program = this.props.programs[subscription.program];
    // const registration = this.props.registrations[subscription.registration];

    // console.log(subscription);
    const initialValues = Object.assign(
      {
        ...subscription,
      },
      { amount: formatCurrency(subscription.amount, true, 'usd') },
    );
    return (
      <Container fluid>
        <Grid centered columns={2}>
          <Grid.Column>
            <Segment basic>
              <Segment basic>
                <Header>
                  {student.firstName} {student.lastName}
                  <Header.Subheader>{program.name}</Header.Subheader>
                </Header>
              </Segment>
              <Segment basic>
                <SubscriptionForm
                  onSubmit={this.handleSubmit}
                  initialValues={initialValues}
                />
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    );
  };
}

SubscriptionContainer.propTypes = {};

const mapStateToProps = state => ({
  students: state.students,
  programs: state.programs,
  users: state.users,
  accounts: state.accounts,
  // registrations: state.registrations,
  subscriptions: state.subscriptions,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateSubscription }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionContainer);
