import Parse from 'parse';

Parse.initialize('crootapi', '2c@xgwZ#]');
if (window.location.hostname === 'localhost') {
  Parse.serverURL = 'http://localhost:8080/parse';
} else {
  Parse.serverURL = 'https://api-dot-communityroot.appspot.com/parse';
}
// Parse.enableLocalDatastore();

export default Parse;

// Parse.serverURL = 'https://crootapp.appspot.com/parse';

// console.log(window.location);

// console.log('Inits parse');
