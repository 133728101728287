import React from "react";
import { Container, Segment, Table } from "semantic-ui-react";

class StudentAccount extends React.Component {
  render = () => {
    return (
      <Container fluid>
        <Segment basic color="grey">
          <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">Account</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.Cell>Kevin Flynn</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      </Container>
    );
  };
}

StudentAccount.propTypes = {};

export default StudentAccount;
