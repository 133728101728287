import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions, Selectors } from '../../ducks/programs';
import Models from '../../parse/models';
import { Form } from 'semantic-ui-react';

const { fetchPrograms } = Actions.thunks;

class ProgramsFilterContainer extends React.Component {
  componentDidMount = () => {
    const business = Models.Business.createWithoutData(
      this.props.business.objectId,
    );
    this.props.fetchPrograms(business);
  };
  render = () => {
    const { business, input, fetchPrograms, ...props } = this.props;
    const value = input.value.length > 0 ? input.value : [];
    return (
      <Form.Dropdown
        onChange={(e, d) => input.onChange(d.value)}
        selection
        {...props}
        value={value}
      />
    );
  };
}

const mapStateToProps = state => ({
  business: state.business,
  options: Selectors.getProgramOptions(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchPrograms }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramsFilterContainer);
