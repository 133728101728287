import React from 'react';
import ProgramFormsContainer from './ProgramFormsContainer';
import ProgramSetupSection from '../../ProgramSetupSection';

const ProgramForms = props => (
  <ProgramSetupSection header="Forms">
    <ProgramFormsContainer />
  </ProgramSetupSection>
);

export default ProgramForms;
