import Immutable from "seamless-immutable";
import { normalize } from "normalizr";
import { SubmissionError } from "redux-form";
import { createSelector } from "reselect";
import orderBy from "lodash/orderBy";
import Parse from "parse";
import Models from "../parse/models";
import schema from "../schema";
import dataRow from "../utils/dataRow";

const ActionTypes = {
  ADD_STUDENT: "ADD_STUDENT",
  RECEIVED_STUDENT: "RECEIVED_STUDENT",
  RECEIVED_STUDENT_INFO: "RECEIVED_STUDENT_INFO",
  RECEIVED_STUDENTS: "RECEIVED_STUDENTS"
};

const Private = {
  fetchStudents: async (business, programs, locations, categories, search) => {
    try {
      let registrationQuery = null;
      if (
        (programs && programs.length > 0) ||
        (locations && locations.length > 0) ||
        (categories && categories.length > 0)
      ) {
        registrationQuery = new Parse.Query(Models.Registration);
        if (programs && programs.length > 0) {
          registrationQuery.containedIn("program", programs);
        }
        if (locations && locations.length > 0) {
          registrationQuery.containedIn("location", locations);
        }
        if (categories && categories.length > 0) {
          registrationQuery.containedIn("category", categories);
        }

        // debugger;
        registrationQuery.include("student");
        registrationQuery.include("student.account");
        registrationQuery.include("business");
        registrationQuery.include("student.account.user");
      }

      const query = new Parse.Query(Models.Student);
      if (registrationQuery) {
        query.matchesKeyInQuery(
          "objectId",
          "student.objectId",
          registrationQuery
        );
      }
      if (search) {
        // query.fullText("nameLower", search);
        query.contains("nameLower", search);
      }

      query.equalTo("business", business);
      query.include("account");
      query.include("business");
      query.include("account");
      query.include("account.user");
      return await query.find();
    } catch (error) {
      throw error;
    }
  },
  createOrderedStudents: students => {
    const values = Object.keys(students).map(studentId =>
      Object.assign(
        {
          name: `${students[studentId].firstName} ${students[studentId].lastName}`
        },
        students[studentId]
      )
    );
    return orderBy(values, ["name"]);
  },
  getStudents: state => state.students,
  getAccounts: state => state.accounts,
  getUsers: state => state.users,
  getFieldValues: state => state.fieldValues,
  studentResults: (students, accounts, users, fieldValues) => {
    const studentFieldValues = dataRow.createStudentValDictionary(fieldValues);

    let results = {};
    if (!students) {
      return results;
    }
    Object.keys(students).forEach(studentId => {
      const student = students[studentId];
      const account = accounts[student.account];
      const user = users[account.user];
      const studentVals = studentFieldValues[student.objectId];

      results[student.objectId] = dataRow.rowFromData({
        objectId: student.objectId,
        student,
        user,
        studentVals
      });
    });
    return results;
  }
};

const Selectors = {
  getStudentResults: createSelector(
    [
      Private.getStudents,
      Private.getAccounts,
      Private.getUsers,
      Private.getFieldValues
    ],
    Private.studentResults
  ),
  getStudents: createSelector(
    [Private.getStudents],
    Private.createOrderedStudents
  )
};

const Actions = {
  creators: {
    addStudent: data => ({
      data,
      type: ActionTypes.ADD_STUDENT
    }),
    receivedStudent: data => ({
      data,
      type: ActionTypes.RECEIVED_STUDENT
    }),
    receivedStudentInfo: data => ({
      data,
      type: ActionTypes.RECEIVED_STUDENT_INFO
    }),
    receivedStudents: data => ({
      data,
      type: ActionTypes.RECEIVED_STUDENTS
    })
  },
  thunks: {
    addStudent: args => async dispatch => {
      try {
        const student = new Models.Student(args);
        await student.save();
        const result = student.toJSON();
        dispatch(
          Actions.creators.addStudent(normalize(result, schema.student))
        );
        return result;
      } catch (error) {
        const err = {
          _error: error.message
        };
        throw new SubmissionError(err);
      }
    },
    fetchAccountStudents: account => async dispatch => {
      const query = new Parse.Query(Models.Student)
        .equalTo("account", account)
        .equalTo("active", true)
        .include("account");

      const students = await query.find();
      dispatch(
        Actions.creators.receivedStudents(
          normalize(
            students.map(s => s.toJSON()),
            schema.students
          )
        )
      );
    },
    fetchStudents: (
      business,
      programs,
      locations,
      categories,
      search
    ) => async dispatch => {
      try {
        const students = await Private.fetchStudents(
          business,
          programs,
          locations,
          categories,
          search
        );
        dispatch(
          Actions.creators.receivedStudents(
            normalize(
              students.map(s => s.toJSON()),
              schema.students
            )
          )
        );
        return students;
      } catch (error) {
        console.error("ERROR ", error);
      }
    },
    fetchStudent: student => async dispatch => {
      // console.log(student);
      await student.fetchWithInclude(["account", "parents", "account.user"]);
      // console.log("STUDNET: ", student.toJSON());
      dispatch(
        Actions.creators.receivedStudent(
          normalize(student.toJSON(), schema.student)
        )
      );
      // let ts = new Date().getTime();
      // // TODO: Finish fetching relevant student profile information
      // const rp = new Parse.Query(Models.Registration)
      //   .equalTo("student", student)
      //   .find();
      // const sp = new Parse.Query(Models.Subscription)
      //   .equalTo("student", student)
      //   .find();
      // const tp = new Parse.Query(Models.Transaction)
      //   .equalTo("transaction.account.students", student)
      //   .include("transactionItem")
      //   .find();
      // const fvp = new Parse.Query(Models.FormValue)
      //   .equalTo("student")
      //   .include("form")
      //   .include("form.fields")
      //   .include("fieldValues")
      //   .find();
      // const [
      //   registrations,
      //   subscriptions,
      //   transactions,
      //   formValues
      // ] = await Promise.all([rp, sp, tp, fvp]);
      // let fs = new Date().getTime();
      // console.log("TIME: ", (fs - ts) / 1000);
      // dispatch(
      //   Actions.creators.receivedStudentInfo(
      //     normalize(
      //       {
      //         formValues: formValues.map(o => o.toJSON()),
      //         transactions: transactions.map(o => o.toJSON()),
      //         subscriptions: subscriptions.map(o => o.toJSON()),
      //         registrations: registrations.map(o => o.toJSON())
      //       },
      //       schema.studentInfo
      //     )
      //   )
      // );
    },
    updateStudent: (student, values) => async dispatch => {
      await student.save(values);
      dispatch(
        Actions.creators.receivedStudent(
          normalize(student.toJSON(), schema.student)
        )
      );
      return student;
    },
    fetchAllStudents: students => async dispatch => {
      try {
        const results = await Parse.Object.fetchAll(students);
        dispatch(
          Actions.creators.receivedStudents(
            normalize(
              results.map(s => s.toJSON()),
              schema.students
            )
          )
        );
        return results;
      } catch (error) {
        console.error("ERROR ", error);
      }
    }
  }
};

export { Actions, Selectors };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (action.type === ActionTypes.RECEIVED_STUDENTS) {
    if (action.data.entities.students) {
      return Immutable(action.data.entities.students);
    }
    return defaultState;
  } else if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.students
  ) {
    return state.merge(action.data.entities.students);
  }
  return state;
};

// const account = new Models.Account();
// account.id = 'XKjXiObDBW';
// const query = new Parse.Query(Models.Student);
// query.first().then(result => {
//   result.set('accounts', [account]);
//   result.save();
// });
