import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { Selectors } from "../../../../ducks/shoppingCart";
import { Header, Segment, Grid, Container, Message } from "semantic-ui-react";
import ShoppingCartContainer from "../ShoppingCartContainer";

const RegisterStudentHeaderContainer = ({ student }) => (
  <Container fluid>
    <Route
      path={"/register/programs"}
      render={props => <Message header="Select a program to enroll" />}
    />
    {student && (
      <Segment basic>
        <Grid columns={2} reversed="tablet">
          <Grid.Column computer={12} tablet={16}>
            <Header as="h5" textAlign="left">
              {student.firstName} {student.lastName}
            </Header>
          </Grid.Column>
          <Grid.Column computer={4} textAlign="right" tablet={16}>
            <ShoppingCartContainer />
          </Grid.Column>
        </Grid>
      </Segment>
    )}
  </Container>
);

const mapStateToProps = state => ({
  student: Selectors.getStudents(state)
});

export default connect(mapStateToProps)(RegisterStudentHeaderContainer);
