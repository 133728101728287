import React from "react";
import { Button, Icon, Popup, Container } from "semantic-ui-react";

class FilterFormPopup extends React.Component {
  state = { isOpen: false };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleFilterChange = filter => {
    this.setState({ isOpen: false });
  };

  render = () => {
    return (
      <Popup
        content={
          <Container text style={{ maxWidth: "400px" }}>
            {this.props.children}
          </Container>
        }
        on="click"
        open={this.state.isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        position="bottom right"
        trigger={
          <Button type="button" icon>
            <Icon name="filter" />
          </Button>
        }
      />
    );
  };
}

export default FilterFormPopup;
