import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Icon } from "semantic-ui-react";
import config from "../../config";

import { Actions } from "../../ducks/paymentMethods";

const { addElectronicCheck } = Actions.thunks;

class PlaidMethodContainer extends React.Component {
  handleResponse = (public_token, metadata) => {
    console.log("public_token: " + public_token);
    console.log("META DATA: ", metadata);
    console.log("account ID: " + metadata.account_id);
    this.props.addElectronicCheck(
      public_token,
      metadata.account_id,
      this.props.currentUser.objectId
    );
  };

  handleClick = () => {
    const link = window.Plaid.create({
      ...config.plaid,
      product: ["auth"],
      userLegalName: this.props.currentUser.name,
      userEmailAddress: this.props.currentUser.email,
      selectAccount: true,
      onSuccess: this.handleResponse,
      onExit: (err, metadata) => {
        // The user exited the Link flow.
        if (err != null) {
          // The user encountered a Plaid API error prior to exiting.
        }
        console.log(err, metadata);
      }
    });
    link.open();
  };
  render = () => {
    return (
      <Form.Button fluid type="button" onClick={this.handleClick}>
        <Icon name="university" />
        Electronic Check
      </Form.Button>
    );
  };
}

PlaidMethodContainer.propTypes = {};

const mapStateToProps = state => ({
  currentUser: state.currentUser
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addElectronicCheck
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaidMethodContainer);
