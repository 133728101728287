import React from "react";

import { Link } from "react-router-dom";
import { Grid, Message } from "semantic-ui-react";
import SignupFormContainer from "./SignupFormContainer";
import LogoHeaderContainer from "../LogoHeaderContainer";

const Signup = ({ business }) => (
  <div className="login-form">
    {/*
Heads up! The styles below are necessary for the correct render of this example.
You can do same with CSS, the main idea is that all the elements up to the `Grid`
below must have a height of 100%.
*/}

    <style>{`
body > div,
body > div > div,
body > div > div > div.login-form {
height: 100%;
}
`}</style>
    <Grid textAlign="center" style={{ height: "100%" }}>
      <Grid.Row
        textAlign="center"
        style={{ height: "30%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <LogoHeaderContainer />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        height="80%"
        //verticalAlign="middle"
        style={{ height: "70%" }}
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <SignupFormContainer />
          <Message>
            Already have an account? <Link to="/login">Login</Link>
          </Message>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

export default Signup;
