import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Actions as ShoppingCartActions } from "../../../../ducks/shoppingCart";
import { Selectors } from "../../../../ducks/students";
import RegisterStudents from "./RegisterStudents";

const { addStudentShoppingCart } = ShoppingCartActions.creators;

class RegisterStudentsContainer extends React.Component {
  componentDidUpdate = prevProps => {
    console.log("UPDATED WITH ACCOUNT: ", this.props.currentAccount);
  };
  handleSelect = studentId => {
    let registrations = {};
    let data = {
      activeStudent: studentId,
      registrations
    };
    this.props.addStudentShoppingCart(data);
  };
  render() {
    const { currentAccount, students, shoppingCart } = this.props;
    console.log("CURRENT ACCOUNT: ", currentAccount);
    return (
      <RegisterStudents
        currentAccount={currentAccount}
        shoppingCart={shoppingCart}
        students={students}
        handleSelect={this.handleSelect}
        disabled={!currentAccount.objectId}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    currentAccount: state.currentAccount,
    students: Selectors.getStudents(state),
    shoppingCart: state.shoppingCart
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addStudentShoppingCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterStudentsContainer);
