import React from "react";
import { Dropdown, Button } from "semantic-ui-react";

class VisibleFormFields extends React.Component {
  state = {
    visibleFields: this.props.visibleFields || [],
    open: false
  };

  render = () => {
    const { formFields, handleChange } = this.props;
    return (
      <Dropdown
        item
        as={Button}
        text="Visible Fields"
        icon="eye slash"
        // floating
        scrolling
        labeled
        // button
        className="icon"
        onClick={e => {
          this.setState({
            open: !this.state.open
          });
        }}
        onBlur={() =>
          this.setState({
            open: false
          })
        }
      >
        <Dropdown.Menu
          onClick={e => {
            e.stopPropagation();
          }}
          open={this.state.open}
        >
          <Dropdown.Header icon="tags" content="Filter by tag" />
          <Dropdown.Divider onClick={e => e.stopPropagation()} />
          {formFields.map((form, i) => (
            <React.Fragment key={form.key}>
              <Dropdown.Header content={form.text} />
              {form.options.map(option => (
                <Dropdown.Item
                  onClick={(e, data) => {
                    e.stopPropagation();
                    handleChange(option.key, !option.selected);
                  }}
                  key={option.key}
                  content={
                    <div
                      className={`ui checkbox toggle ${
                        option.selected ? "checked" : ""
                      }`}
                      onClick={e => {
                        console.log("Clicked the FIELD ", e);
                        // this.toggleSelected(option.key);
                        // e.stopPropagation();
                      }}
                    >
                      <input
                        checked={option.selected}
                        type="checkbox"
                        className="hidden"
                        onChange={e => e}
                      />
                      <label
                        onClick={e => {
                          // console.log('Clicked the LABEL ', e);
                          // e.stopPropagation();
                        }}
                      >
                        {option.text}
                      </label>
                    </div>
                  }
                />
              ))}
            </React.Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
}

export default VisibleFormFields;
