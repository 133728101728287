import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ChoiceForm from './ChoiceForm';
import { Actions } from '../../ducks/choices';
import Models from '../../parse/models';
const { addChoice } = Actions.thunks;

class ChoiceFormContainer extends React.Component {
  handleSubmit = async values => {
    const business = new Models.Business({ id: this.props.business.objectId });
    const choice = await this.props.addChoice(
      Object.assign({ business }, values),
    );
    if (this.props.handleComplete) {
      this.props.handleComplete(choice);
    }
    return choice;
  };
  render() {
    const { choice } = this.props;
    return (
      <ChoiceForm
        initialValues={{
          name: choice.name,
          capacity: choice.capacity,
          id: choice.objectId,
          amount: choice.amount,
        }}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addChoice,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChoiceFormContainer);
