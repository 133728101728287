import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions } from "../../ducks/paymentMethods";

import { Elements, StripeProvider } from "react-stripe-elements";
import PaymentMethodForm from "./PaymentMethodForm";
import config from "../../config";

const { addPaymentMethod } = Actions.thunks;

class PaymentMethodFormContainer extends Component {
  handleSubmit = stripeMethod => {
    // User clicked submit
    const { token } = stripeMethod;
    const { user } = this.props;
    this.props.addPaymentMethod(token, user.objectId);
  };

  render() {
    return (
      <StripeProvider apiKey={config.stripe.apiKey}>
        <Elements>
          <PaymentMethodForm handleSubmit={this.handleSubmit} />
        </Elements>
      </StripeProvider>
    );
  }
}

const mapStateToProps = state => ({
  user: state.currentUser
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addPaymentMethod
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodFormContainer);
