import React from 'react';
import { Menu, Segment, Form } from 'semantic-ui-react';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import CalendarPicker from './CalendarPicker';
import DatePicker from '../../../DatePicker';
import DayPicker from './DayPicker';
import ProgramSetupSection from '../../ProgramSetupSection';

class ScheduleType extends React.Component {
  state = {
    activeItem: 'Start and End Dates',
    highlighted: [],
    lastSelectedDate: new Date(),
  };
  handleChange = d => {
    let highlighted;
    if (this.state.highlighted.indexOf(d) > -1) {
      this.state.highlighted.forEach(date => {
        if (date === d) {
          return;
        }
        highlighted.push(date);
      });
    } else {
      highlighted = this.state.highlighted;
      // highlighted.push(d);
      highlighted.push(d);
    }
    console.log(highlighted);
    this.setState({ highlighted, lastSelectedDate: d });
  };
  handleDayClick = (e, { name }) => {};

  handleItemClick = value => {
    this.props.input.onChange(value);
  };

  render() {
    const activeItem = this.props.input.value || 'daysOfWeek';
    // console.log(this.props.startDate);
    // console.log(this.props);
    return (
      <React.Fragment>
        <Menu attached="top" tabular>
          <Menu.Item
            name="Start and End Dates"
            active={activeItem === 'daysOfWeek'}
            onClick={() => this.handleItemClick('daysOfWeek')}
          />
          <Menu.Item
            name="Manual Dates"
            active={activeItem === 'dates'}
            onClick={() => this.handleItemClick('dates')}
          />
        </Menu>
        <Segment attached="bottom">
          {activeItem === 'daysOfWeek' && (
            <div>
              <Form.Field>
                <label>Meeting Days</label>
                <Field name="days" component={DayPicker} />
              </Form.Field>
              <Form.Group>
                <Form.Field>
                  <label>Start Date</label>
                  <Field
                    name="startDate"
                    // fluid
                    component={DatePicker}
                    settings={{
                      minDate: this.props.endDate,
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>End Date</label>
                  <Field
                    name="endDate"
                    // fluid
                    component={DatePicker}
                    settings={{
                      maxDate: this.props.startDate,
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Start Time</label>
                  <Field
                    name="startTime"
                    // fluid

                    settings={{
                      showTimeSelect: true,
                      showTimeSelectOnly: true,
                      timeIntervals: 5,
                      dateFormat: 'h:mm aa',
                      timeCaption: 'Time',
                    }}
                    component={DatePicker}
                  />
                </Form.Field>
                <Form.Field>
                  <label>End Time</label>
                  <Field
                    name="endTime"
                    // fluid
                    settings={{
                      showTimeSelect: true,
                      showTimeSelectOnly: true,
                      timeIntervals: 5,
                      dateFormat: 'h:mm aa',
                      timeCaption: 'Time',
                    }}
                    component={DatePicker}
                  />
                </Form.Field>
              </Form.Group>
            </div>
          )}
          {activeItem === 'dates' && (
            <Form.Field>
              <label>Select individual dates and times</label>
              <Field name="dates" component={CalendarPicker} />
            </Form.Field>
          )}
        </Segment>
      </React.Fragment>
    );
  }
}

const ProgramScheduleContainer = props => (
  <ProgramSetupSection header="Schedule">
    <Field
      name="scheduleType"
      component={ScheduleType}
      startDate={props.startDate}
      endDate={props.endDate}
    />
  </ProgramSetupSection>
);

const mapStateToProps = state => {
  const selector = formValueSelector('programSetup');
  return {
    startDate: selector(state, 'startDate'),
    endDate: selector(state, 'endDate'),
  };
};

export default connect(mapStateToProps)(ProgramScheduleContainer);
