import React from "react";
import { Container, Grid, Segment } from "semantic-ui-react";
import ProfileContainer from "./ProfileContainer";
import StudentRegistrationsContainer from "./StudentRegistrationsContainer";
import StudentAccount from "./StudentAccount";

const Student = ({
  match: {
    params: { studentId }
  }
}) => (
  <Container fluid>
    <Segment basic>
      <ProfileContainer studentId={studentId} />
    </Segment>
    <Container fluid>
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <StudentRegistrationsContainer studentId={studentId} />
          </Grid.Column>
          <Grid.Column>
            <StudentAccount />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </Container>
);

Student.propTypes = {};

export default Student;
