import Immutable from 'seamless-immutable';
import { normalize } from 'normalizr';
import Parse from 'parse';

import { SubmissionError } from 'redux-form';
import schema from '../schema';
import Models from '../parse/models';

const ActionTypes = {
  ADD_FEE: 'ADD_FEE',
  RECEIVED_FEES: 'RECEIVED_FEES',
};

const Actions = {
  creators: {
    addFee: data => ({
      data,
      type: ActionTypes.ADD_FEE,
    }),

    receivedFees: data => ({
      data,
      type: ActionTypes.RECEIVED_FEES,
    }),
  },
  thunks: {
    addFee: data => async dispatch => {
      try {
        const fee = new Models.Fee();
        await fee.save(data);
        const result = normalize(fee.toJSON(), schema.fee);
        dispatch(Actions.creators.addFee(result));
        return fee.toJSON();
      } catch (error) {
        const err = {
          _error: error.message,
        };
        throw new SubmissionError(err);
      }
    },
    fetchFees: business => async dispatch => {
      try {
        const query = new Parse.Query(Models.Fee);
        query.equalTo('business', business);
        const fees = await query.find();
        return dispatch(
          Actions.creators.receivedFees(
            normalize(fees.map(fee => fee.toJSON()), schema.fees),
          ),
        );
      } catch (error) {
        // const err = {
        //   _error: error.message,
        // };
        console.error(error);
      }
    },
  },
};

export { Actions };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.fees
  ) {
    return state.merge(action.data.entities.fees);
  }
  return state;
};
