import React from "react";
import { Dropdown as SemanticDropdown } from "semantic-ui-react";

class Dropdown extends React.Component {
  handleChange = (e, data) => {
    if (this.props.multiple) {
      // console.log('THIS IS MULTIPLE: ', data);
      this.props.input.onChange(data.value);
    } else {
      this.props.input.onChange(data.value);
    }
  };
  render() {
    // console.log("THJS VALUE: ", this.props.input.value);
    return (
      <SemanticDropdown
        {...this.props}
        value={this.props.input.value || (this.props.multiple ? [] : "")}
        onChange={this.handleChange}
      />
    );
  }
}
export default Dropdown;
