import React from "react";
import { Route, Switch } from "react-router-dom";

import Dashboard from "../../Dashboard";
import Home from "../../Home";
import FormsContainer from "../../FormsContainer";
import ProgramsContainer from "../../ProgramsContainer";
import ProgramSetupContainer from "../../ProgramSetupContainer";
import StaffContainer from "../../StaffContainer";
import StudentsContainer from "../../StudentsContainer";
import Student from "../../Student";
import BusinessSettingsContainer from "../../BusinessSettingsContainer";
import FormBuilderContainer from "../../FormBuilderContainer";
import StripeContainer from "../../StripeContainer";
import RegistrationsContainer from "../../RegistrationsContainer";
import TransactionsContainer from "../../TransactionsContainer";
import SubscriptionsContainer from "../../SubscriptionsContainer";

const AdminRoutes = () => (
  <Dashboard>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/forms" component={FormsContainer} />
      <Route exact path="/forms/:formId" component={FormBuilderContainer} />
      <Route exact path="/programs" component={ProgramsContainer} />
      <Route
        exact
        path="/registrations/:programId"
        component={RegistrationsContainer}
      />
      <Route
        exact
        path="/programs/edit/:programId"
        component={ProgramSetupContainer}
      />
      <Route
        exact
        path="/programs/copy/:programId"
        component={ProgramSetupContainer}
      />
      <Route exact path="/staff" component={StaffContainer} />
      <Route exact path="/students" component={StudentsContainer} />
      <Route exact path="/students/:studentId" component={Student} />
      <Route
        exact
        path="/billing/transactions"
        component={TransactionsContainer}
      />
      <Route exact path="/billing/plans" component={SubscriptionsContainer} />
      <Route
        exact
        path="/business/settings"
        component={BusinessSettingsContainer}
      />
      <Route exact path="/stripe" component={StripeContainer} />
    </Switch>
  </Dashboard>
);

export default AdminRoutes;
