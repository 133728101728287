import React from 'react';
import { Container, Segment, Header } from 'semantic-ui-react';

const ProgramSetupSection = ({ header, children }) => (
  <Container>
    <Container>
      <Segment basic className="place-holder" textAlign="left">
        <div />
        <Header>{header}</Header>
      </Segment>
    </Container>
    <Container>
      <Segment>{children}</Segment>
    </Container>
  </Container>
);

export default ProgramSetupSection;
