const FormType = {
  studentForm: "studentForm",
  accountForm: "accountForm"
};

const ChargePaymentStatus = {
  succeeded: "succeeded",
  pending: "pending",
  failed: "failed"
};

const InvoiceStatus = {
  draft: "draft",
  open: "open",
  paid: "paid",
  uncollectible: "uncollectible",
  void: "void"
};

const TransactionType = {
  sale: "sale",
  manualInvoice: "manualInvoice",
  subscriptionInvoice: "subscriptionInvoice",
  payment: "payment"
};

const TransactionItemType = {
  program: "program",
  deposit: "deposit",
  fee: "fee",
  discount: "discount"
};

const ActiveStatus = {
  active: "active",
  inActive: "inActive"
};

const ApprovalStatus = {
  approved: "approved",
  pending: "pending",
  denied: "denied"
};

const PlanInterval = {
  monthly: "monthly",
  weekly: "weekly",
  oneTime: "oneTime"
};

const ChargeStatus = {
  active: "active",
  pendingCharge: "pendingCharge",
  inactive: "inactive"
};

const RegistrationStatus = {
  enrolled: "enrolled",
  waitlisted: "waitlisted",
  pendingApproval: "pendingApproval",
  pendingDiscount: "pendingDiscount",
  inactive: "inactive"
};

const RegistrationStatusDisplay = {
  enrolled: "Enrolled",
  waitlisted: "Waitlisted",
  pendingApproval: "Pending Approval",
  pendingDiscount: "Pending Discount Approval",
  inactive: "Inactive"
};

const RegistrationType = {
  course: "course",
  daily: "daily",
  dropin: "dropin"
};

const StaffType = {
  owner: "owner",
  admin: "admin",
  director: "director",
  teacher: "teacher"
};

const StripeAccountType = {
  express: "express",
  standard: "standard"
};

export {
  ActiveStatus,
  ChargeStatus,
  ChargePaymentStatus,
  FormType,
  TransactionItemType,
  TransactionType,
  InvoiceStatus,
  ApprovalStatus,
  PlanInterval,
  RegistrationStatus,
  RegistrationStatusDisplay,
  RegistrationType,
  StaffType,
  StripeAccountType
};
export default {
  ActiveStatus,
  ChargeStatus,
  ChargePaymentStatus,
  FormType,
  TransactionItemType,
  TransactionType,
  InvoiceStatus,
  ApprovalStatus,
  PlanInterval,
  RegistrationStatus,
  RegistrationStatusDisplay,
  RegistrationType,
  StaffType,
  StripeAccountType
};
