import React from "react";
import { Field, reduxForm } from "redux-form";
import { Container, Form, Segment, Header } from "semantic-ui-react";
const required = value => (value ? undefined : "Required");

const renderField = props => {
  const {
    input,
    type,
    meta: { error, touched },
    placeholder
  } = props;
  return (
    <Form.Input
      type={type}
      placeholder={placeholder}
      {...input}
      size="large"
      fluid
      error={error !== undefined && touched}
    />
  );
};

const ProgramForm = ({ handleSubmit, submitting }) => (
  <Container>
    <Segment basic>
      <Header>Add Program</Header>
      <Form onSubmit={handleSubmit}>
        <Field
          type="text"
          name="name"
          placeholder="Program or Room Name"
          component={renderField}
          validate={required}
        />
        <Form.Button loading={submitting} color="teal" fluid size="large">
          Save
        </Form.Button>
      </Form>
    </Segment>
  </Container>
);

export default reduxForm({
  form: "programForm"
})(ProgramForm);
