import React from "react";
import { Header, Button, Grid, Table } from "semantic-ui-react";
import moment from "moment";
import EditName from "./EditName";
import getInitials from "../../../../utils/getInitials";
import utils from "../../../../utils";
import AvatarContainer from "./AvatarContainer";

class Profile extends React.PureComponent {
  state = { isEditing: false };

  componentDidUpdate = prevProps => {
    // console.log("THIS IS EDITING: ", this.state.isEditing);
    if (this.props.submitNameSucceeded && !prevProps.submitNameSucceeded) {
      this.setState({
        isEditing: false
      });
    }
  };

  render = () => {
    const {
      objectId,
      avatar,
      name,
      firstName,
      lastName,
      dob,
      grade,
      notes
    } = this.props;
    const { isEditing } = this.state;
    const url =
      avatar && avatar.url && typeof avatar.url === "string"
        ? avatar.url
        : null;
    const initials = firstName ? getInitials(firstName, lastName) : null;
    let component = (
      <Grid verticalAlign="middle">
        <Grid.Column>
          <AvatarContainer url={url} studentId={objectId} initials={initials} />
        </Grid.Column>
        <Grid.Column width={6}>
          <Header as="h3">
            <Header.Content>
              {name} <span />
              <Button
                style={{ boxShadow: "none" }}
                basic
                icon="pencil"
                onClick={() => this.setState({ isEditing: true })}
              />
            </Header.Content>
          </Header>
        </Grid.Column>
      </Grid>
    );
    if (isEditing) {
      component = (
        <EditName
          initialValues={{ firstName, lastName }}
          onSubmit={this.props.onSubmit}
        />
      );
    }

    // console.log(utils.valueFromDate(dob));
    const birthday =
      moment(utils.valueFromDate(dob)).format("MMM D, YYYY") || "";
    return (
      <React.Fragment>
        {component}
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>DOB</Table.Cell>
              <Table.Cell>{birthday}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Grade</Table.Cell>
              <Table.Cell>{grade}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Notes</Table.Cell>
              <Table.Cell>{notes}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  };
}

Profile.propTypes = {};

export default Profile;
