import React from "react";
import { reduxForm, Field } from "redux-form";
import { Form } from "semantic-ui-react";
import FilterFormPopup from "../../FilterFormPopup";
import FilterForm from "../../FilterForm";
import FilterTagFormContainer from "../../FilterTagFormContainer";

const RegistrationsFilterContainer = props => (
  <Form onSubmit={props.handleSubmit}>
    <Form.Group>
      <Field
        width="6"
        name="search"
        placeholder="Search by student name"
        component={Form.Input}
      />
      <Form.Field>
        <FilterFormPopup>
          <FilterForm
            report="students"
            filters={props.filterFields}
            days={props.days}
            // onChange={props.onChange}
          />
        </FilterFormPopup>
      </Form.Field>
    </Form.Group>
    <FilterTagFormContainer
      onChange={props.onChange}
      formName="registrationsFiltersForm"
    />
  </Form>
);

export default reduxForm({ form: "registrationsFiltersForm" })(
  RegistrationsFilterContainer
);
