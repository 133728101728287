import { schema } from "normalizr";

const parseId = { idAttribute: "objectId" };
const account = new schema.Entity("accounts", {}, parseId);
const accountSettings = new schema.Entity("accountSettings", {}, parseId);
const business = new schema.Entity("business", {}, parseId);
const businessSettings = new schema.Entity("businessSettings", {}, parseId);
const category = new schema.Entity("categories", {}, parseId);
const choice = new schema.Entity("choices", {}, parseId);
const discount = new schema.Entity("discounts", {}, parseId);
const fee = new schema.Entity("fees", {}, parseId);
const form = new schema.Entity(
  "forms",
  {},
  {
    idAttribute: "objectId"
  }
);

const field = new schema.Entity("fields", {}, parseId);
const fieldValue = new schema.Entity("fieldValues", {}, parseId);
const formValue = new schema.Entity("formValues", {}, parseId);
const gateway = new schema.Entity("gateways", {}, parseId);

const transaction = new schema.Entity("transactions", {}, parseId);
const transactionItem = new schema.Entity("transactionItems", {}, parseId);

const location = new schema.Entity("locations", {}, parseId);
const paymentMethod = new schema.Entity("paymentMethods", {}, parseId);
const parent = new schema.Entity("parents", {}, parseId);
const program = new schema.Entity("programs", {}, parseId);

const registration = new schema.Entity("registrations", {}, parseId);
const staff = new schema.Entity("staff", {}, parseId);
const student = new schema.Entity("students", {}, parseId);
const subscription = new schema.Entity("subscriptions", {}, parseId);

const user = new schema.Entity("users", {}, parseId);
const shoppingCart = new schema.Entity("shoppingCart", {}, {});

// Arrays
const accounts = [account];
const choices = [choice];
const categories = [category];
const discounts = [discount];
const fees = [fee];
const forms = [form];
const fields = [field];
const fieldValues = [fieldValue];
const formValues = [formValue];
const transactions = [transaction];
const transactionItems = [transactionItem];
const locations = [location];
const students = [student];
const paymentMethods = [paymentMethod];
const programs = [program];
const parents = [parent];
const registrations = [registration];
const staffs = [staff];
const subscriptions = [subscription];

// Definitions
account.define({
  students,
  user,
  business
});
accountSettings.define({
  account
});
businessSettings.define({
  business
});
discount.define({
  business
});
form.define({
  business,
  fields
});
formValue.define({
  form,
  fieldValues,
  student
});
fieldValue.define({
  field,
  account,
  student
});
gateway.define({
  business
});
transaction.define({
  account,
  business,
  transactionItems,
  registrations
});
transactionItem.define({
  transaction,
  program,
  student
});
location.define({
  business
});
program.define({
  business,
  category,
  choices,
  discounts,
  fees,
  studentForms: forms,
  accountForms: forms,
  forms,
  location
});
staff.define({
  business,
  user
});
student.define({
  account,
  business
});
shoppingCart.define({
  students
});

subscription.define({
  account,
  business,
  registration,
  program,
  student
});

paymentMethod.define({
  user
});

registration.define({
  program,
  student,
  transactionItem
});

const summary = new schema.Object({ students, programs });
const formsAndFieldValues = new schema.Object({ programs, fieldValues });
const formsAndFormValues = new schema.Object({ programs, formValues });

const studentInfo = new schema.Object({
  registrations,
  subscriptions,
  transactions,
  formValues
});
const session = new schema.Object({
  currentAccount: account,
  currentStaff: staff
});

// export default {
//   TRANSACTION: transaction,
//   TRANSACTIONS: [transaction]
// };

//

export default {
  account,
  accounts,
  accountSettings,
  businessSettings,
  category,
  categories,
  choice,
  choices,
  discount,
  discounts,
  fee,
  fees,
  form,
  forms,
  field,
  fields,
  fieldValues,
  formValue,
  formValues,
  formsAndFieldValues,
  formsAndFormValues,
  gateway,
  transaction,
  transactions,
  transactionItem,
  transactionItems,
  location,
  locations,
  paymentMethod,
  paymentMethods,
  parent,
  parents,
  program,
  programs,
  registration,
  registrations,
  session,
  subscription,
  subscriptions,
  summary,
  student,
  students,
  studentInfo,
  staff,
  staffs
};
