import React from "react";
import { Image, Button, Loader } from "semantic-ui-react";
import "./AvatarEditor.css";
class AvatarEditor extends React.PureComponent {
  state = {
    url: this.props.url
  };

  componentDidUpdate = prevProps => {
    // console.log("COMPONENT UPDATED!!!!!! ", prevProps);
    if (this.props.url && this.props.url !== prevProps.url) {
      this.setState({
        url: this.props.url
      });
    }
  };

  _handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    reader.onloadend = () => {
      this.setState({
        file,
        url: reader.result
      });
      this.props.onChange(file);
    };

    reader.readAsDataURL(file);
  }

  render = () => {
    const { initials } = this.props;
    const { url } = this.state;
    let avatar = <Image circular src={url} />;
    return (
      <div className="preview-component">
        <Loader active={this.props.loading} />
        <Button
          as="label"
          htmlFor="file"
          content={url ? avatar : initials}
          className={url ? "avatar-editor editor-image" : "avatar-editor"}
        />
        <input
          onChange={e => this._handleImageChange(e)}
          id="file"
          hidden
          type="file"
        />
      </div>
    );
  };
}

AvatarEditor.propTypes = {};

export default AvatarEditor;
