import React from 'react';
// import moment from 'moment';
import ReactDatePicker from 'react-datepicker';

const valueFromDate = date => {
  if (date.iso) {
    // console.log('ISO DATE: ', date.iso);
    return new Date(date.iso);
  }
  return new Date(date);
};

class DatePicker extends React.Component {
  handleChange = date => {
    this.props.input.onChange(date);
  };
  render() {
    const { input, settings } = this.props;
    // debugger;
    const value = input.value ? valueFromDate(input.value) : null;
    const minDate =
      settings && settings.minDate ? valueFromDate(settings.minDate) : null;
    const maxDate =
      settings && settings.maxDate ? valueFromDate(settings.maxDate) : null;
    // console.log('SETTINGS: ', settings);
    // console.log(this.props);
    // console.log(value);
    // console.log(settings, minDate, maxDate);
    // console.log('PROPS:S ', this.props);
    return (
      <ReactDatePicker
        {...settings}
        dateForm="MM/DD/YYYY"
        onChange={this.handleChange}
        selected={value}
        maxDate={minDate}
        minDate={maxDate}
      />
    );
  }
}

export default DatePicker;
