import React from 'react';
import { Form } from 'semantic-ui-react';

const TextArea = props => {
  return (
    <Form.TextArea
      {...props.input}
      label={props.label}
      rows={props.rows}
      placeholder={props.placeholder}
    />
  );
};
export default TextArea;
