import Immutable from "seamless-immutable";
import { normalize } from "normalizr";
import Parse from "parse";

import schema from "../schema";
import Models from "../parse/models";

const ActionTypes = {
  ADD_GATEWAY: "ADD_GATEWAY",
  RECEIVED_GATEWAY: "RECEIVED_GATEWAY"
};

const Actions = {
  creators: {
    addGateway: data => ({
      data,
      type: ActionTypes.ADD_GATEWAY
    }),

    receivedGateway: data => ({
      data,
      type: ActionTypes.RECEIVED_GATEWAY
    })
  },
  thunks: {
    fetchGateway: business => async dispatch => {
      try {
        const query = new Parse.Query(Models.Gateway);
        query.equalTo("business", business);
        query.equalTo("active", true);
        const gateway = await query.first();
        if (gateway) {
          dispatch(
            Actions.creators.receivedGateway(
              normalize(gateway.toJSON(), schema.gateway)
            )
          );
        }
        return gateway;
      } catch (error) {
        // const err = {
        //   _error: error.message,
        // };
        console.error(error);
      }
    }
  }
};

export { Actions };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.gateways &&
    action.data.result
  ) {
    return Immutable(action.data.entities.gateways[action.data.result]);
  }
  return state;
};
