import React from "react";
import { connect } from "react-redux";
import { Header, Image } from "semantic-ui-react";

const LogoHeaderContainer = ({ business }) => (
  <Header as="h1" textAlign="center">
    <Image alt="" src={business && business.logo && business.logo.url} />{" "}
    {business.name}
  </Header>
);

const mapStateToProps = state => ({
  business: state.business
});

export default connect(mapStateToProps)(LogoHeaderContainer);
