import React from 'react';
import { Checkbox as SemanticCheckbox, Form } from 'semantic-ui-react';

const Checkbox = props => (
  <Form.Field>
    <SemanticCheckbox
      name={props.input.name}
      label={props.label}
      placeholder={props.placeholder}
      onChange={(e, data) => props.input.onChange(data.checked)}
      checked={props.input.value === true}
    />
  </Form.Field>
);
export default Checkbox;
