import React from "react";
import { Dimmer, Segment, Loader } from "semantic-ui-react";

const BlurredLoader = ({ loading, children }) => (
  <Dimmer.Dimmable as={Segment} basic dimmed={loading}>
    <Dimmer active={loading} inverted>
      <Loader active={loading} />
    </Dimmer>
    {children}
  </Dimmer.Dimmable>
);

export default BlurredLoader;
