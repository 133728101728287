import React from 'react';
import {
  Container,
  Dropdown,
  Form,
  Segment,
  Divider,
  Icon,
  Popup,
} from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';
import Immutable from 'seamless-immutable';
import './FieldRenderer.css';
import FieldRender from './FieldRender';
import RichTextBox from '../../../RichTextBox';
import FieldOptions from './FieldOptions';
import fieldOptions from './options.json';

class FieldRenderer extends React.Component {
  handleChangeLabel = value => {
    // console.log('Changed Label');
    const { field } = this.props;
    const { objectId } = field;
    this.props.handleChangeField(objectId, { label: value });
  };
  handleChangeRequired = value => {
    // console.log('Change Required');
    const { field } = this.props;
    const { objectId } = field;
    this.props.handleChangeField(objectId, { required: value });
  };
  handleChangeType = (e, data) => {
    // console.log('Changed Type');

    const { value } = data;
    const { field } = this.props;
    const { objectId } = field;
    this.props.handleChangeField(objectId, { type: value });
  };
  handleChangeOptions = options => {
    // console.log('Change Options');

    const { field } = this.props;
    const { objectId } = field;
    this.props.handleChangeField(objectId, { options });
  };
  handleDeleteField = () => {
    // console.log('Changed Delete');

    const { field } = this.props;
    const { objectId } = field;
    this.props.handleDeleteField(objectId);
  };
  render() {
    const { isEditing, field, toggleEdit } = this.props;
    const height = isEditing ? 'auto' : 0;
    const duration = isEditing ? 200 : 0;
    const localId = field.localId || field.objectId;
    const {
      label,
      // objectId,
      placeholder,
      options,
      required,
      type,
    } = field;
    const className = isEditing
      ? 'ui segment secondary is-editing'
      : 'ui segment basic';
    return (
      <div className={className}>
        <Container
          onClick={(e, d) => {
            e.stopPropagation();
            if (!isEditing) {
              toggleEdit(localId);
            }
          }}
          fluid
        >
          <AnimateHeight
            duration={300}
            height={height} // see props documentation below
          >
            <Segment basic>
              <Popup
                content="Remove field from the form"
                inverted
                trigger={
                  <Icon name="remove circle" onClick={this.handleDeleteField} />
                }
              />
            </Segment>
          </AnimateHeight>

          <Form.Field>
            <RichTextBox
              onSave={true}
              onChange={this.handleChangeLabel}
              placeholder={placeholder}
              value={label}
            />
          </Form.Field>
          <AnimateHeight
            duration={duration}
            height={height} // see props documentation below
          >
            <br />
            <Divider />
            <br />
            <div className="field">
              <div className="ui medium fluid input">
                <Dropdown
                  placeholder="Select a field type"
                  // fluid
                  search
                  selection
                  size="medium"
                  text="Field Type"
                  onChange={this.handleChangeType}
                  options={fieldOptions}
                  value={type}
                />
              </div>
            </div>
            {(type === 'multipleSelect' || type === 'singleSelect') && (
              <FieldOptions
                options={options || Immutable([])}
                handleChange={this.handleChangeOptions}
              />
            )}
          </AnimateHeight>

          <FieldRender type={type} options={options} />
          <AnimateHeight
            duration={300}
            height={height} // see props documentation below
          >
            <Segment basic>
              <Form.Checkbox
                label="Required"
                name="required"
                onChange={(e, data) => this.handleChangeRequired(data.checked)}
                checked={required === true}
              />
            </Segment>
          </AnimateHeight>
        </Container>
      </div>
    );
  }
}

export default FieldRenderer;
