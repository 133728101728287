import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

const EditCellRenderer = ({
  cellData,
  columnData,
  columnIndex,
  dataKey,
  rowData,
  rowIndex,
  isScrolling,
}) => {
  const { objectId } = rowData;
  return (
    // <div>Edit Cell FIX ME</div>
    <Link to={`/forms/${objectId}`}>
      <Icon name="edit" />
    </Link>
  );
};
export default EditCellRenderer;
