import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import reduce from 'lodash/reduce';

class TransactionTotalContainer extends React.Component {
  render = () => {
    const { items } = this.props;
    if (!items) {
      return <div />;
    }
    const amount = reduce(
      Object.keys(items) || [],
      (sum, objectId) => sum + items[objectId] || 0,
      0,
    );
    return (
      <b>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(amount)}
        <input name="amount" type="hidden" value={amount} />
      </b>
    );
  };
}

TransactionTotalContainer.propTypes = {};

const mapStateToProps = state => {
  const selector = formValueSelector('transactionForm');
  return {
    items: selector(state, 'transactionItemAmount'),
  };
};
export default connect(mapStateToProps)(TransactionTotalContainer);
