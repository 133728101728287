import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Segment } from "semantic-ui-react";
import { Actions, Selectors } from "../../ducks/transactions";
import Models from "../../parse/models";
import columns from "./columns";
import Spreadsheet from "../Spreadsheet";
import TransactionContainer from "../TransactionContainer";
import Overlay from "../Overlay";

const { fetchTransactions } = Actions.thunks;

class TransactionsContainer extends React.Component {
  state = {
    editOpen: false,
    sendReminderOpen: false,
    cancelTransactionOpen: false
  };
  // state = { open: true, transactionId: 'Ga69I6eFfE' };

  handleClose = () => this.setState({ editOpen: false });

  handleAction = (action, objectId) => {
    this.setState({ editOpen: true, transactionId: objectId });
  };
  componentDidMount = () => {
    const business = Models.Business.createWithoutData(
      this.props.business.objectId
    );
    this.props.fetchTransactions(business);
  };
  render = () => {
    console.log(this.props);
    const { cols, data } = this.props;
    return (
      <Overlay
        content={
          <TransactionContainer transactionId={this.state.transactionId} />
        }
        open={this.state.editOpen}
        handleClose={this.handleClose}
      >
        <Container fluid>
          <Segment basic></Segment>
        </Container>
        <Container fluid>
          <Segment basic>
            <Spreadsheet
              handleCellChange={this.handleAction}
              cols={cols}
              data={data}
            />
          </Segment>
        </Container>
      </Overlay>
      // <Container fluid style={{ position: 'relative' }}>
      //   {editOpen && (
      //     <Container
      //       fluid
      //       style={{
      //         height: '100%',
      //         position: 'absolute',
      //         top: 0,
      //         left: 0,
      //         backgroundColor: '#fff',
      //         zIndex: 9999,
      //       }}
      //     >
      //       <Segment basic style={{ paddingBottom: 0, marginBottom: 0 }}>
      //         <Grid>
      //           <Grid.Column floated="right">
      //             <Button
      //               // size="huge"
      //               // color="black"
      //               onClick={this.handleClose}
      //               icon="cancel"
      //               floated="right"
      //               style={{ marginRight: '1em', marginTop: '1em' }}
      //             />
      //           </Grid.Column>
      //         </Grid>
      //       </Segment>
      //       <TransactionContainer transactionId={this.state.transactionId} />
      //     </Container>
      //   )}
      //   <Container fluid>
      //     <Container fluid>
      //       <Segment basic></Segment>
      //     </Container>
      //     <Container fluid>
      //       <Segment basic>
      //         <Spreadsheet
      //           handleCellChange={this.handleAction}
      //           cols={cols}
      //           data={data}
      //         />
      //       </Segment>
      //     </Container>
      //   </Container>
      // </Container>
    );
  };
}

const mapStateToProps = state => ({
  business: state.business,
  cols: columns,
  data: Selectors.getTransactions(state)
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTransactions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionsContainer);
