import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomFormContainer from '../CustomFormContainer';
import { Actions, Selectors } from '../../ducks/forms';

const { fetchForm } = Actions.thunks;

class CustomFormViewContainer extends React.Component {
  componentDidMount = () => {
    const { formId } = this.props.match.params;
    this.props.fetchForm(formId);
  };

  render = () => (
    <CustomFormContainer
      formId={this.props.form.objectId}
      name={this.props.form.name}
      fields={this.props.form.fields}
    />
  );
}

const mapStateToProps = (state, props) => ({
  form: Selectors.formBuilder(state, props),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchForm,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomFormViewContainer);
