import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Segment } from "semantic-ui-react";
import { Actions, Selectors } from "../../ducks/subscriptions";
import Models from "../../parse/models";
import columns from "./columns";

import Spreadsheet from "../Spreadsheet";
import SubscriptionContainer from "../SubscriptionContainer";
import Overlay from "../Overlay";
const { fetchSubscriptions } = Actions.thunks;

class SubscriptionsContainer extends React.Component {
  state = { open: false, subscriptionId: null };
  // state = { open: true, subscriptionId: '1TIskOEORl' };

  handleClose = () => this.setState({ open: false, subscriptionId: null });
  handleOpen = () => this.setState({ open: true });

  componentDidMount = () => {
    const business = Models.Business.createWithoutData(
      this.props.business.objectId
    );
    this.props.fetchSubscriptions(business);
  };

  handleAction = (action, objectId) => {
    this.setState({ open: true, subscriptionId: objectId });
  };

  render = () => {
    const { cols, data } = this.props;
    return (
      <Overlay
        content={
          <SubscriptionContainer subscriptionId={this.state.subscriptionId} />
        }
        open={this.state.open}
        handleClose={this.handleClose}
      >
        <Container fluid>
          <Segment basic></Segment>
        </Container>
        <Container fluid>
          <Segment basic>
            <Spreadsheet
              handleCellChange={this.handleAction}
              cols={cols}
              data={data}
            />
          </Segment>
        </Container>
      </Overlay>
    );
  };
}

const mapStateToProps = state => ({
  business: state.business,
  cols: columns,
  data: Selectors.getSubscriptions(state)
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSubscriptions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsContainer);
