import React from 'react';
import { Form, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import startCase from 'lodash/startCase';
import { Field, formValueSelector } from 'redux-form';
import './ProgramRegistrationSettings.css';
import Checkbox from '../../../Checkbox';

import LabelHelper from '../../../LabelHelper';
import ProgramSetupSection from '../../ProgramSetupSection';
import { RegistrationType } from '../../../../parse/models/enums';
import NumberFormatter from '../../../NumberFormatter';
import Dropdown from '../../../Dropdown';

const ProgramRegistrations = props => (
  <ProgramSetupSection header="Registrations">
    <Field
      name="online"
      label={
        <LabelHelper label="Register Online">
          <div>
            <p>Allow parents to register for this program</p>
          </div>
        </LabelHelper>
      }
      component={Checkbox}
    />
    <Field
      name="waitlist"
      label={
        <LabelHelper label="Enable Waitlist">
          <div>
            <p>
              If the program is full, parent registrations are automatically put
              on waitlists.
            </p>
          </div>
        </LabelHelper>
      }
      component={Checkbox}
    />
    <Field
      name="requireApproval"
      label={
        <LabelHelper label="Require Approval">
          <div>
            <p>
              Students must be approved before being admitted into the class.
              The parent will not be charged the full tuition unless they are
              approved.
            </p>
          </div>
        </LabelHelper>
      }
      component={Checkbox}
    />
    <p />
    {props.scheduleType !== 'dates' && (
      <Form.Field>
        <LabelHelper label="Registration Type">
          <div>
            <p>
              <b>Course: </b> Students enroll full-time for every day the
              program meets
            </p>
            <p>
              <b>Daily: </b> Students are able to enroll on specific days of the
              week depending on when the program meets (i.e. Monday, Wednesday,
              Friday)
            </p>
          </div>
        </LabelHelper>
        <Field
          name="registrationType"
          selection
          options={[
            {
              key: RegistrationType.course,
              value: RegistrationType.course,
              text: 'Course',
            },
            {
              key: RegistrationType.daily,
              value: RegistrationType.daily,
              text: 'Daily',
            },
          ]}
          component={Dropdown}
        />
      </Form.Field>
    )}
    {props.registrationType === RegistrationType.course && (
      <Form.Field>
        <LabelHelper label="Capacity">
          <div>
            <p>Set the maximum number of students allowed to enroll</p>
          </div>
        </LabelHelper>
        <Field
          name={`capacity`}
          settings={{
            // style: { maxWidth: '10em' },
            thousandSeparator: true,
            placeholder: '(optional)',
            suffix: ' students',
          }}
          component={NumberFormatter}
        />
      </Form.Field>
    )}
    {props.registrationType === RegistrationType.daily && (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={7}>Day</Table.HeaderCell>
            <Table.HeaderCell width={9}>
              <LabelHelper label="Capacity">
                <div>
                  <p>Set the maximum number of students allowed to enroll</p>
                </div>
              </LabelHelper>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.days &&
            props.days.map(day => (
              <Table.Row key={day}>
                <Table.Cell>
                  <label>{startCase(day)}</label>
                </Table.Cell>
                <Table.Cell>
                  <Form.Field key={day}>
                    <Field
                      name={`dayCapacity[${day}]`}
                      settings={{
                        // style: { maxWidth: '10em' },
                        thousandSeparator: true,
                        placeholder: '(optional)',
                        suffix: ' students',
                      }}
                      component={NumberFormatter}
                    />
                  </Form.Field>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    )}
  </ProgramSetupSection>
);

const mapStateToProps = state => {
  const selector = formValueSelector('programSetup');
  return {
    days: selector(state, 'days'),
    scheduleType: selector(state, 'scheduleType'),
    registrationType: selector(state, 'registrationType'),
  };
};

export default connect(mapStateToProps)(ProgramRegistrations);
