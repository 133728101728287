import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Segment } from 'semantic-ui-react';
import StaffFormContainer from './StaffFormContainer';
import Spreadsheet from '../Spreadsheet';
import columns from './columns';

import { Actions, Selectors } from '../../ducks/staff';
import Models from '../../parse/models';
const { fetchStaff } = Actions.thunks;

class StaffContainer extends React.Component {
  componentDidMount() {
    const business = new Models.Business({ id: this.props.business.objectId });
    this.props.fetchStaff(business);
  }

  render() {
    const { cols, data } = this.props;
    return (
      <Container fluid>
        <Container fluid>
          <Segment basic>
            <StaffFormContainer />
          </Segment>
        </Container>
        <Container fluid>
          <Segment basic>
            <Spreadsheet cols={cols} data={data} />
          </Segment>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  business: state.business,
  cols: columns,
  data: Selectors.getStaff(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchStaff }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StaffContainer);
