import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import BillingPlan from '../../../BillingSetupContainer/BillingPlan';
import DepositField from '../../../BillingSetupContainer/DepositField';
import { PlanInterval } from '../../../../parse/models/enums';
import AmountField from '../../../BillingSetupContainer/AmountField';

import ProgramSetupSection from '../../ProgramSetupSection';
import ChargeActive from '../../../BillingSetupContainer/ChargeActive';

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const daysOfMonth = [
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  '13th',
  '14th',
  '15th',
  '16th',
  '17th',
  '18th',
  '19th',
  '20th',
  '21st',
  '22nd',
  '23rd',
  '24th',
  '25th',
  '26th',
  '27th',
  '28th',
  'Last day of the month',
];

const ProgramBillingContainer = props => {
  const { planInterval, registrationType, days } = props;
  let dues = daysOfWeek;
  if (planInterval === PlanInterval.monthly) {
    dues = daysOfMonth;
  }
  dues = dues.map(period => {
    return {
      key: period,
      value: period,
      text: period,
    };
  });
  return (
    <ProgramSetupSection header="Billing">
      <BillingPlan planInterval={planInterval} dues={dues} />
      <DepositField
        visible={planInterval !== PlanInterval.oneTime}
        registrationType={registrationType}
      />
      <AmountField
        registrationType={registrationType}
        days={days}
        planInterval={planInterval}
      />
      <ChargeActive visible={planInterval === PlanInterval.oneTime} />
      <p />
      <p />
    </ProgramSetupSection>
  );
};

const mapStateToProps = state => {
  const selector = formValueSelector('programSetup');
  return {
    days: selector(state, 'days'),
    planInterval: selector(state, 'planInterval'),
    registrationType: selector(state, 'registrationType'),
  };
};

export default connect(mapStateToProps)(ProgramBillingContainer);
