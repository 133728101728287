import React from "react";
import {
  Container,
  Segment,
  Grid,
  Button,
  Icon,
  Header,
  Card
} from "semantic-ui-react";
import { Link } from "react-router-dom";

// import RegisterPaymentMethod from "./RegisterPaymentMethod";
import RegisterPaymentitems from "./RegisterPaymentitems";
import RegisterPaymentMethodContainer from "./RegisterPaymentMethodContainer";
import RegisterAmountDue from "./RegisterAmountDue";

const RegisterPayment = props => {
  const {
    handleSubmit,
    paymentSummary,
    submitting,
    error,
    succeeded,
    valid
  } = props;
  // console.log('PAYMENT PROPS: ', props);
  return (
    <Container>
      {succeeded && (
        <Container fluid>
          <Segment basic />
          <Segment basic />
          <Segment basic>
            <Grid columns={2} centered>
              <Grid.Column width={10}>
                <Card color="teal" fluid>
                  <Card.Content>
                    <Header as="h4">
                      <Icon name="check" color="teal" />
                      <Header.Content>
                        Thanks for your registration!
                      </Header.Content>
                    </Header>
                  </Card.Content>
                  <Card.Content extra>
                    <Button basic color="teal" as={Link} to="/user/billing">
                      View History
                    </Button>
                  </Card.Content>
                  {/* {paymentSummary.charge.amountDisplay} */}
                </Card>
              </Grid.Column>
            </Grid>
          </Segment>
        </Container>
      )}
      {error && (
        <Container fluid>
          <Segment basic>
            <Grid columns={2} centered>
              <Grid.Column width={10}>
                <Card fluid>
                  <Card.Content>
                    <Header as="h4">
                      <Icon name="check" color="teal" />
                      <Header.Content>
                        Thanks for your registration!
                      </Header.Content>
                    </Header>
                  </Card.Content>
                  <Card.Content extra>
                    <Button basic color="teal" as={Link} to="/user/billing">
                      View History
                    </Button>
                  </Card.Content>
                  {/* {paymentSummary.charge.amountDisplay} */}
                </Card>
              </Grid.Column>
            </Grid>
          </Segment>
        </Container>
      )}
      {!succeeded && !error && (
        <React.Fragment>
          <Segment
            basic
            style={{
              marginBottom: 0,
              marginTop: "1em",
              paddingBottom: 0,
              paddingTop: 0
            }}
          >
            <Segment basic>
              <Link to="/register">
                <Icon name="user" />
                Add Another Student
              </Link>
            </Segment>
          </Segment>
          <Grid columns={2} stackable>
            <Grid.Column computer={10} tablet={16}>
              <RegisterPaymentitems
                items={paymentSummary.items}
                amountDisplay={paymentSummary.amountDisplay}
              />
            </Grid.Column>
            <Grid.Column computer={6} tablet={16}>
              <React.Fragment>
                <RegisterPaymentMethodContainer />
                <Container fluid>
                  <Segment basic>
                    <RegisterAmountDue paymentSummary={paymentSummary} />
                    <Button
                      primary
                      fluid
                      disabled={!valid || submitting}
                      onClick={handleSubmit}
                      loading={submitting}
                    >
                      Submit
                    </Button>
                  </Segment>
                </Container>
              </React.Fragment>
            </Grid.Column>
          </Grid>
        </React.Fragment>
      )}
    </Container>
  );
};

export default RegisterPayment;
