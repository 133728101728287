import getInitials from "./getInitials";

const Public = {
  createStudentValDictionary: fieldValues => {
    let studentFieldValues = {};
    Object.keys(fieldValues).forEach(fieldValueId => {
      const fieldValue = fieldValues[fieldValueId];
      const values = studentFieldValues[fieldValue.student] || {};
      values[fieldValue.field] = fieldValue[fieldValue.type];
      studentFieldValues[fieldValue.student] = values;
    });
    return studentFieldValues;
  },
  rowFromData: ({ objectId, student, user, studentVals }) => {
    return {
      objectId: objectId,
      studentId: student.objectId,
      studentName: `${student.firstName} ${student.lastName}`,
      studentFirstName: student.firstName,
      studentLastName: student.lastName,
      avatar: student.avatar ? student.avatar.url : null,
      accountEmail: user.username,
      accountFirstName: user.firstName,
      accountLastName: user.lastName,
      accountName:
        user && user.firstName ? `${user.firstName} ${user.lastName}` : null,
      // avatar: 'https://react.semantic-ui.com/images/avatar/small/stevie.jpg',
      initials: getInitials(student.firstName, student.lastName),
      ...studentVals
    };
  }
};

export default Public;
