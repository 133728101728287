import Immutable from "seamless-immutable";
import { normalize } from "normalizr";
import { SubmissionError } from "redux-form";
import Parse from "parse";
import { createSelector } from "reselect";
import startCase from "lodash/startCase";
import Models from "../parse/models";
import schema from "../schema";
import StaffType, { StaffStatus } from "../utils/staffType";

const ActionTypes = {
  ADD_STAFF: "ADD_STAFF",
  RECEIVED_STAFF: "RECEIVED_STAFF"
};

const StaffHelper = {
  addOwnerStaff: async ({ user, business }) =>
    Private.addStaff({
      user,
      business,
      access: StaffType.owner,
      status: StaffStatus.active
    })
};

const Private = {
  addStaff: async ({ user, business, name, email, access, status }) => {
    try {
      const staff = new Models.Staff();
      return await staff.save({
        user,
        business,
        name,
        email,
        access,
        status,
        active: true
      });
    } catch (error) {
      throw error;
    }
  },

  getStaff: state => state.staff,
  getUsers: state => state.users,

  createStaffData: (staff, users) => {
    let data = {};
    Object.keys(staff).forEach(objectId => {
      const s = staff[objectId];
      const user = s.user ? users[s.user] : null;
      data[objectId] = {
        objectId,
        name:
          user && user.firstName
            ? user.firstName + " " + user.lastName
            : s.name,
        email: user ? user.email : s.email,
        access: startCase(s.type),
        status: s.status ? s.status : "Active"
      };
    });
    return data;
  }
};

const Selectors = {
  getStaff: createSelector(
    [Private.getStaff, Private.getUsers],
    Private.createStaffData
  )
};

const Actions = {
  creators: {
    addStaff: data => ({
      data,
      type: ActionTypes.ADD_STAFF
    }),
    receivedStaff: data => ({
      data,
      type: ActionTypes.RECEIVED_STAFF
    })
  },
  thunks: {
    addStaff: ({ business, name, email, access }) => async dispatch => {
      try {
        const bus = new Models.Business();
        bus.id = business.id;
        const result = await Private.addStaff({
          business: bus,
          name,
          email,
          access
        });
        const staff = normalize(result.toJSON(), schema.staff);
        return dispatch(Actions.creators.addStaff(staff));
      } catch (error) {
        const err = {
          _error: error.message
        };
        throw new SubmissionError(err);
      }
    },
    fetchStaff: business => async dispatch => {
      try {
        const query = new Parse.Query(Models.Staff);
        query.equalTo("business", business);
        query.include("user");
        const staff = await query.find();
        const result = staff.map(s => s.toJSON());
        dispatch(
          Actions.creators.receivedStaff(normalize(result, schema.staffs))
        );
        return;
      } catch (error) {
        console.error("ERROR ", error);
      }
    }
  }
};

export { Actions, Selectors, StaffHelper };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.staff
  ) {
    return state.merge(action.data.entities.staff);
  }
  return state;
};
