import React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader, Icon, Segment } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { Actions } from '../../ducks/stripe';
const { connectStripe } = Actions.thunks;
class StripeContainer extends React.Component {
  componentDidMount = () => {
    console.log(this.props);
    const { location } = this.props;
    console.log(location.search);
    const params = new URLSearchParams(location.search);
    console.log(params);
    console.log(params.get('code'));
    this.props.connectStripe(params.get('code'), this.props.business.objectId);
  };
  render() {
    const { stripe } = this.props;
    if (stripe) {
      return <Redirect to="/business/profile" />;
    }
    return (
      <Segment basic style={{ height: '80%' }}>
        <Dimmer active inverted>
          <Loader inverted>
            <Icon name="stripe" /> Connecting Stripe
          </Loader>
        </Dimmer>
      </Segment>
    );
  }
}

const mapStateToProps = state => ({
  business: state.business,
  stripe: state.stripe,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      connectStripe,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StripeContainer);
