import React from 'react';
import startCase from 'lodash/startCase';
import { Container, List, Form, Label } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';

// const displayDays = {
//   Mon: 'Monday',
//   Tue: 'Tuesday',
//   Wed: 'Wednesday',
//   Thu: 'Thursday',
//   Fri: 'Friday',
//   Sat: 'Saturday',
//   Sun: 'Sunday',
// };

const FieldLabel = props => {
  const { day, waitlistEnabled, full } = props;
  // console.log(displayDays[day]);
  // return <label>{displayDays[day]}</label>;
  if (full[day] && !waitlistEnabled) {
  }
  return (
    <label>
      {startCase(day)}{' '}
      {full[day] && !waitlistEnabled && (
        <Label size="mini" basic color="red">
          Full
        </Label>
      )}
      {full[day] && waitlistEnabled && (
        <Label size="mini" basic color="yellow">
          Waistlist Only
        </Label>
      )}
    </label>
  );
};

const RegisterProgramItem = props => {
  const {
    program,
    student,
    height,
    toggle,
    toggleSelect,
    iconColor,
    iconName,
    days,
    full,
    waitlistEnabled,
  } = props;

  return (
    <List.Item style={{ padding: 0 }}>
      <List style={{ padding: 0 }}>
        <List.Item onClick={toggle}>
          {/* <Container fluid onClick={this.toggle}> */}
          <List.Icon
            name={iconName}
            color={iconColor}
            size="large"
            verticalAlign="middle"
          />
          <List.Content>
            <List.Header as="h3">
              {program.name}{' '}
              {program.registrationType === 'course' &&
                full &&
                !waitlistEnabled && (
                  <Label size="small" basic color="red">
                    Full
                  </Label>
                )}
              {program.registrationType === 'course' &&
                full &&
                waitlistEnabled && (
                  <Label size="small" basic color="yellow">
                    Waistlist
                  </Label>
                )}
            </List.Header>
            {program.description}
            {program.registrationType === 'daily' && (
              <div>
                Meets {program.days.map(day => startCase(day)).join(', ')}
              </div>
            )}
          </List.Content>
        </List.Item>
      </List>
      {program.registrationType === 'daily' && (
        <Container>
          <AnimateHeight
            duration={500}
            height={height} // see props documentation below
          >
            <Container style={{ padding: '0.5em' }}>
              <p>Select the days you'd like {student.firstName} to attend</p>
              <div className="ui form">
                {program.registrationType === 'daily' &&
                  program.days &&
                  program.days.length > 0 && (
                    <React.Fragment>
                      {program.days.map(day => (
                        <Form.Checkbox
                          key={day}
                          label={
                            <FieldLabel
                              day={day}
                              full={full}
                              waitlistEnabled={waitlistEnabled}
                            />
                          }
                          checked={days[day] ? true : false}
                          onClick={(e, data) =>
                            toggleSelect(
                              student.objectId,
                              program.objectId,
                              data.checked,
                              waitlistEnabled === true && full[day] === true,
                              day,
                            )
                          }
                        />
                      ))}
                    </React.Fragment>
                  )}
              </div>
            </Container>
          </AnimateHeight>
        </Container>
      )}
    </List.Item>
  );
};

export default RegisterProgramItem;
