import React from "react";
import moment from "moment";

const ProgramScheduleCellRenderer = ({ cellData, rowData }) => {
  console.log(cellData);
  const dateRange =
    cellData.startDate && cellData.endDate
      ? `${moment(cellData.startDate).format("MMM D, YYYY")} - ${moment(
          cellData.endDate
        ).format("MMM D, YYYY")}`
      : "";
  return <div>{dateRange}</div>;
};
export default ProgramScheduleCellRenderer;
