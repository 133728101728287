import React from "react";
import { connect } from "react-redux";
import { submit, hasSubmitSucceeded } from "redux-form";
import { bindActionCreators } from "redux";
import Models from "../../../parse/models";
import Profile from "./Profile/Profile";
import { Actions } from "../../../ducks/students";
const { fetchStudent, updateStudent } = Actions.thunks;

class ProfileContainer extends React.Component {
  componentDidMount = () => {
    const { studentId } = this.props;
    const student = Models.Student.createWithoutData(studentId);
    // console.log("STUDENT: ", student.toJSON());
    this.props.fetchStudent(student);
  };
  handleSubmit = values => {
    const { studentId } = this.props;
    const student = Models.Student.createWithoutData(studentId);
    return this.props.updateStudent(student, values);
  };
  render = () => {
    return (
      <Profile
        // key={shortid.generate()}
        {...this.props.student}
        onSubmit={this.handleSubmit}
        // onAvatarChange={() => this.props.submit("avatarForm")}
        submitNameSucceeded={this.props.submitNameSucceeded}
      />
    );
  };
}

ProfileContainer.propTypes = {};

const mapStateToProps = (state, props) => ({
  student: state.students[props.studentId],
  submitNameSucceeded: hasSubmitSucceeded("studentProfileName")(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStudent,
      updateStudent,
      submit
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
