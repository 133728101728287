import React from "react";
import { reduxForm, Field } from "redux-form";
import { Form } from "semantic-ui-react";

const EditName = props => (
  <Form onSubmit={props.handleSubmit}>
    <Form.Group>
      <Field component={Form.Input} name="firstName" />
      <Field component={Form.Input} name="lastName" />
      <Form.Button loading={props.submitting}>Save</Form.Button>
    </Form.Group>
  </Form>
);

EditName.propTypes = {};

export default reduxForm({ form: "studentProfileName" })(EditName);
