import React from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatter = props => (
  <NumberFormat
    {...props.settings}
    value={props.input.value}
    onValueChange={({ floatValue }) => props.input.onChange(floatValue)}
  />
);

export default NumberFormatter;
