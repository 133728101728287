import React from "react";
import { connect } from "react-redux";
import { Label, Icon, Segment } from "semantic-ui-react";
import without from "lodash/without";
import { Selectors } from "../../ducks/filters";
import { Field } from "redux-form";

class TagFields extends React.PureComponent {
  removeTag = id => {
    const value = this.props.input.value || [];
    const val = without(value, id);
    this.props.input.onChange(val);
  };
  render = () => {
    const { values } = this.props;
    if (!values) {
      return <React.Fragment />;
    }
    return (
      <React.Fragment>
        {values.map(value => (
          <Label key={value.id} as="a" onClick={() => this.removeTag(value.id)}>
            {value.name}
            <Icon name="delete" />
          </Label>
        ))}
      </React.Fragment>
    );
  };
}

const FilterTagFormContainer = props => {
  const {
    programs,
    locations,
    categories,
    registrationStatus,
    days
  } = props.tags;
  return (
    <Segment basic style={{ padding: 0 }}>
      <Field name="programs" values={programs} component={TagFields} />
      <Field name="categories" values={categories} component={TagFields} />
      <Field name="locations" values={locations} component={TagFields} />
      <Field
        name="registrationStatus"
        values={registrationStatus}
        component={TagFields}
      />
      <Field name="days" values={days} component={TagFields} />
    </Segment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    tags: Selectors.getTags(state, props)
  };
};

export default connect(mapStateToProps)(FilterTagFormContainer);
