import Immutable from 'seamless-immutable';
import Parse from 'parse';
import findKey from 'lodash/findKey';
import { createSelector } from 'reselect';
import { normalize } from 'normalizr';
import schema from '../schema';

import Models from '../parse/models';

const ActionTypes = {
  RECEIVED_FORM_VALUES: 'RECEIVED_FORM_VALUES',
  SAVED_FORM_VALUES: 'SAVED_FORM_VALUES',
};

const Private = {
  getForm: (state, props) => state.forms[props.objectId],
  getFields: state => state.fields,
  getFieldValues: state => state.fieldValues,
  getFormValues: state => state.formValues,

  getFormFields: (state, props) => props.fields,
  getFormAccount: (state, props) => {
    const { studentId, accountId } = props;
    return { studentId, accountId };
  },
  formFieldValues: (fieldValues, fields, { studentId, accountId }) => {
    let fieldIds = {};
    fields.forEach(field => (fieldIds[field.objectId] = field));
    let values = {};

    Object.keys(fieldValues).forEach(fieldValueId => {
      const fieldValue = fieldValues[fieldValueId];

      const fieldId = fieldValue.field;
      const field = fieldIds[fieldId];

      // Needs to be relevant to student and account, if supplied
      if (studentId) {
        if (fieldValue.student !== studentId) {
          return;
        }
      } else if (accountId) {
        if (fieldValue.account !== accountId) {
          return;
        }
      }
      if (field) {
        values[fieldId] = fieldValue[field.type];
      }
    });
    // console.log(' FINISHED RUNNING WITH VALUES: ', values);
    return values;
  },

  /**
   * Selector for returning formId, formValueId, feildId: formValueId
   */
  originalFormValue: (form, fields, formValues, fieldValues) => {
    const formId = form.objectId;
    let formValueId = findKey(formValues, fv => fv.form === formId);
    let fieldObjects = {};
    form.fields.forEach(fieldId => {
      fieldObjects[fieldId] = findKey(fieldValues, fv => fv.field === fieldId);
    });
    return {
      formId,
      formValueId,
      fieldObjects,
    };
  },

  fetchFormValue: async (formId, studentId, accountId) => {
    const query = new Parse.Query(Models.FormValue);
    if (accountId) {
      // console.log('query account');
      query.equalTo('account', Models.Account.createWithoutData(accountId));
    }
    if (studentId) {
      // console.log('query student');
      query.equalTo('student', Models.Student.createWithoutData(studentId));
    }
    query.equalTo('form', Models.Form.createWithoutData());
    query.include('fieldValues');
    return query.find();
  },
};

const Actions = {
  creators: {
    savedFormValues: data => ({
      data,
      type: ActionTypes.SAVED_FORM_VALUES,
    }),
  },
  thunks: {
    upsertFormValue: (
      data,
      originalFormValue,
      formId,
      studentId,
      accountId,
    ) => async dispatch => {
      const form = Models.Form.createWithoutData(formId);
      const fieldValues = Object.keys(data).map(fieldId => {
        const fieldValue = new Models.FieldValue();
        if (originalFormValue.fieldObjects[fieldId]) {
          console.log('WE HAVE THE ORIGINAL ID');
          fieldValue.id = originalFormValue.fieldObjects[fieldId];
        }
        const field = new Models.Field({ id: fieldId });
        fieldValue.set('field', field);
        fieldValue.set(field.get('type'), data[fieldId]);
        fieldValue.set('type', field.get('type'));
        fieldValue.set('form', form);
        if (field.get('type') !== 'upload') {
          if (field.get('type') === 'date') {
            fieldValue.set('value', data[fieldId].toISOString());
          } else {
            fieldValue.set('value', `${data[fieldId]}`);
          }
        }
        fieldValue.set('label', field.get('label'));
        fieldValue.set('active', true);

        if (studentId) {
          fieldValue.set('student', new Models.Student({ id: studentId }));
        }
        if (accountId) {
          fieldValue.set('account', new Models.Account({ id: accountId }));
        }
        return fieldValue;
      });
      await Parse.Object.saveAll(fieldValues);
      const formValue = new Models.FormValue();
      if (originalFormValue.formValueId) {
        formValue.id = originalFormValue.formValueId;
      }
      if (studentId) {
        formValue.set('student', new Models.Student({ id: studentId }));
      }
      if (accountId) {
        formValue.set('account', new Models.Account({ id: accountId }));
      }
      formValue.set('form', form);
      formValue.set('fieldValues', fieldValues);
      await formValue.save();
      dispatch(
        Actions.creators.savedFormValues(
          normalize(formValue.toJSON(), schema.formValue),
        ),
      );
      return formValue;
    },
  },
};

const Selectors = {
  getOriginalFormValue: () =>
    createSelector(
      [
        Private.getForm,
        Private.getFields,
        Private.getFormValues,
        Private.getFieldValues,
      ],
      Private.originalFormValue,
    ),
  getFormFieldValues: () =>
    createSelector(
      [Private.getFieldValues, Private.getFormFields, Private.getFormAccount],
      Private.formFieldValues,
    ),
};

export { Actions, Selectors };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.formValues
  ) {
    return state.merge(action.data.entities.formValues);
  }
  return state;
};
