import React from 'react';
import ContentEditable from 'react-contenteditable';
// import sanitizeHtml from 'sanitize-html';

import './RichTextBox.css';

class RichTextBox extends React.Component {
  state = { html: this.props.value ? this.props.value.trim() : '' };
  contentEditable = React.createRef();

  handleBlur = () => {
    if (this.state.html !== this.props.value) {
      console.log(this.state.html);
      this.props.onChange(this.state.html);
    }
  };

  sanitizeConf = {
    allowedTags: ['br'],
    allowedAttributes: {},
  };

  handleChange = evt => {
    this.setState({ html: evt.target.value });
  };

  handleKeyDown = e => {
    const { key } = e;

    // return;
    switch (key) {
      case 'Enter':
        if (this.props.textarea) {
          break;
        }
        // console.log(this.contentEditable.current);
        this.contentEditable.current.blur();
        break;
      case 'Escape':
        this.contentEditable.current.blur();
        break;
      default:
        break;
    }
  };

  render = () => {
    const { el } = this.props || 'div';
    return (
      <ContentEditable
        placeholder={this.props.placeholder}
        innerRef={this.contentEditable}
        html={this.state.html} // innerHTML of the editable div
        disabled={false} // use true to disable editing
        onChange={this.handleChange} // handle innerHTML change
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        tagName={el} // Use a custom HTML tag (uses a div by default)
        className="editable-component"
        // onClick={e => e.stopPropagation()}
        ref={domNode => {
          this.domElm = domNode;
        }}
      />
    );
  };
}

export default RichTextBox;
