import Immutable from "seamless-immutable";
import orderBy from "lodash/orderBy";
import { createSelector } from "reselect";
import { normalize } from "normalizr";
import Parse from "parse";
import Models from "../parse/models";
import schema from "../schema";

import dataRow from "../utils/dataRow";

const ActionTypes = {
  RECEIVED_REGISTRATIONS: "RECEIVED_REGISTRATIONS"
};

const Helpers = {
  // getAccounts: state => state.accounts,

  getTransactions: state => state.transactions,
  getTransactionItems: state => state.transactionItems,
  getStudents: state => state.students,
  getPrograms: state => state.programs,
  getLocations: state => state.locations,
  getCategories: state => state.categories,
  getAccounts: state => state.accounts,
  getUsers: state => state.users,
  getFieldValues: state => state.fieldValues,
  getRegistrations: state => state.registrations,
  registrationResults: (
    students,
    accounts,
    users,
    fieldValues,
    registrations
  ) => {
    // console.log(students, registrations);

    // let values = {}
    const studentFieldValues = dataRow.createStudentValDictionary(fieldValues);
    // console.log(studentFieldValues);
    let results = {};
    if (!registrations) {
      return results;
    }

    Object.keys(registrations).forEach(registrationId => {
      const registration = registrations[registrationId];
      const student = students[registration.student];
      const account = accounts[student.account];
      const user = users[account.user];
      const studentVals = studentFieldValues[student.objectId];
      // console.log(studentVals);
      // const program = students[registration.program];

      const { registrationStatus } = registration;

      let schedule = "fullTime";
      if (registration.course) {
        schedule = "fullTime";
      } else if (registration.days) {
        schedule = registration.days;
      } else if (registration.dates) {
        schedule = registration.dates;
      }
      // console.log('COURSE: ', registration.course);
      results[student.objectId] = Object.assign(
        {},
        dataRow.rowFromData({
          objectId: student.objectId,
          registration,
          student,
          user,
          studentVals
        }),
        {
          schedule,
          registrationStatus
        }
      );
    });
    return results;
  },
  studentRegistrationResults: (
    registrations,
    programs,
    locations,
    categories,
    transactions,
    transactionItems
  ) => {
    return orderBy(
      Object.keys(registrations).map(id => {
        const registration = registrations[id];
        const program = programs[registration.program].merge({
          category: categories[programs[registration.program].category],
          location: locations[programs[registration.program].location]
        });

        console.log(transactionItems);
        const transactionItem = transactionItems[
          registration.transactionItem
        ].merge({
          transaction:
            transactions[
              transactionItems[registration.transactionItem].transaction
            ]
        });

        return registration.merge({
          program,
          transactionItem
        });
      }),
      registration => registration.createdAt
    );
  }
};

const Selectors = {
  getStudentRegistrations: createSelector(
    [
      Helpers.getRegistrations,
      Helpers.getPrograms,
      Helpers.getLocations,
      Helpers.getCategories,
      Helpers.getTransactions,
      Helpers.getTransactionItems
    ],
    Helpers.studentRegistrationResults
  ),
  getRegistrations: createSelector(
    [
      Helpers.getStudents,
      Helpers.getAccounts,
      Helpers.getUsers,
      Helpers.getFieldValues,
      Helpers.getRegistrations
    ],
    Helpers.registrationResults
  )
};

const Actions = {
  creators: {
    receivedRegistrations: data => ({
      data,
      type: ActionTypes.RECEIVED_REGISTRATIONS
    })
  },
  thunks: {
    // fetchReportData: (fields, students)
    fetchStudentRegistrations: student => async dispatch => {
      const result = await new Parse.Query(Models.Registration)
        .equalTo("student", student)
        .include("program")
        .include("program.location")
        .include("program.category")
        .include("student")
        .include("student.account")
        .include("student.account.user")
        .include("transactionItem")
        .include("transactionItem.transaction")
        .find();
      const registrations = result.map(r => r.toJSON());
      dispatch(
        Actions.creators.receivedRegistrations(
          normalize(registrations, schema.registrations)
        )
      );
    },
    fetchRegistrations: (
      program,
      search,
      registrationStatus
    ) => async dispatch => {
      const query = new Parse.Query(Models.Registration);
      query.equalTo("program", program);
      if (search) {
        const studentQuery = new Parse.Query(Models.Student);
        studentQuery.contains("nameLower", search);
        query.matchesQuery("student", studentQuery);
      }
      if (registrationStatus && registrationStatus.length > 0) {
        query.containedIn("registrationStatus", registrationStatus);
      }
      query.include("program");
      query.include("program.location");
      query.include("program.category");

      query.include("student");
      query.include("student.account");
      query.include("student.account.user");
      query.include("transactionItem");
      query.limit(1000);
      const results = await query.find();
      const registrations = results.map(r => r.toJSON());
      // console.log(registrations);
      dispatch(
        Actions.creators.receivedRegistrations(
          normalize(registrations, schema.registrations)
        )
      );
    }
  }
};

export { Actions, Selectors };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (action && action.type === ActionTypes.RECEIVED_REGISTRATIONS) {
    if (action.data.entities.registrations) {
      return Immutable(action.data.entities.registrations);
    }
    return defaultState;
  } else if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.registrations
  ) {
    return state.merge(action.data.entities.registrations);
  }
  return state;
};
