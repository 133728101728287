import React from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { bindActionCreators } from "redux";
import utils from "../../utils";
import xor from "lodash/xor";
import { Container, Segment } from "semantic-ui-react";
import { Actions, Selectors } from "../../ducks/students";
import { Actions as FieldValueActions } from "../../ducks/fieldValues";

import { Selectors as VisibleFieldSelectors } from "../../ducks/visibleFields";
import Spreadsheet from "../Spreadsheet";
import Models from "../../parse/models";

import StudentsFilterContainer from "./StudentsFilterContainer";
import VisibleFormFieldsContainer from "../VisibleFormFieldsContainer";

const { fetchStudents } = Actions.thunks;
const { fetchVisibleFieldValues } = FieldValueActions.thunks;

class StudentsContainer extends React.Component {
  state = { isLoading: false };

  componentDidMount = () => {
    const business = new Models.Business();
    business.id = this.props.business.objectId;
    this.setState({ isLoading: true });
    this.props.fetchStudents(business).then(result => {
      this.setState({ isLoading: false });
      return result;
    });
  };

  componentDidUpdate = prevProps => {
    const prev = Object.keys(prevProps.visibleFields.students);
    const next = Object.keys(this.props.visibleFields.students);
    if (next.length > prev.length) {
      // field needs to actually be custom
      const diff = xor(prev, next);
      const { fields } = this.props;
      const [el] = diff;
      if (!fields[el]) {
        return;
      }

      if (diff && diff.length > 0) {
        const studentIds = Object.keys(this.props.data);
        this.props.fetchVisibleFieldValues(studentIds, diff);
      }
    }
  };

  handleFilter = values => {
    const business = new Models.Business();
    business.id = this.props.business.objectId;
    const programs =
      values.programs &&
      values.programs.map(programId =>
        Models.Program.createWithoutData(programId)
      );
    const categories =
      values.categories &&
      values.categories.map(categoryId =>
        Models.Category.createWithoutData(categoryId)
      );
    const locations =
      values.locations &&
      values.locations.map(locationId =>
        Models.Location.createWithoutData(locationId)
      );
    this.setState({ isLoading: true });
    const search = values.search ? values.search.toLowerCase() : null;
    return this.props
      .fetchStudents(business, programs, categories, locations, search)
      .then(result => {
        this.setState({ isLoading: false });
        return result;
      });
  };

  render = () => {
    const { cols, data } = this.props;
    return (
      <Container fluid>
        <Container fluid>
          <Segment basic>
            <VisibleFormFieldsContainer report="students" />
            <p />
            <StudentsFilterContainer
              isLoading={this.state.isLoading}
              onSubmit={this.handleFilter}
              onChange={value => {
                if (value.search) {
                  utils.delay(
                    () => this.props.submit("studentFiltersForm"),
                    500
                  )();
                } else {
                  this.props.submit("studentFiltersForm");
                }
              }}
            />
          </Segment>
        </Container>
        <Container fluid>
          <Segment basic>
            <Spreadsheet
              cols={cols}
              data={data}
              loading={this.state.isLoading}
            />
          </Segment>
        </Container>
      </Container>
    );
  };
}

const mapStateToProps = (state, props) => {
  return {
    business: state.business,
    cols: VisibleFieldSelectors.getColumns(
      state,
      Object.assign({ report: "students" }, props)
    ),
    data: Selectors.getStudentResults(state, props),
    visibleFields: state.visibleFields,
    fields: state.fields,
    filters: state.filters
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { fetchStudents, fetchVisibleFieldValues, submit },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(StudentsContainer);
