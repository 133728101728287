import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from '../../ducks/transactions';
import { Container, Header, Segment, Dimmer, Loader } from 'semantic-ui-react';
import TransactionForm from './TransactionForm';
import formatCurrency from '../../utils/formatCurrrency';
import Models from '../../parse/models';
const { updateTransaction } = Actions.thunks;

class TransactionContainer extends React.Component {
  handleSubmit = data => {
    const { transactionItemAmount } = data;
    // console.log(data);
    // let planEndDate;
    // if (data.planEndDate.iso) {
    //   planEndDate = new Date(data.planEndDate.iso);
    // } else if (data.planEndDate) {
    //   planEndDate = new Date(data.planEndDate);
    // }

    // const amount = formatCurrency(data.amount, false, 'usd');
    let amount = 0;
    const transactionItems = Object.keys(transactionItemAmount).map(
      transactionItemId => {
        const itemAmount = formatCurrency(
          transactionItemAmount[transactionItemId],
          false,
          'usd',
        );
        amount += itemAmount;
        return new Models.TransactionItem({
          id: transactionItemId,
          amount: itemAmount,
        });
      },
    );
    const transaction = new Models.Transaction({
      id: this.props.transactionId,
      amount,
    });
    return this.props.updateTransaction(transaction, transactionItems);
  };

  render = () => {
    const transaction = this.props.transactions[this.props.transactionId];
    if (!transaction) {
      return (
        <Container fluid style={{ height: '100%' }}>
          <Dimmer inverted active>
            <Loader inverted />
          </Dimmer>
        </Container>
      );
    }
    const transactionItems = transaction.transactionItems.map(
      transactionItemId => ({
        ...this.props.transactionItems[transactionItemId],
        program: this.props.programs[
          this.props.transactionItems[transactionItemId].program
        ],
        student: this.props.students[
          this.props.transactionItems[transactionItemId].student
        ],
      }),
    );

    const transactionItemAmount = {};
    transactionItems.forEach(transactionItem => {
      transactionItemAmount[transactionItem.objectId] = formatCurrency(
        transactionItem.amount,
        true,
        'usd',
      );
    });
    return (
      <Container style={{ height: '100%' }}>
        <Segment basic>
          <Segment basic>
            <Segment>
              <Header>Invoice</Header>
              <TransactionForm
                onSubmit={this.handleSubmit}
                initialValues={{
                  // ...transaction,
                  transactionItemAmount,
                  dueDate: transaction.dueDate || transaction.createdAt,
                }}
                transactionItems={transactionItems}
              />
            </Segment>
          </Segment>
        </Segment>
      </Container>
    );
  };
}

TransactionContainer.propTypes = {};

const mapStateToProps = state => ({
  students: state.students,
  programs: state.programs,
  users: state.users,
  accounts: state.accounts,
  // registrations: state.registrations,
  subscriptions: state.subscriptions,
  transactions: state.transactions,
  transactionItems: state.transactionItems,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTransaction,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionContainer);
