import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Form, Header, Table, Grid } from 'semantic-ui-react';
import DatePicker from '../../DatePicker';
import NumberFormatter from '../../NumberFormatter';
import { TransactionItemType } from '../../../parse/models/enums';
import TransactionTotalContainer from './TransactionTotalContainer';

class TransactionForm extends React.Component {
  state = {
    transactionItems: this.props.transactionItems || [],
  };

  render = () => {
    const { submitting, handleSubmit } = this.props;
    const { transactionItems } = this.state;

    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Due Date</label>
            <Field
              settings={{ disabled: true }}
              name="dueDate"
              component={DatePicker}
              fluid
            />
          </Form.Field>
        </Form.Group>
        <Table>
          <Table.Body>
            {transactionItems.map(transactionItem => (
              <Table.Row key={transactionItem.objectId}>
                <Table.Cell width="10">
                  <Header>
                    {transactionItem.program && (
                      <Header.Content>
                        {transactionItem.student.firstName}{' '}
                        {transactionItem.student.lastName} -{' '}
                        {transactionItem.program.name}
                      </Header.Content>
                    )}
                    {transactionItem.type === TransactionItemType.deposit && (
                      <Header.Subheader>Deposit</Header.Subheader>
                    )}
                    {TransactionItemType.course && (
                      <Header.Subheader>Enrollment</Header.Subheader>
                    )}
                  </Header>
                </Table.Cell>
                <Table.Cell width="6">
                  <Form.Field>
                    <label>Invoice Amount</label>
                    <Field
                      name={`transactionItemAmount[${transactionItem.objectId}]`}
                      // fluid
                      settings={{
                        // style: { maxWidth: '10em' },
                        prefix: '$',
                        thousandSeparator: true,
                        placeholder: '0',
                        style: { textAlign: 'right' },
                      }}
                      component={NumberFormatter}
                    />
                  </Form.Field>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {}
                Total: <TransactionTotalContainer />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Grid textAlign="right">
          <Grid.Row>
            <Grid.Column>
              <Form.Button loading={submitting} primary>
                Submit & Send
              </Form.Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  };
}

export default reduxForm({ form: 'transactionForm' })(TransactionForm);
