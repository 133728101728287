import React from "react";
import { Table, Segment } from "semantic-ui-react";

const RegisterAmountDue = ({ paymentSummary }) => {
  console.log("PAYMENt SUMMARY: ");
  console.log(paymentSummary);
  const { charge } = paymentSummary;
  return (
    <Segment>
      {charge && (
        <Table style={{ borderWidth: 0 }}>
          <Table.Body>
            {charge.feeAmount > 0 && (
              <React.Fragment>
                <Table.Row>
                  <Table.Cell>Sub Total</Table.Cell>
                  <Table.Cell textAlign="right">
                    {paymentSummary.amountDisplay}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{charge.feeName}</Table.Cell>
                  <Table.Cell textAlign="right">
                    {charge.feeAmountDisplay}
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>Amount Due </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {charge.amountDisplay}{" "}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
    </Segment>
  );
};

RegisterAmountDue.propTypes = {};

export default RegisterAmountDue;
