import React from "react";
import shortid from "shortid";
import {
  Container,
  Modal,
  Form,
  Image,
  List,
  Message,
  Segment
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import StudentFormContainer from "../../../../StudentsContainer/StudentFormContainer";

class RegisterStudents extends React.Component {
  render() {
    const { currentAccount, students, handleSelect, disabled } = this.props;
    return (
      <Container>
        <Segment basic>
          <Message>
            <Message.Header>Welcome to CommunityRoot!</Message.Header>
            {students && students.length > 0 && (
              <p>Start registration by selecting a student below</p>
            )}
            {(!students || students.length === 0) && (
              <p>Add a student by selecting the Add New Student button below</p>
            )}
          </Message>
          <List selection verticalAlign="middle">
            {students.map(student => (
              <List.Item
                key={student.objectId}
                onClick={() => handleSelect(student.objectId)}
                as={Link}
                to={`/register/programs`}
              >
                <Image
                  src="https://react.semantic-ui.com/images/avatar/small/lena.png"
                  avatar
                  size="mini"
                />
                <List.Content>
                  <List.Header>{student.name}</List.Header>
                </List.Content>
              </List.Item>
            ))}
          </List>
          <Form>
            <Form.Group>
              <Modal
                key={shortid.generate()}
                defaultOpen={false}
                trigger={
                  <Form.Button disabled={disabled}>Add New Student</Form.Button>
                }
              >
                <StudentFormContainer
                  account={currentAccount}
                  defaultOpen={false}
                />
              </Modal>
            </Form.Group>
          </Form>
        </Segment>
      </Container>
    );
  }
}

export default RegisterStudents;
