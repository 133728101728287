import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LocationForm from "./LocationForm";
import { Actions } from "../../ducks/locations";
import Models from "../../parse/models";
const { addLocation } = Actions.thunks;

class LocationFormContainer extends React.Component {
  handleSubmit = async values => {
    const business = new Models.Business({ id: this.props.business.objectId });
    const location = await this.props.addLocation(
      Object.assign({ business }, values)
    );
    if (this.props.handleComplete) {
      this.props.handleComplete(location);
    }
    return location;
  };
  render() {
    return (
      <LocationForm
        initialValues={{ name: this.props.locationName }}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addLocation
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationFormContainer);
