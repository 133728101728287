import React from "react";
import { Link } from "react-router-dom";
import { Header, Menu, Image } from "semantic-ui-react";
const TitleMenuItem = () => (
  <Menu.Item as={Link} to="/" className="desktop-menu-item">
    <Header inverted>
      {/* <Icon color="teal" name="react" /> */}
      <Image
        size="small"
        src="https://storage.googleapis.com/communityroot/4e035709897e7a72e1b99eda42be6636_white-logo.png"
        className="icon-image"
      />
      <Header.Content
        style={{ fontSize: "0.85em", paddingLeft: ".35rem" }}
        color="teal"
      >
        CommunityRoot
      </Header.Content>
    </Header>
  </Menu.Item>
);

export default TitleMenuItem;
