import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RegisterProgramItem from "./RegisterProgramItem";
import { Actions, Helpers } from "../../../../../../ducks/shoppingCart";

const { updateProgramShoppingCart } = Actions.creators;

const defaultHeight = props => {
  // const selected = props.shoppingCart[props.student.objectId] || {};
  // const programSelection = selected[props.program.objectId] || {};
  const { program, shoppingCart } = props;
  let height = 0;
  if (Helpers.programSelectedForStudent(program.objectId, shoppingCart)) {
    height = "auto";
  }

  return height;
};

class RegisterProgramItemContainer extends Component {
  state = {
    height: defaultHeight(this.props)
  };

  isProgramFull = program => {
    if (program.registrationType === "course") {
      console.log(program.capacity, program.totalEnrolled);
      let full = false;
      if (program.capacity && program.capacity <= program.totalEnrolled) {
        console.log(program.capacity >= program.totalEnrolled);
        full = true;
      }
      return full;
    } else if (program.registrationType === "daily") {
      const { dayCapacity, dailyTotalEnrolled } = program;
      let dayStatus = {};
      Object.keys(dayCapacity).forEach(day => {
        const capacity = dayCapacity[day];
        if (capacity && capacity > 0 && dailyTotalEnrolled) {
          const totalEnrolled = dailyTotalEnrolled[day];
          dayStatus[day] = totalEnrolled >= capacity; // true if full
        }
      });
      return dayStatus;
    }
  };

  toggle = () => {
    // console.log('Type; ', this.props.program.registrationType);

    if (this.props.program.registrationType === "daily") {
      const { height } = this.state;
      this.setState({
        height: height === 0 ? "auto" : 0
      });
    } else {
      const full = this.isProgramFull(this.props.program);
      let waitlist = false;
      if (full && this.props.program.waitlistEnabled) {
        waitlist = true;
      }
      console.log("FULL: ", full, " AND WAITLIST: ", waitlist);
      const { program, student, shoppingCart } = this.props;
      const selected = Helpers.programSelectedForStudent(
        program.objectId,
        shoppingCart
      );
      this.toggleSelect(
        student.objectId,
        program.objectId,
        !selected,
        waitlist
      );
    }
  };

  toggleSelect = (studentId, programId, selected, waitlist, day) => {
    const { shoppingCart, program } = this.props;
    // console.log("FULL: ", this.isProgramFull(program));
    if (
      this.isProgramFull(program) === true &&
      program.waitlistEnabled !== true
    ) {
      return;
    }
    console.log("SELECTED");

    const newCart = Helpers.toggleSelection(
      shoppingCart,
      studentId,
      programId,
      selected,
      waitlist,
      day
    );
    this.props.updateProgramShoppingCart(newCart);
  };
  render() {
    const { height } = this.state;
    const { program, student, shoppingCart } = this.props;
    const selected = shoppingCart.registrations[student.objectId] || {};
    const programSelection = selected[program.objectId] || {};

    const iconName = Helpers.programSelectedForStudent(
      program.objectId,
      shoppingCart
    )
      ? "check circle"
      : "circle outline";
    const iconColor = Helpers.programSelectedForStudent(
      program.objectId,
      shoppingCart
    )
      ? "teal"
      : "grey";
    const days = programSelection.days || {};

    const full = this.isProgramFull(program);
    // console.log("FULL: ", full);
    return (
      <RegisterProgramItem
        program={program}
        student={student}
        shoppingCart={shoppingCart}
        toggle={this.toggle}
        toggleSelect={this.toggleSelect}
        iconName={iconName}
        iconColor={iconColor}
        days={days}
        height={height}
        full={full}
        waitlistEnabled={program.waitlistEnabled === true}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    shoppingCart: state.shoppingCart
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateProgramShoppingCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterProgramItemContainer);
