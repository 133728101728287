import React from 'react';
import startCase from 'lodash/startCase';
import { Container, Table } from 'semantic-ui-react';
import ChoicesField from '../ChoicesField';

const DailyChoices = props => {
  const { days, visible } = props;
  if (!visible || !days) {
    return <React.Fragment />;
  }
  console.log(props);
  return (
    <Container fluid>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={7}>Day</Table.HeaderCell>
            <Table.HeaderCell width={9}>Choices</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {days.map(day => (
            <Table.Row key={`choice-${day}`}>
              <Table.Cell>{startCase(day)}</Table.Cell>
              <Table.Cell>
                <ChoicesField
                  onAddItem={props.onAddItem}
                  options={props.options}
                  editLabel={props.editLabel}
                  name={`dailyChoices[${day}]`}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Container>
  );
};

export default DailyChoices;
