import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import omit from "lodash/omit";
import { Actions, Helpers } from "../../ducks/programs";
import ProgramSetup from "./ProgramSetup";
import Models from "../../parse/models";
const { fetchProgram, saveProgram } = Actions.thunks;

class ProgramSetupContainer extends React.Component {
  programId() {
    const {
      match: {
        params: { programId }
      }
    } = this.props;

    return programId;
  }
  componentDidMount() {
    // This is a route
    const programId = this.programId();
    const program = this.props.programs[programId];
    if (!program) {
      this.props.fetchProgram(programId);
    }
  }
  handleSubmt = form => {
    // return;
    // Prevents submission when another form is modal present
    if (Object.keys(this.props.form).length > 1) {
      return;
    }
    console.log(this.props.form);
    // console.log(Object.keys(this.props.form).length);
    const { business } = this.props;
    const program = new Models.Program();
    const isCopying = this.props.match.url.indexOf("copy") >= 0;
    const omitKeys = isCopying
      ? ["objectId", "createdAt", "updatedAt"]
      : ["createdAt", "updatedAt"];
    const data = omit(Helpers.formToProgram(form, business.objectId), omitKeys);
    return this.props.saveProgram(program, data);
  };

  render() {
    const {
      match: {
        params: { programId }
      }
    } = this.props;
    const program = this.props.programs[programId];
    // console.log(program);
    const data = Helpers.programFormat(program);

    return (
      <ProgramSetup
        onSubmit={this.handleSubmt}
        initialValues={data}
        validate={Helpers.validateProgramForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  business: state.business,
  programs: state.programs,
  form: state.form
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProgram,
      saveProgram
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramSetupContainer);
