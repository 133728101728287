import React from 'react';
import { Route } from 'react-router-dom';
import DashboardSidebar from './DashboardSidebar';
import { Container, Responsive } from 'semantic-ui-react';

const Dashboard = ({ children }) => (
  <div style={{ height: '100%', width: '100%' }}>
    <Responsive
      as={Container}
      fluid
      id="main-container"
      minWidth={Responsive.onlyTablet.minWidth}
    >
      <Route
        render={props => (
          <DashboardSidebar {...props} mobile={false} children={children} />
        )}
      />
    </Responsive>
    <Responsive
      as={Container}
      fluid
      id="main-container"
      maxWidth={Responsive.onlyMobile.maxWidth}
    >
      <Route
        render={props => (
          <DashboardSidebar {...props} mobile={true} children={children} />
        )}
      />
    </Responsive>
  </div>
);

export default Dashboard;
