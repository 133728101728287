import React from 'react';
import { connect } from 'react-redux';
import RegisterProgressBar from './RegisterProgressBar';
import { Selectors } from '../../../../ducks/shoppingCart';

const RegisterProgressBarContainer = props => (
  <RegisterProgressBar {...props} />
);
const mapStateToProps = state => ({
  // shoppingCart: state.shoppingCart,
  programStatus: Selectors.getMinProgramsSelectedForStudent(state),
  requiredFormStatus: Selectors.getRequiredFormStatus(state),
});

export default connect(mapStateToProps)(RegisterProgressBarContainer);
