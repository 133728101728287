import React from 'react';
import { Button, Form, Table } from 'semantic-ui-react';

class FieldOption extends React.Component {
  state = { editing: true, value: this.props.option.value };

  handleChange = (e, data) => {
    // console.log('changed ');
    this.setState({
      value: data.value,
    });
  };

  handleKeyUp = e => {
    // console.log('keyup ');

    if (e.key === 'Enter') {
      this.save();
    }
  };

  handleRemove = () => {
    // console.log('remove ');
    const { index } = this.props.index;
    this.props.removeOption(index);
  };

  save = () => {
    // console.log('save ');

    this.props.handleChange(this.props.index, this.state.value);
  };

  render() {
    const { value } = this.state;
    const {
      index,
      // option
    } = this.props;
    // console.log(this.props, this.state);
    return (
      <Table basic="very" unstackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Form.Input
                placeholder={`Option ${index + 1}`}
                value={value || ''}
                onKeyUp={this.handleKeyUp}
                onChange={this.handleChange}
                onBlur={this.save}
              />
            </Table.Cell>
            <Table.Cell collapsing>
              <Button
                onClick={() => this.handleRemove}
                size="tiny"
                circular
                icon="times"
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

export default FieldOption;
