import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';

const ListItemLink = ({ to, label, icon }) => (
  <Route
    path={to}
    children={({ match }) => (
      <Menu.Item label={label} active={match ? true : false} as={Link} to={to}>
        <Icon
          {...icon}
          // color={done.indexOf('students') >= 0 ? 'green' : 'grey'}
          size="small"
        />
        {label}
      </Menu.Item>
    )}
  />
);

const RegisterProgressBar = props => (
  <Menu icon="labeled" pointing secondary className="stepper-menu">
    <ListItemLink
      to="/register/programs"
      label="Programs"
      icon={{
        name: 'check',
        color: props.programStatus ? 'teal' : 'grey',
      }}
    />
    <ListItemLink
      to="/register/forms"
      label="Forms"
      icon={{
        test: console.log(props.requiredFormStatus.valid),
        name: 'check',
        color: props.requiredFormStatus.valid ? 'teal' : 'grey',
      }}
    />
    <ListItemLink
      to="/register/payment"
      label="Payment"
      icon={{ name: 'check', color: 'grey' }}
    />
  </Menu>
);

export default RegisterProgressBar;
