import Immutable from "seamless-immutable";
import union from "lodash/union";
import filter from "lodash/filter";
import flatten from "lodash/flatten";
import reduce from "lodash/reduce";
import { formatCurrencyDisplay } from "../formatCurrrency";
import {
  ActiveStatus,
  PlanInterval,
  RegistrationStatus
} from "../../parse/models/enums";

const Private = {
  itemForProgram: ({
    planInterval,
    chargeActive,
    registrationStatus,
    cost,
    planDueDate,
    planStartDate,
    planEndDate
  }) => {
    let amount = cost;
    let amountPending = 0;
    // let description = 'One Time payment';
    let plan = "charge";
    let planAmount;
    if (planInterval !== PlanInterval.oneTime) {
      plan = "subscription";
      amount = 0;
      planAmount = cost;
    }
    // debugger;
    // Charge status
    let chargeStatus = ActiveStatus.active;
    if (!chargeActive) {
      chargeStatus = ActiveStatus.inActive;
      amount = 0;
      amountPending = cost;
    }

    // approval status
    if (registrationStatus !== RegistrationStatus.enrolled) {
      amount = 0;
      amountPending = cost;
    }

    const amountDisplay = formatCurrencyDisplay(amount, true, "usd");
    const amountPendingDisplay = formatCurrencyDisplay(
      amountPending,
      true,
      "usd"
    );

    let description = "One Time payment";
    if (planInterval !== PlanInterval.oneTime) {
      description = `$${formatCurrencyDisplay(cost, true, "usd")} per ${
        planInterval === "monthly" ? "month" : "week"
      }`;
    }

    return {
      active: true,
      amount,
      amountDisplay,
      amountPending,
      amountPendingDisplay,
      description,
      chargeStatus,
      registrationStatus,
      type: "program",
      plan,
      planAmount,
      planInterval,
      planDueDate,
      planStartDate,
      planEndDate
    };
  }
};

const Public = {
  courseSubTotalForSelection: (program, status) => {
    // Need to accoint for:
    /**
     * 1. Waitlist
     * 2. Billing Inactive
     * 3. Needs Approval before being enrolled in the program
     * 4. When any of these get iniatiated - should go in activity table
     */
    const {
      chargeActive,
      requireApproval,
      planInterval,
      planDueDate,
      planStartDate,
      planEndDate
    } = program;

    let registrationStatus = RegistrationStatus.enrolled;
    if (status === "waitlist") {
      registrationStatus = RegistrationStatus.waitlisted;
    } else if (requireApproval) {
      registrationStatus = RegistrationStatus.pendingApproval;
    }

    const details = Private.itemForProgram({
      chargeActive,
      registrationStatus,
      planInterval,
      planDueDate,
      planStartDate,
      planEndDate,
      cost: program.amount
    });
    return {
      course: true,
      ...details
    };
  },
  dailySubTotalForSelection: (program, selection) => {
    // day selection
    // program.dayAmount:
    // {
    //   "Mon": 9109,
    //   "Tue": 90129021,
    //   "Wed": 99012,
    //   "Thu": 909
    // }
    // console.log(selection);
    const {
      chargeActive,
      requireApproval,
      planInterval,
      planDueDate,
      planStartDate,
      planEndDate
    } = program;

    const activeDays = filter(
      Object.keys(selection.days),
      day => selection.days[day] === true
    );

    const waitlistDays = filter(
      Object.keys(selection.days),
      day => selection.days[day] === "waitlist"
    );

    let daySelectedAdditionalFees = {};
    activeDays.forEach(
      day => (daySelectedAdditionalFees[day] = program.dayAmount[day])
    );

    const amount = program.daysSelectedAmount[activeDays.length] || 0;
    const amountPerActiveDay = {};
    const activeDayAmount = amount / activeDays.length;

    activeDays.forEach(day => {
      const additionalAmount = program.dayAmount[day] || 0;
      amountPerActiveDay[day] = activeDayAmount + additionalAmount;
    });

    const additionalAmount = reduce(
      activeDays,
      (sum, day) => {
        return sum + program.dayAmount[day] || 0;
      },
      0
    );
    const cost = amount + additionalAmount;

    let registrationStatus = RegistrationStatus.enrolled;
    if (waitlistDays.length > 0 && activeDays.length === 0) {
      registrationStatus = RegistrationStatus.waitlisted;
    } else if (requireApproval) {
      registrationStatus = RegistrationStatus.pendingApproval;
    }

    const details = Private.itemForProgram({
      chargeActive,
      planInterval,
      planDueDate,
      planStartDate,
      planEndDate,
      registrationStatus,
      cost
    });
    // console.log("DETAILS: ", details);

    return {
      days: activeDays,
      waitlistDays,
      dayAmount: program.dayAmount,
      daysSelectedAmount: program.daysSelectedAmount,
      ...details
    };
  },

  getItems: (registrations, students, programs, discounts, fees) => {
    return Object.keys(registrations).map(studentId => {
      const name = `${students[studentId].firstName} ${students[studentId].lastName}`;
      const id = studentId;
      const selections = registrations[studentId];

      // ========= Get individual items
      const items = flatten(
        Object.keys(selections).map(programId => {
          const selection = selections[programId];
          const program = programs[programId];

          let programItems = [];
          let depositItems = [];
          let feeItems = [];
          let discountItems = [];
          const programFees = program.fees || [];

          let programItem = {};

          if (program.registrationType === "course") {
            programItem = Public.courseSubTotalForSelection(
              program,
              selection.course
            );
          } else if (program.registrationType === "daily") {
            programItem = Public.dailySubTotalForSelection(
              program,
              registrations[studentId][programId]
            );
          }

          // console.log(' PROGRAM ITEM: ', programItem);
          programItems.push({
            id: programId,
            type: "program",
            name: program.name,
            ...programItem
          });
          const status =
            programItem.registrationStatus === RegistrationStatus.enrolled;
          console.log("STATUS: ", status);
          console.log(program.chargeActive);
          if (program.deposit > 0) {
            const depositAmount =
              status && program.chargeActive ? program.deposit : 0;
            const pendingDepositAmount =
              status && program.chargeActive ? 0 : program.deposit;
            depositItems.push({
              id: programId,
              type: "deposit",
              name: `Deposit (${program.name})`,
              description: "Deposit",
              amount: depositAmount,
              amountDisplay: formatCurrencyDisplay(depositAmount, true, "usd"),
              registrationStatus: programItem.registrationStatus,
              amountPending: pendingDepositAmount,
              amountPendingDisplay: formatCurrencyDisplay(pendingDepositAmount)
            });
          }
          programFees.forEach(feeId => {
            feeItems.push({
              id: feeId,
              type: "fee",
              name: fees[feeId].name,
              description: "Fee",
              amountDisplay: formatCurrencyDisplay(
                fees[feeId].amount,
                true,
                "usd"
              ),
              amount: fees[feeId].amount,
              registrationStatus: programItem.registrationStatus,
              activeDue: status && program.chargeActive
            });
          });

          if (program.discounts && program.discounts.length > 0) {
            console.log("DISCOUNTS: ", discounts);
            console.log("PROGRAM DISCOUNTS: ", program.discounts);
            discountItems = program.discounts.map(discountId => {
              const discount = discounts[discountId] || {};
              const amount =
                discount && discount.unit === "percent"
                  ? (programItem.amount * discount.value) / 100
                  : discount.value;
              const amountDisplay = formatCurrencyDisplay(
                amount * -1,
                true,
                "usd"
              );
              return {
                id: discountId,
                type: "discount",
                name: discount.name,
                amount,
                amountDisplay,
                registrationStatus: programItem.registrationStatus,
                activeDue: status && program.chargeActive
              };
            });
            console.log(" DISCOUNT ITEMS: ", discountItems);
          }
          return union(programItems, depositItems, feeItems, discountItems);
        })
      );
      // Not sure if we need student fees
      const amount = reduce(
        items,
        (sum, item) => {
          if (item.type === "discount") {
            return sum - item.amount;
          }
          return sum + item.amount;
        },
        0
      );
      return Immutable({
        id,
        name,
        items,
        amountDisplay: formatCurrencyDisplay(amount, true, "usd"),
        amount
      });
    });
  }
};

export default Public;
