import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions, Selectors } from '../../ducks/categories';
import Models from '../../parse/models';
import { Form } from 'semantic-ui-react';

const { fetchCategories } = Actions.thunks;

class CategoriesFilterContainer extends React.Component {
  componentDidMount = () => {
    const business = Models.Business.createWithoutData(
      this.props.business.objectId,
    );
    this.props.fetchCategories(business);
  };
  render = () => {
    const { business, input, fetchCategories, ...props } = this.props;
    const value = input.value.length > 0 ? input.value : [];

    return (
      <Form.Dropdown
        onChange={(e, d) => input.onChange(d.value)}
        selection
        {...props}
        value={value}
      />
    );
  };
}

const mapStateToProps = state => ({
  business: state.business,
  options: Selectors.getCategoryOptions(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchCategories }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoriesFilterContainer);
