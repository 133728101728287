import Immutable from "seamless-immutable";
import head from "lodash/head";
import last from "lodash/last";
import union from "lodash/union";
import { normalize } from "normalizr";
import { createSelector } from "reselect";
import { SubmissionError } from "redux-form";
import orderBy from "lodash/orderBy";
import Parse from "parse";
import schema from "../schema";
import Models from "../parse/models";
import utils from "../utils";
import formatCurrency from "../utils/formatCurrrency";
import { RegistrationType } from "../parse/models/enums";

const ActionTypes = {
  ADD_PROGRAM: "ADD_PROGRAM",
  RECEIVED_PROGRAMS: "RECEIVED_PROGRAMS",
  RECEIVED_PROGRAM_TOTALS: "RECEIVED_PROGRAM_TOTALS",
  RECEIVED_REGISTRATION_PROGRAMS: "RECEIVED_REGISTRATION_PROGRAMS"
};

const Helpers = {
  validateProgramForm: program => {
    let errors = {};
    // console.log(program);
    return errors;
  },
  valueFromDate: date => {
    if (!date) {
      return null;
    }
    if (date.iso) {
      return new Date(date.iso);
    }
    return new Date(date);
  },
  valueFromDates: (scheduleType, dates) => {
    if (scheduleType === "daysOfWeek") {
      return null;
    }
    return orderBy(
      dates.map(date => {
        if (date.iso) {
          return new Date(date.iso);
        }
        return new Date(date);
      })
    );
  },

  // For display
  programFormat: program => {
    if (!program) {
      return Immutable({ daysSelectedAmount: {} });
    }
    let dayAmount = {};
    if (program.dayAmount) {
      Object.keys(program.dayAmount).forEach(day => {
        dayAmount[day] = formatCurrency(program.dayAmount[day], true, "usd");
      });
    }
    let daysSelectedAmount = {};
    if (program.daysSelectedAmount) {
      const days = [1, 2, 3, 4, 5, 6, 7];
      days.forEach(day => {
        if (program.daysSelectedAmount[day]) {
          daysSelectedAmount[day] = formatCurrency(
            program.daysSelectedAmount[day],
            true,
            "usd"
          );
        }
      });
      // console.log(program.daysSelectedAmount);
    }
    return program.merge({
      dayAmount,
      daysSelectedAmount,
      amount: formatCurrency(program.amount, true, "usd"),
      deposit: formatCurrency(program.deposit, true, "usd"),
      dropinAmount: formatCurrency(program.dropinAmount, true, "usd"),
      dropinLateRegistrationAmount: formatCurrency(
        program.dropinLateRegistrationAmount,
        true,
        "usd"
      )
    });
  },
  // For saving
  formToProgram: (form, businessId) => {
    // console.log("SUBMITTING FORM!!!! ", form);
    // debugger;
    const discounts = form.discounts
      ? Immutable.asMutable(form.discounts).map(
          discountId => new Models.Discount({ id: discountId })
        )
      : [];
    const fees = form.fees
      ? Immutable.asMutable(form.fees).map(
          feeId => new Models.Fee({ id: feeId })
        )
      : [];

    const studentForms = form.studentForms
      ? Immutable.asMutable(form.studentForms).map(formId =>
          Models.Form.createWithoutData(formId)
        )
      : [];
    const accountForms = form.accountForms
      ? Immutable.asMutable(form.accountForms).map(formId =>
          Models.Form.createWithoutData(formId)
        )
      : [];
    const scheduleType = form.scheduleType || "daysOfWeek";

    const dates = Helpers.valueFromDates(scheduleType, form.dates);
    const sd =
      scheduleType === "daysOfWeek" ? form.startDate : head(form.dates);
    const ed = scheduleType === "daysOfWeek" ? form.endDate : last(form.dates);
    const startTime =
      scheduleType === "daysOfWeek"
        ? Helpers.valueFromDate(form.startTime)
        : null;
    const endTime =
      scheduleType === "daysOfWeek"
        ? Helpers.valueFromDate(form.endTime)
        : null;

    const currency = "usd";

    const amount = formatCurrency(form.amount, false, currency);
    const deposit = formatCurrency(form.deposit, false, currency);
    const dropinAmount = formatCurrency(form.dropinAmount, false, currency);
    const dropinLateRegistrationAmount = formatCurrency(
      form.dropinLateRegistrationAmount,
      false,
      currency
    );

    let dayAmount = {};
    if (form.dayAmount) {
      Object.keys(form.dayAmount).forEach(day => {
        dayAmount[day] = formatCurrency(form.dayAmount[day], false, "usd");
      });
    }

    let daysSelectedAmount = {};
    if (form.daysSelectedAmount) {
      const days = [1, 2, 3, 4, 5, 6, 7];
      days.forEach(day => {
        if (form.daysSelectedAmount[day]) {
          daysSelectedAmount[day] = formatCurrency(
            form.daysSelectedAmount[day],
            false,
            "usd"
          );
        }
      });
      console.log(form.daysSelectedAmount);
    }
    let choices = [];
    let dailyChoices = form.dailyChoices;
    if (form.registrationType === RegistrationType.course) {
      choices = form.choices
        ? form.choices.map(choiceId =>
            Models.Choice.createWithoutData(choiceId)
          )
        : [];
    } else if (form.dailyChoices) {
      Object.keys(form.dailyChoices).forEach(day => {
        choices = union(choices, form.dailyChoices[day] || []);
      });
      choices = choices.map(choiceId =>
        Models.Choice.createWithoutData(choiceId)
      );
    }

    const category = form.category
      ? new Models.Category({ id: form.category })
      : null;
    const location = form.location
      ? new Models.Location({ id: form.location })
      : null;
    const program = Object.assign({}, form, {
      business: new Models.Business({ id: businessId }),
      category,
      location,
      amount,
      choices,
      dailyChoices,
      dayAmount,
      deposit,
      dropinAmount,
      dropinLateRegistrationAmount,
      daysSelectedAmount,
      currency,
      discounts,
      fees,
      studentForms,
      accountForms,
      dates,
      scheduleType,
      startDate: Helpers.valueFromDate(sd),
      endDate: Helpers.valueFromDate(ed),
      startTime,
      endTime
    });
    return program;
  },
  getPrograms: state => state.programs,
  createOrderedPrograms: programs =>
    orderBy(
      Object.keys(programs).map(programId => programs[programId]),
      ["name"]
    ),

  createAdminPrograms: (programs, locations, categories) => {
    let results = {};
    Object.keys(programs).forEach(programId => {
      results[programId] = Object.assign({}, programs[programId], {
        image:
          programs[programId].image && programs[programId].image.url
            ? programs[programId].image.url
            : null,
        initials: programs[programId].name
          ? programs[programId].name
              .split(" ")
              .map((word, i) => (i > 1 ? "" : word.charAt(0).toUpperCase()))
              .join("")
          : null,
        locationName: programs[programId].location
          ? locations[programs[programId].location].name
          : null,
        categoryName: programs[programId].category
          ? categories[programs[programId].category].name
          : null,
        schedule: {
          startDate: utils.valueFromDate(programs[programId].startDate),
          endDate: utils.valueFromDate(programs[programId].endDate),
          startTime: programs[programId].startTime,
          endTime: programs[programId].endTime
        }
      });
    });
    return results;
  }
};

const Selectors = {
  getProgramOptions: createSelector([Helpers.getPrograms], obj =>
    orderBy(
      Object.keys(obj).map(key => ({
        key,
        text: obj[key].name,
        value: key
      })),
      ["text"]
    )
  ),
  getPrograms: createSelector(
    [Helpers.getPrograms],
    Helpers.createOrderedPrograms
  ),
  getAdminPrograms: createSelector(
    [
      state => state.programs,
      state => state.locations,
      state => state.categories
    ],
    Helpers.createAdminPrograms
  )
};

const Actions = {
  creators: {
    addProgram: data => ({
      data,
      type: ActionTypes.ADD_PROGRAM
    }),
    receivedPrograms: data => ({
      data,
      type: ActionTypes.RECEIVED_PROGRAMS
    }),
    receivedProgramTotals: data => ({
      data,
      type: ActionTypes.RECEIVED_PROGRAM_TOTALS
    }),
    receivedRegistrationPrograms: data => ({
      data,
      type: ActionTypes.RECEIVED_REGISTRATION_PROGRAMS
    })
  },
  thunks: {
    addProgram: (business, data) => async dispatch => {
      const { name } = data;
      try {
        const program = new Models.Program();
        await program.save({
          business,
          name,
          active: true
        });
        const result = program.toJSON();
        dispatch(Actions.creators.addProgram(result));
        return result;
      } catch (error) {
        const err = {
          _error: error.message
        };
        throw new SubmissionError(err);
      }
    },
    fetchProgram: programId => async dispatch => {
      try {
        const query = new Parse.Query(Models.Program);
        const program = await query.get(programId);
        return dispatch(
          Actions.creators.receivedPrograms(
            normalize(program.toJSON(), schema.program)
          )
        );
      } catch (error) {
        console.error("ERROR ", error);
      }
    },
    fetchRegistrationPrograms: business => async dispatch => {
      try {
        const query = new Parse.Query(Models.Program);
        query.equalTo("business", business);
        query.equalTo("active", true);
        query.include("business");
        query.include("studentForms");
        query.include("studentForms.fields");
        query.include("accountForms");
        query.include("accountForms.fields");
        query.equalTo("online", true);
        const programs = await query.find();
        const results = programs.map(s => s.toJSON());
        dispatch(
          Actions.creators.receivedRegistrationPrograms(
            normalize(results, schema.programs)
          )
        );
        const programIds = results.map(result => result.objectId);
        await Actions.thunks.fetchProgramTotals(programIds)(dispatch);
        return results;
      } catch (error) {
        console.error("ERROR ", error);
        throw error;
      }
    },

    fetchPrograms: (business, withTotals) => async dispatch => {
      // console.log("BUSINESS: ", business);
      try {
        const query = new Parse.Query(Models.Program);
        query.equalTo("business", business);
        query.equalTo("active", true);
        query.include("location");
        query.include("category");
        query.include("business");
        query.include("studentForms");
        query.include("accountForms");
        const programs = await query.find();

        const results = programs.map(s => s.toJSON());
        dispatch(
          Actions.creators.receivedPrograms(normalize(results, schema.programs))
        );
        if (withTotals) {
          const programIds = results.map(result => result.objectId);
          await Actions.thunks.fetchProgramTotals(programIds)(dispatch);
        }
        return results;
      } catch (error) {
        console.error("ERROR ", error);
      }
    },
    fetchProgramTotals: programIds => async dispatch => {
      const totals = await Parse.Cloud.run("programRegistrations", {
        programIds
      });

      const values = Object.keys(totals).map(objectId => ({
        objectId,
        ...totals[objectId]
      }));
      dispatch(
        Actions.creators.receivedProgramTotals(
          normalize(values, schema.programs)
        )
      );
      return values;
    },
    saveProgram: (program, form) => async dispatch => {
      try {
        await program.save(form);
        const result = program.toJSON();
        dispatch(Actions.creators.addProgram(result));
        return result;
      } catch (error) {
        const err = {
          _error: error.message
        };
        console.error(error);
        throw new SubmissionError(err);
      }
    }
  }
};

export { Actions, Helpers, Selectors };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.type === ActionTypes.RECEIVED_PROGRAM_TOTALS &&
    action.data.entities.programs
  ) {
    return state.merge(action.data.entities.programs, { deep: true });
  } else if (
    action &&
    action.type === ActionTypes.RECEIVED_REGISTRATION_PROGRAMS &&
    action.data.entities.programs
  ) {
    return Immutable(action.data.entities.programs);
  } else if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.programs
  ) {
    return state.merge(action.data.entities.programs);
  }
  return state;
};
