import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Container, Segment, Button } from 'semantic-ui-react';
import { Actions } from '../../ducks/forms';
import columns from './columns';
import Spreadsheet from '../Spreadsheet';
// import Models from '../parse/models';
import Models from '../../parse/models';

const { addForm, fetchForms } = Actions.thunks;

class FormsContainer extends React.Component {
  state = { loaded: false, redirect: false };
  componentDidMount() {
    this.props
      .fetchForms(
        Models.Business.createWithoutData(this.props.business.objectId),
      )
      .then(() => this.setState({ loaded: true }));
  }

  addForm = () => {
    const {
      business: { objectId },
      forms,
    } = this.props;
    const totalForms = Object.keys(forms).length;
    const formName = `Form ${totalForms + 1}`;
    this.props
      .addForm(Models.Business.createWithoutData(objectId), formName)
      .then(result =>
        this.setState({
          redirect: result,
        }),
      );
  };

  render() {
    const { cols, forms } = this.props;
    console.log(this.props);
    if (this.state.redirect) {
      return <Redirect to={`/forms/${this.state.redirect}`} push />;
    }
    return (
      <Container fluid>
        <Container fluid>
          <Segment basic>
            <Button
              icon="wordpress forms"
              content="Add Form"
              onClick={this.addForm}
              disabled={!this.state.loaded}
            />
          </Segment>
        </Container>
        <Container fluid>
          <Segment basic>
            <Spreadsheet cols={cols} data={forms} />
          </Segment>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business,
    cols: columns,
    forms: state.forms,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchForms, addForm }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormsContainer);
