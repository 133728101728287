import React from 'react';
// import { Image } from 'semantic-ui-react';
const ScheduleCellRenderer = ({
  cellData,
  columnData,
  columnIndex,
  dataKey,
  rowData,
  rowIndex,
  isScrolling,
}) => {
  // console.log(cellData);
  if (cellData === 'fullTime') {
    return <div>Full Time</div>;
  }
  return <div>Hi</div>;

  //   return (
  //     <div>
  //     {days.map(day)}
  //     </div>
  //   );
};

export default ScheduleCellRenderer;
