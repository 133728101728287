import React from "react";
import { Field, reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";
import { Form, Header, Segment, Message } from "semantic-ui-react";
import NumberFormatter from "../../../NumberFormatter";

const renderField = props => {
  const {
    input,
    type,
    meta: { error }
  } = props;
  console.log(props);
  return (
    <Form.Input
      type={type}
      {...input}
      {...props}
      size="large"
      fluid
      error={error !== undefined}
    />
  );
};

const renderPhoneField = props => {
  const {
    input,
    meta: { error },
    placeholder
  } = props;
  return (
    <Form.Field size="large" error={error !== undefined} input={input}>
      <div className="ui large fluid input">
        <NumberFormatter
          settings={{ placeholder, format: "(###) ###-####" }}
          input={input}
        />
      </div>
    </Form.Field>
  );
};

const SignupForm = props => {
  const { handleSubmit, submitting, error, submitSucceeded } = props;
  if (submitSucceeded) {
    return <Redirect to="/register" />;
  }
  return (
    <Form size="large" error={error !== undefined} onSubmit={handleSubmit}>
      <Segment>
        <Header as="h2" color="teal" textAlign="center">
          Create New Account
        </Header>
        <Field
          type="text"
          name="firstName"
          placeholder="First name"
          component={renderField}
        />
        <Field
          type="text"
          name="lastName"
          placeholder="Last name"
          component={renderField}
        />
        <Field
          name="phone"
          placeholder="Phone Number"
          settings={{ placeholder: "Phone Number", size: "large" }}
          // control={NumberFormatter}
          component={renderPhoneField}
        />
        <Field
          type="text"
          name="email"
          placeholder="Email address"
          autoComplete="username"
          component={renderField}
        />
        <Field
          placeholder="Password"
          name="password"
          type="password"
          autoComplete="current-password"
          component={renderField}
        />
        {error && error === "Account already exists for this username." && (
          <Message
            style={{ textAlign: "left" }}
            error
            content={
              "There is already a CommunityRoot account associated with this email address. To login with this email address, click on the link below."
            }
          />
        )}
        <Form.Button loading={submitting} color="teal" fluid size="large">
          Continue
        </Form.Button>
      </Segment>
    </Form>
  );
};

export default reduxForm({
  form: "signup" // a unique identifier for this form
})(SignupForm);
