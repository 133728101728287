import React from "react";

import { Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Form, Header, Segment, Message } from "semantic-ui-react";

const renderField = props => {
  const {
    input,
    // meta: { error, touched }
    error
  } = props;
  // console.log("props: ", props);
  return <Form.Input {...input} {...props} error={error} />;
};

let LoginForm = props => {
  const {
    currentAccount,
    currentStaff,
    handleSubmit,
    submitting,
    error,
    submitSucceeded
  } = props;
  if (submitSucceeded) {
    if (currentStaff && currentStaff.objectId) {
      return <Redirect to="/programs" />;
    } else if (currentAccount) {
      return <Redirect to="/register" />;
    }
  }
  console.log("ERROR: ", props);
  return (
    <Form error={error !== undefined} onSubmit={handleSubmit}>
      <Segment>
        <Header as="h2" color="teal" textAlign="center">
          Log In to your account
        </Header>
        <Field
          autoComplete="current-email"
          type="text"
          name="email"
          placeholder="Email address"
          size="large"
          component={renderField}
          error={error && error.indexOf("email") >= 0}
        />
        <Field
          autoComplete="current-password"
          placeholder="password"
          name="password"
          type="password"
          size="large"
          component={Form.Input}
          error={error && error.indexOf("password") >= 0}
        />
        {error && (
          <Message style={{ textAlign: "left" }} error content={error} />
        )}
        <Form.Button loading={submitting} color="teal" fluid size="large">
          Login
        </Form.Button>
      </Segment>
    </Form>
  );
};

export default reduxForm({
  form: "login" // a unique identifier for this form
})(LoginForm);
