import React from 'react';
import { Dropdown } from 'semantic-ui-react';

class SmartDropdown extends React.Component {
  state = { text: 'Access Level' };
  handleChange = (e, v) => {
    const {
      input: { onChange },
    } = this.props;

    this.setState({ text: v.value });
    onChange(v.value);
  };
  render() {
    const { text } = this.state;
    return (
      <div className="field">
        <div className="ui large fluid input">
          <Dropdown
            button
            size="large"
            className="icon large"
            labeled
            icon="lock"
            name="access"
            onChange={this.handleChange}
            options={[
              {
                text: 'Teacher',
                value: 'Teacher',
              },
              {
                text: 'Director',
                value: 'Director',
              },
            ]}
            text={text}
          />
        </div>
      </div>
    );
  }
}

export default SmartDropdown;
