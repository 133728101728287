import ProgramCellRenderer from "./ProgramCellRenderer";
import RegistrationsCellRenderer from "./RegistrationsCellRenderer";
import ProgramManageCellRenderer from "./ProgramManageCellRenderer";
import FileCellRenderer from "../Spreadsheet/FileCellRenderer";
import DefaultCellRenderer from "../Spreadsheet/DefultCellRenderer";
import ProgramScheduleCellRenderer from "./ProgramScheduleCellRenderer";

const cols = [
  {
    dataKey: "image",
    label: "",
    width: 40,
    minWidth: 40,
    cellRenderer: FileCellRenderer
  },
  {
    dataKey: "name",
    label: "Name",
    width: 190,
    minWidth: 190,
    flexGrow: 1,
    cellRenderer: ProgramCellRenderer
  },
  {
    dataKey: "locationName",
    label: "Location",
    width: 190,
    minWidth: 190,
    flexGrow: 1,
    cellRenderer: DefaultCellRenderer
  },
  {
    dataKey: "categoryName",
    label: "Category",
    width: 190,
    minWidth: 190,
    flexGrow: 1,
    cellRenderer: DefaultCellRenderer
  },
  {
    dataKey: "schedule",
    label: "Schedule",
    width: 120,
    flexGrow: 1,
    cellRenderer: ProgramScheduleCellRenderer
  },
  {
    dataKey: "totalEnrolled",
    label: "Total Students",
    width: 160,
    cellRenderer: RegistrationsCellRenderer
  },
  {
    dataKey: "totalPending",
    label: "Total Pending",
    width: 60,
    cellRenderer: RegistrationsCellRenderer
  },
  {
    dataKey: "objectId",
    label: "Actions",
    width: 160,
    minWidth: 160,
    cellRenderer: ProgramManageCellRenderer,
    className: "edit-cell-no-overflow",
    handleCellChange: true
    // flexGrow: 1,
  }
];

export default cols;
