import React from "react";
import "airbnb-js-shims";
import ReactDOM from "react-dom";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./utils/polyfills";
import "./parse";

import App from "./components/App";
import storage from "./store";

import "react-virtualized/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "./semantic/dist/semantic.min.css";
import "./index.css";
import "./styles/CalendarPicker.css";

import * as serviceWorker from "./serviceWorker";

const { persistor, store } = storage();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
