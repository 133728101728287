import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Actions } from "../../../../../../ducks/shoppingCart";
import RegisterPaymentMethod from "./RegisterPaymentMethod";

const { paymentMethodChange } = Actions.creators;

const mapStateToProps = state => ({
  accounSettings: state.accounSettings,
  businessSettings: state.businessSettings
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      paymentMethodChange
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPaymentMethod);
