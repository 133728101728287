import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';
import filter from 'lodash/filter';
import { Button } from 'semantic-ui-react';
import shortid from 'shortid';
import FieldOption from './FieldOption';

const defaultFieldOption = Immutable({
  value: null,
  number: null,
});

class FieldOptions extends React.Component {
  addOption = () => {
    const { options } = this.props;
    const number = options.length + 1;
    const value = `Value ${number}`;
    const option = defaultFieldOption.merge({ number, value });
    this.props.handleChange(options.concat(option));
  };
  removeOption = index => {
    const { options } = this.props;
    this.props.handleChange(filter(options, (option, i) => i !== index));
  };
  handleChange = (index, value) => {
    const { options } = this.props;
    const option = options[index];
    const newOption = option.merge({ value });
    const newOptions = options.map((option, i) => {
      if (i === index) {
        return newOption.merge({ number: i + 1 });
      }
      return option.merge({ number: i + 1 });
    });
    this.props.handleChange(newOptions);
  };
  render() {
    const { options } = this.props;
    return (
      <div>
        <div className="field">
          {options.map((option, i) => (
            <FieldOption
              key={shortid.generate()}
              option={option}
              index={i}
              handleChange={this.handleChange}
              removeOption={this.removeOption}
            />
          ))}
        </div>

        <div className="field">
          <Button onClick={() => this.addOption()} fluid>
            Add another option
          </Button>
        </div>
      </div>
    );
  }
}

FieldOptions.propTypes = {
  options: PropTypes.array,
};

export default FieldOptions;
