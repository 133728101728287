import Immutable from "seamless-immutable";
import { normalize } from "normalizr";
import Parse from "parse";
import schema from "../schema";
import Models from "../parse/models";

const ActionTypes = {
  ADDED_PAYMENT_METHOD: "ADDED_PAYMENT_METHOD",
  RECEIVED_PAYMENT_METHODS: "RECEIVED_PAYMENT_METHODS"
};

const Actions = {
  creators: {
    addedPaymentMethod: data => ({
      data,
      type: ActionTypes.ADDED_PAYMENT_METHOD
    }),
    receivedPaymentMethods: data => ({
      data,
      type: ActionTypes.RECEIVED_PAYMENT_METHODS
    })
  },
  thunks: {
    addElectronicCheck: (
      publicToken,
      plaidAccountId,
      userId
    ) => async dispatch => {
      const method = await Parse.Cloud.run("addPlaidAch", {
        publicToken,
        plaidAccountId,
        userId
      });
      const result = normalize(method.toJSON(), schema.paymentMethod);
      const action = Actions.creators.addedPaymentMethod(result);
      dispatch(action);
      return result;
    },
    addPaymentMethod: (token, userId) => async dispatch => {
      const method = await Parse.Cloud.run("addStripeCard", {
        token,
        userId
      });
      const result = normalize(method.toJSON(), schema.paymentMethod);
      const action = Actions.creators.addedPaymentMethod(result);
      dispatch(action);
      return result;
    },
    fetchUserPaymentMethods: user => async dispatch => {
      // console.log('FETCHING PAYMENT MEthODS FOR USER: ', user);
      const query = new Parse.Query(Models.PaymentMethod);
      query.equalTo("user", user);
      query.equalTo("active", true);
      query.descending("createdAt");
      const paymentMethods = await query.find();
      let defaultPaymentMethodId;
      const methods = paymentMethods.map(method => {
        if (method.get("default")) {
          defaultPaymentMethodId = method.id;
        }
        return method.toJSON();
      });
      if (!defaultPaymentMethodId && paymentMethods.length > 0) {
        console.log("assign default id");
        defaultPaymentMethodId = paymentMethods[0].id;
      }
      const result = normalize(methods, schema.paymentMethods);
      const action = Actions.creators.receivedPaymentMethods(
        Object.assign({ defaultPaymentMethodId }, result)
      );
      dispatch(action);
      return result;
    }
  }
};

export { Actions, ActionTypes };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.paymentMethods
  ) {
    return state.merge(action.data.entities.paymentMethods);
  }
  return state;
};
