import React from 'react';
import { Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const RegistrationsCellRenderer = ({ cellData, rowData }) => {
  const { objectId } = rowData;

  return (
    // <div>Edit Cell FIX ME</div>

    <Popup
      content="View Registrations"
      trigger={<Link to={`/registrations/${objectId}`}>{cellData}</Link>}
    />
  );
};
export default RegistrationsCellRenderer;
