import React from 'react';
import { Icon, Button, Dropdown } from 'semantic-ui-react';

const TransactionEditCellRenderer = ({ cellData, rowData, handleChange }) => {
  // console.log(rowData);
  return (
    <Dropdown
      trigger={
        <Button icon>
          Options
          <Icon name="caret down" />
        </Button>
      }
      onChange={(e, d) => handleChange(d.value, cellData)}
      icon={null}
      options={[
        {
          key: 'manage',
          icon: 'file alternate outline',
          text: 'Manage',
          value: 'manage',
          onClick: (e, d) => handleChange(d.value, cellData),
        },
        {
          key: 'reminder',
          icon: 'redo',
          text: 'Send Reminder',
          value: 'reminder',
          onClick: (e, d) => handleChange(d.value, cellData),
        },
        {
          key: 'cancel',
          icon: 'delete',
          text: 'Cancel',
          value: 'cancel',
          onClick: (e, d) => handleChange(d.value, cellData),
        },
      ]}
    />
  );
};
export default TransactionEditCellRenderer;
