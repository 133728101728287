import React from "react";
import { Button, Popup } from "semantic-ui-react";
import csv from "../../utils/csv";
import Report from "./Report";

class Spreadsheet extends React.Component {
  handleExport = () => {
    csv({
      data: Object.keys(this.props.data).map(key => this.props.data[key]),
      fields: this.props.cols,
      filename: "export.csv"
    });
  };
  render() {
    const { loading, exportable, ...props } = this.props;
    return (
      <div>
        {exportable && (
          <Popup
            trigger={
              <Button
                size="tiny"
                // circular
                basic
                onClick={this.handleExport}
                icon="download"
                floated="right"
              />
            }
            content="Download report as a csv file"
          />
        )}
        <Report loading={loading} {...props} />
      </div>
    );
  }
}

Spreadsheet.defaultProps = {
  exportable: false
};

export default Spreadsheet;
