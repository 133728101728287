import React from 'react';

import { Menu } from 'semantic-ui-react';

class DayPicker extends React.Component {
  state = {
    activeItem: 'Start and End Dates',
    highlighted: [],
    lastSelectedDate: new Date(),
  };
  handleDayClick = (e, d) => {
    console.log(this.props.input);
    const { onChange, value } = this.props.input;
    const values = value || [];
    const arr = [...values];

    const day = d.name.toLowerCase();
    if (this.props.input.value.indexOf(day) < 0) {
      arr.push(day);
    } else {
      arr.splice(arr.indexOf(day), 1);
    }
    onChange(arr);
  };
  render() {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return (
      <Menu compact color="orange" size="small">
        {days.map(day => (
          <Menu.Item
            key={day}
            name={day}
            active={this.props.input.value.indexOf(day.toLowerCase()) > -1}
            onClick={this.handleDayClick}
          />
        ))}
      </Menu>
    );
  }
}

export default DayPicker;
