import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./App.css";
import { Actions } from "../../ducks/business";
import Router from "../Router";
const { hydrate } = Actions.thunks;
class App extends React.Component {
  componentDidMount() {
    this.props.hydrate();
  }
  render() {
    return <Router />;
  }
}

// Not currently doing anything with this
const mapStateToProps = state => {
  return { business: state.business };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators({ hydrate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
