import React from "react";
import {
  Container,
  Header,
  List,
  Form,
  Segment,
  Grid
} from "semantic-ui-react";
import RegisterFormItem from "./RegisterFormItem/RegisterFormItem";
import RegisterContinueButton from "../../RegisterContinueButton";
import BlurredLoader from "../../../../BlurredLoader";

const RegistrationForms = ({
  currentAccountId,
  currentStudentId,
  accountForms,
  studentForms,
  disabledContinue,
  formStatus,
  loading
}) => {
  // console.log(formStatus);
  return (
    <Container fluid>
      <BlurredLoader loading={loading}>
        {accountForms && accountForms.length > 0 && (
          <Header>Account Forms</Header>
        )}
        {studentForms && studentForms.length > 0 && (
          <Container fluid>
            <Header>Student Forms</Header>
            <Segment color="grey" inverted>
              <List verticalAlign="middle">
                {studentForms.map(studentForm => (
                  <RegisterFormItem
                    key={studentForm.objectId}
                    form={studentForm}
                    studentId={currentStudentId}
                    accountId={currentAccountId}
                    formStatus={
                      formStatus.students[currentStudentId]
                        ? formStatus.students[currentStudentId][
                            studentForm.objectId
                          ]
                        : null
                    }
                  />
                ))}
              </List>
            </Segment>
          </Container>
        )}
        <Form>
          <Segment basic>
            <Grid columns={1} textAlign="right">
              <Grid.Column>
                {/* <Segment basic> */}
                <RegisterContinueButton
                  to="/register/payment"
                  primary
                  disabled={disabledContinue}
                  size="big"
                  // fluid
                />
                {/* </Segment> */}
              </Grid.Column>
            </Grid>
          </Segment>
        </Form>
      </BlurredLoader>
    </Container>
  );
};

export default RegistrationForms;
