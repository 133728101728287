import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Container, Form, Segment, Header } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

const renderField = props => {
  const {
    input,
    type,
    meta: { error },
    placeholder,
  } = props;
  return (
    <Form.Input
      type={type}
      placeholder={placeholder}
      {...input}
      size="large"
      fluid
      error={error !== undefined}
    />
  );
};

const renderDate = props => (
  <div className="field">
    <div className="ui large fluid input">
      <DatePicker
        name={props.input.name}
        autoComplete={props.input.name}
        // selected={this.state.dob}
        // onChange={this.handleChange}
        placeholderText={props.placeholder}
        className="datepicker-input"
        selected={props.input.value || null}
        onChange={props.input.onChange}
      />
    </div>
  </div>
);

const StudentForm = props => {
  const { handleSubmit, submitting, error } = props;
  return (
    <Container style={{ maxWidth: '300px' }}>
      <Segment basic>
        <Header>Add Student</Header>
        <Form error={error !== undefined} onSubmit={handleSubmit}>
          <Field
            type="text"
            name="firstName"
            placeholder="First Name"
            component={renderField}
          />
          <Field
            type="text"
            name="lastName"
            placeholder="Last Name"
            component={renderField}
          />

          <Field
            type="text"
            name="dob"
            placeholder="Date of Birth"
            component={renderDate}
          />

          <Form.Button loading={submitting} color="teal" fluid size="large">
            Save
          </Form.Button>
        </Form>
      </Segment>
    </Container>
  );
};

export default reduxForm({
  form: 'studentForm', // a unique identifier for this form
})(StudentForm);
