import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Field } from 'redux-form';

import sortBy from 'lodash/sortBy';

import { Actions } from '../../../../../ducks/forms';
import Models from '../../../../../parse/models';
import CheckboxArray from '../../../../CheckboxArray';

const { fetchForms } = Actions.thunks;

// Selectors
const orderForms = state => {
  const forms = sortBy(Object.keys(state.forms).map(key => state.forms[key]), [
    'name',
  ]);
  return forms;
};
const getForms = () => {
  return createSelector(
    orderForms,
    forms => forms,
  );
};

// =============

class ProgramFormsContainer extends React.Component {
  state = {
    choiceModalOpen: false,
  };

  componentDidMount() {
    if (this.props.business && this.props.business.objectId) {
      this.props.fetchForms(new Models.Business({id: this.props.business.objectId}));
    }
  }

  render() {
    const { forms } = this.props;
    return (
      <div className="field">
        <Field name="studentForms" options={forms} component={CheckboxArray} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const formsSelector = getForms();
  return {
    business: state.business,
    forms: formsSelector(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchForms,
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramFormsContainer);
