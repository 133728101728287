import React from "react";
import { Link } from "react-router-dom";
// import { Image } from 'semantic-ui-react';
const StudentCellRenderer = ({
  cellData,
  columnData,
  columnIndex,
  dataKey,
  rowData,
  rowIndex,
  isScrolling
}) => {
  return <Link to={`/students/${rowData.objectId}`}>{cellData}</Link>;
};

export default StudentCellRenderer;
