import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Actions as ShoppingCartActions,
  Helpers
} from "../../../../ducks/shoppingCart";
import { Actions, Selectors } from "../../../../ducks/programs";
import RegisterPrograms from "./RegisterPrograms";
import Models from "../../../../parse/models";

const { fetchRegistrationPrograms } = Actions.thunks;

const { addProgramShoppingCart } = ShoppingCartActions.creators;

class RegisterProgramsContainer extends React.Component {
  state = { loading: false };
  fetchData = async () => {
    const { business } = this.props;
    this.setState({ loading: true });
    await this.props.fetchRegistrationPrograms(
      new Models.Business({ id: business.objectId })
    );
    this.setState({ loading: false });
  };
  componentDidMount = () => {
    this.fetchData();
  };

  render() {
    const { currentAccount, programs, shoppingCart } = this.props;
    const disabledContinue = Helpers.disabledProgramContinue(shoppingCart);
    const { activeStudent } = shoppingCart;
    const student = this.props.students[activeStudent] || {};
    return (
      <RegisterPrograms
        disabledContinue={disabledContinue}
        currentAccount={currentAccount}
        shoppingCart={shoppingCart}
        programs={programs}
        student={student}
        loading={this.state.loading}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: state.currentAccount,
    business: state.business,
    programs: Selectors.getPrograms(state),
    shoppingCart: state.shoppingCart,
    students: state.students
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addProgramShoppingCart,
      fetchRegistrationPrograms
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterProgramsContainer);
