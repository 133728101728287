import React from "react";
import { Container, List, Segment, Grid } from "semantic-ui-react";
import AnimateHeight from "react-animate-height";
import CustomFormContainer from "../../../../../CustomFormContainer";
import "./RegisterFormItem.css";

class RegisterFormItem extends React.Component {
  state = {
    open: false
  };

  toggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const { open } = this.state;
    const { form, studentId, accountId, formStatus } = this.props;
    const height = open ? "auto" : 0;
    let icon = "clipboard outline";
    let color = "grey";
    if (formStatus.valid) {
      color = "teal";
      icon = "check";
      if (formStatus.totalComplete === 0) {
        color = "yellow";
      }
    }
    // if (formStatus !== "incomplete") {
    //   if (!formStatus || Object.keys(formStatus).length === 0) {
    //     icon = "check";
    //     color = "green";
    //   }
    // }
    // console.log(formStatus);
    return (
      <List.Item style={{ padding: 0, marginBottom: "1em" }}>
        <Segment>
          <List
            style={{ padding: 0 }}
            onClick={this.toggle}
            selection
            verticalAlign="middle"
            className="form-list-item"
          >
            <Grid columns={2}>
              <Grid.Column width={1} textAlign="center" verticalAlign="middle">
                <List.Icon size="big" name={icon} color={color} />
              </Grid.Column>
              <Grid.Column width={13}>
                <List.Item style={{ padding: ".5em .5em" }}>
                  <List.Content>
                    <List.Header as="h3">{form.name}</List.Header>
                    <List.Description>
                      Source files for project
                    </List.Description>
                  </List.Content>
                </List.Item>
                <p />
                {/* <Progress
                  percent={
                    (formStatus.totalComplete / formStatus.totalFields) * 100
                  }
                  size="tiny"
                  color={color}
                >
                  {formStatus.totalComplete}/{formStatus.totalFields} answered
                </Progress> */}
              </Grid.Column>
            </Grid>
          </List>
          <Container>
            <AnimateHeight
              duration={300}
              height={height} // see props documentation below
            >
              <Segment secondary basic>
                {height === 0 && <React.Fragment />}
                {height === "auto" && (
                  <CustomFormContainer
                    form={form.objectId}
                    {...form}
                    studentId={studentId}
                    accountId={accountId}
                  />
                )}
              </Segment>
            </AnimateHeight>
          </Container>
        </Segment>
      </List.Item>
    );
  }
}

export default RegisterFormItem;
