// import Immutable from 'seamless-immutable';
// import { normalize } from 'normalizr';
// import { SubmissionError } from 'redux-form';
import Parse from 'parse';
// import Models from '../parse/models';

const ActionTypes = {
  CONNECT_STRIPE: 'CONNECT_STRIPE',
  STRIPE_CONNECTED: 'STRIPE_CONNECTED',
};

const Actions = {
  creators: {
    connectStripe: data => ({
      data,
      type: ActionTypes.CONNECT_STRIPE,
    }),
    stripeConnected: data => ({
      data,
      type: ActionTypes.STRIPE_CONNECTED,
    }),
  },
  thunks: {
    connectStripe: (code, businessId) => async dispatch => {
      const connected = await Parse.Cloud.run('stripe', { code, businessId });
      dispatch(Actions.creators.stripeConnected(connected));
    },
  },
};

export { Actions };

const defaultState = false;
export default (state = defaultState, action) => {
  if (action && action.type === ActionTypes.STRIPE_CONNECTED) {
    console.log(action);
    return action.data;
  }
  return state;
};
