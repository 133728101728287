import React from "react";
import PaymentMethodFormContainer from "../PaymentMethodFormContainer";
import { Modal, Header, Icon, Container, Form } from "semantic-ui-react";
import PlaidMethodContainer from "../PlaidMethodContainer";

class PaymentMethodFormModal extends React.Component {
  state = { modalOpen: false };
  handleOpen = modal => this.setState({ modalOpen: modal });
  handleClose = () => this.setState({ modalOpen: false });

  selectionContent = () => {
    return (
      <React.Fragment>
        <Modal.Header>Select a Payment Option</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <Form.Button
                    fluid
                    type="button"
                    onClick={() => this.setState({ modalOpen: "card" })}
                  >
                    <Icon name="credit card" />
                    Credit Card
                  </Form.Button>
                </Form.Field>
                <Form.Field>
                  <PlaidMethodContainer />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </React.Fragment>
    );
  };
  methodContent = modalOpen => {
    return (
      <React.Fragment>
        <Modal.Header>Add a Payment Method</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <PaymentMethodFormContainer onFinish={this.handleClose} />
          </Modal.Description>
        </Modal.Content>
      </React.Fragment>
    );
  };

  render() {
    const { modalOpen } = this.state;
    let content = this.selectionContent();
    if (modalOpen === "card" || modalOpen === "ach") {
      content = this.methodContent(modalOpen);
    }
    console.log(this.props);
    return (
      <Container fluid>
        <Header
          href=""
          as="a"
          size="tiny"
          style={{ fontWeight: "normal", fontSize: "1em" }}
          color="blue"
          onClick={e => {
            e.preventDefault();
            this.handleOpen("option");
          }}
        >
          <Icon name="plus" />
          Add Payment Method
        </Header>
        <Modal
          open={this.state.modalOpen !== false}
          onClose={this.handleClose}
          centered={false}
          size={modalOpen === "option" ? "small" : "mini"}
        >
          {content}
        </Modal>
      </Container>
    );
  }
}

export default PaymentMethodFormModal;
