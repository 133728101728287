import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Segment } from "semantic-ui-react";
import { Actions, Selectors } from "../../ducks/programs";
// import ProgramModal from './programs/ProgramModal';
import NewProgramContainer from "./NewProgramContainer";
import Spreadsheet from "../Spreadsheet";
import columns from "./columns";
import Models from "../../parse/models";

const { fetchPrograms } = Actions.thunks;

class ProgramsContainer extends React.Component {
  componentDidMount() {
    const { business } = this.props;
    if (business.objectId) {
      this.props.fetchPrograms(
        new Models.Business({ id: business.objectId }),
        true
      );
    }
  }
  render() {
    const { cols, data } = this.props;
    // console.log(data);
    return (
      <Container fluid>
        <Container fluid>
          <Segment basic>
            <NewProgramContainer />
          </Segment>
        </Container>
        <Container fluid>
          <Segment basic>
            <Spreadsheet cols={cols} data={data} />
          </Segment>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business,
    cols: columns,
    data: Selectors.getAdminPrograms(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchPrograms }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProgramsContainer);
