import React from "react";
import PropTypes from "prop-types";
import Measure from "react-measure";
import moment from "moment";
import {
  SortableContainer,
  SortableElement
  // arrayMove
} from "react-sortable-hoc";
import { Table as VirtualizedTable, Column } from "react-virtualized";
import memoize from "memoize-one";
import _ from "lodash";
import sd from "./SortDirection";
import "./Report.css";
import { Loader, Dimmer, Segment } from "semantic-ui-react";

const SortableHeader = SortableElement(({ children, ...props }) =>
  React.cloneElement(children, props)
);

const SortableHeaderRowRenderer = SortableContainer(
  ({ className, columns, style }) => (
    <div className={className} role="row" style={style}>
      {React.Children.map(columns, (column, index) => (
        <SortableHeader index={index}>{column}</SortableHeader>
      ))}
    </div>
  )
);

// cols HAVE to have objectId for sort to work correctly
class Report extends React.Component {
  state = {
    rowHeight: this.props.rowHeight || 50,
    sortBy: this.props.sortBy || "objectId",
    sortDirection: sd.ASC,
    sortable: this.props.sortable || false,
    headerHeight: this.props.headerHeight || 40,
    noResultsMsg: this.props.noResultsMsg || "No results",
    dimensions: {
      width: -1,
      height: -1,
      top: -1,
      bottom: -1
    }
  };
  // Returns the sorted keys
  static _sortKeys({ sortBy, sortDirection, data }) {
    // console.log("We need to sort: ", sortBy, sortDirection, data);
    const values = Object.keys(data).map(key => data[key]);

    // console.log(values);
    let sortedValues = _.sortBy(values, value => {
      if (sortBy === "dateTime") {
        return moment(value[sortBy], "MM/DD/YYYY h:mm:ss a").toISOString();
      }
      return value[sortBy];
    });
    // console.log(sortedValues);
    // let sortedValues = _.sortBy(values, value => value[sortBy]);
    if (sortDirection === sd.DESC) {
      // reverse
      sortedValues = _.reverse(sortedValues);
    }
    return sortedValues.map(sortedValue => sortedValue.objectId);
  }

  sortKeys = memoize(({ sortBy, sortDirection, data }) =>
    Report._sortKeys({ sortBy, sortDirection, data })
  );

  // onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
  //   this.setState(({ columnWidths }) => ({
  //     columnWidths: {
  //       ...columnWidths,
  //       [columnKey]: newColumnWidth,
  //     },
  //   }));
  // }

  // onColumnReorderEndCallback = (event) => {
  //   const columnOrder = this.state.columnOrder.filter(
  //     columnKey => columnKey !== event.reorderColumn,
  //   );
  //   const oldIndex = this.state.columnOrder.indexOf(event.reorderColumn);
  //   if (event.columnAfter) {
  //     const index = columnOrder.indexOf(event.columnAfter);
  //     columnOrder.splice(index, 0, event.reorderColumn);
  //   } else {
  //     columnOrder.push(event.reorderColumn);
  //   }
  //   const newIndex = columnOrder.indexOf(event.reorderColumn);
  //   if (oldIndex === newIndex) {
  //     return;
  //   }
  //   this.setState({
  //     columnOrder,
  //   });
  //   this.props.handleColumnReorder(this.state.columnOrder);
  // }

  render = () => {
    // console.log(this.state);
    const {
      sortBy,
      sortDirection,
      sortable,
      dimensions,
      rowHeight,
      headerHeight
    } = this.state;
    // console.log(this.state);
    const { cols, data, loading } = this.props;
    const sortedKeys = this.sortKeys({ sortBy, sortDirection, data });
    const padding = parseInt(
      window
        .getComputedStyle(document.body, null)
        .getPropertyValue("font-size"),
      10
    );

    const width = dimensions.width;
    const height = window.innerHeight - dimensions.top - padding;
    return (
      <Measure
        bounds
        onResize={contentRect => {
          this.setState({ dimensions: contentRect.bounds });
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <Segment basic style={{ padding: 0, margin: 0 }}>
              <Dimmer active={loading} inverted>
                <Loader inverted />
              </Dimmer>
              <VirtualizedTable
                headerClassName="headerColumn"
                rowClassName={this._rowClassName}
                disableHeader={false}
                headerHeight={headerHeight}
                noRowsRenderer={this._noRowsRenderer}
                height={height}
                rowGetter={({ index }) => this._rowGetter(index, sortedKeys)}
                rowHeight={rowHeight}
                rowCount={sortedKeys.length}
                width={width}
                // sort={this._sort}
                sortBy={sortBy}
                sortDirection={sortDirection}
                loading={true}
                headerRowRenderer={params => (
                  <SortableHeaderRowRenderer
                    {...params}
                    axis="x"
                    lockAxis="x"
                    onSortEnd={this.onSortEnd}
                  />
                )}
              >
                {cols.map(col => (
                  <Column
                    {...col}
                    key={col.dataKey}
                    disableSort={sortable}
                    width={col.width || 100}
                    minWidth={col.minWidth || 100}
                    flexGrow={col.flexGrow || 0}
                    flexShrink={1}
                    // cellRenderer={}
                    cellRenderer={this._cellRenderer(col)}
                  />
                ))}
              </VirtualizedTable>
            </Segment>

            {/* {this.props.loading && <Loader />} */}
          </div>
        )}
      </Measure>
    );
  };

  // Column sorter
  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      const sortKeyObject = this.props.cols[newIndex];
      let { sortBy, sortDirection } = this.state;
      if (sortBy === sortKeyObject.dataKey) {
        sortDirection = sortDirection === sd.DESC ? sd.ASC : sd.DESC;
      } else {
        sortBy = sortKeyObject.dataKey;
        sortDirection = sd.DESC;
      }
      this.setState({
        sortBy,
        sortDirection
      });
    } else {
      // this.setState(state => ({
      //   cols: arrayMove(state.cols, oldIndex, newIndex)
      // }));
    }
  };

  _headerRenderer = () => {};
  _noRowsRenderer = () => {
    // console.log(this.props);
    return <div>{this.props.noResultsMsg || this.state.noResultsMsg}</div>;
  };

  manageCellRenderer = col => data => {
    const Cell = col.cellRenderer;
    return <Cell {...data} handleChange={this.props.handleCellChange} />;
  };
  _cellRenderer = col => {
    if (col.cellRenderer) {
      if (col.handleCellChange) {
        return this.manageCellRenderer(col);
      }
      return col.cellRenderer;
    }
    return null;
  };
  _rowClassName = ({ index }) => {
    if (index < 0) {
      return "headerRow";
    } else {
      return index % 2 === 0 ? "evenRow" : "oddRow";
    }
  };
  _rowGetter = (index, sortedKeys) => {
    const { data } = this.props;
    const row = data[sortedKeys[index]] || {};
    return row;
  };
  _isSortEnabled = () => {
    // TODO: make this dynamic
    return true;
  };

  _sortedKeysHelper = () => {
    const { sortBy, sortDirection } = this.state;
    const { data } = this.props;
    return Report._sortKeys({
      sortBy,
      sortDirection,
      data
    });
  };
}

Report.defaultProps = {
  cols: [],
  data: {}
};

Report.propTypes = {
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string,
      label: PropTypes.string
    })
  ),
  data: PropTypes.object.isRequired
};

export default Report;
