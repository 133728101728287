import Immutable from "seamless-immutable";
import { normalize } from "normalizr";
import Parse from "parse";

import { SubmissionError } from "redux-form";
import schema from "../schema";
import Models from "../parse/models";

const ActionTypes = {
  UPSET_BUSINESS_SETTINGS: "UPSET_BUSINESS_SETTINGS",
  RECEIVED_BUSINESS_SETTINGS: "RECEIVED_BUSINESS_SETTINGS"
};

const Actions = {
  creators: {
    upsertBusinessSettings: data => ({
      data,
      type: ActionTypes.UPSET_BUSINESS_SETTINGS
    }),

    receivedBusinessSettings: data => ({
      data,
      type: ActionTypes.RECEIVED_BUSINESS_SETTINGS
    })
  },
  thunks: {
    upsertBusinessSettings: data => async dispatch => {
      try {
        const businessSettings = new Models.BusinessSettings();
        await businessSettings.save(data);
        const result = normalize(
          businessSettings.toJSON(),
          schema.businessSettings
        );
        dispatch(Actions.creators.upsertBusinessSettings(result));
        return businessSettings.toJSON();
      } catch (error) {
        const err = {
          _error: error.message
        };
        throw new SubmissionError(err);
      }
    },
    fetchBusinessSettings: business => async dispatch => {
      try {
        const query = new Parse.Query(Models.BusinessSettings);
        query.equalTo("business", business);
        const businessSettings = await query.first();
        let settings = businessSettings ? businessSettings.toJSON() : {};
        return dispatch(
          Actions.creators.receivedBusinessSettings(
            normalize(settings, schema.businessSettings)
          )
        );
      } catch (error) {
        // const err = {
        //   _error: error.message,
        // };
        console.error(error);
      }
    }
  }
};

export { Actions };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.businessSettings &&
    action.data.result
  ) {
    return Immutable(action.data.entities.businessSettings[action.data.result]);
  }
  return state;
};
