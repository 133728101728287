import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import Parse from 'parse';

import { normalize } from 'normalizr';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import union from 'lodash/union';

import Models from '../parse/models';
import schema from '../schema';
const ActionTypes = {
  ADD_FORM: 'ADD_FORM',
  UPSERT_FIELD: 'UPSERT_FIELD',
  UPDATE_FIELDS: 'UPDATE_FIELDS',
  UPDATE_FORM: 'UPDATE_FORM',
  RECEIVED_FORM: 'RECEIVED_FORM',
  RECEIVED_FORMS: 'RECEIVED_FORMS',
  RECEIVED_FORMS_AND_VALUES: 'RECEIVED_FORMS_AND_VALUES',
};

const Private = {
  queryFormValues: async (forms, { account, students }) => {
    // console.log(' ACCOUNT: ', account);
    // console.log(' STUDENTS: ', students);
    const query = new Parse.Query(Models.FormValue);
    query.containedIn('form', forms);
    if (account) {
      // console.log('query account');
      query.equalTo('account', account);
    }
    if (students) {
      // console.log('query student');
      // console.log(students);
      query.containedIn('student', students);
    }
    query.include('fieldValues');
    return query.find();
  },
  queryFieldValues: async (fields, { account, students }) => {
    if (fields.length === 0) {
      return [];
    }
    // console.log(' ACCOUNT: ', account);
    // console.log(' STUDENTS: ', students);
    const query = new Parse.Query(Models.FieldValue);
    query.containedIn('field', fields);
    if (account) {
      // console.log('query account');
      query.equalTo('account', account);
    }
    if (students) {
      // console.log('query student');
      // console.log(students);
      query.containedIn('student', students);
    }
    return query.find();
  },
  getForm: (state, props) => {
    const {
      match: {
        params: { formId },
      },
    } = props;
    return state.forms[formId];
  },
  getFields: state => state.fields,
  createForm: (form, fields) => {
    if (form && form.fields) {
      const activeFields = filter(
        form.fields.map(fieldId => fields[fieldId]),
        field => field.active,
      );
      return form.merge({
        fields: orderBy(activeFields, ['number'], ['asc']),
      });
    }
    return Immutable({ fields: [] });
  },

  updateLocalFields: (formId, fields) => {
    const entity = {};
    entity[formId] = {
      fields,
    };
    return { entities: { forms: entity } };
  },
};

const Selectors = {
  formBuilder: createSelector(
    [Private.getForm, Private.getFields],
    Private.createForm,
  ),
};

const Actions = {
  creators: {
    addForm: data => ({
      data,
      type: ActionTypes.ADD_FORM,
    }),
    upsertField: data => ({
      data,
      type: ActionTypes.UPSERT_FIELD,
    }),
    updateFields: data => ({
      data,
      type: ActionTypes.UPDATE_FIELDS,
    }),
    updateForm: data => ({
      data,
      type: ActionTypes.UPDATE_FORM,
    }),
    receivedForm: data => ({
      data,
      type: ActionTypes.RECEIVED_FORM,
    }),
    receivedForms: data => ({
      data,
      type: ActionTypes.RECEIVED_FORMS,
    }),
    receivedFormsAndValues: data => ({
      data,
      type: ActionTypes.RECEIVED_FORMS_AND_VALUES,
    }),
  },
  thunks: {
    addForm: (business, name) => async dispatch => {
      const form = new Models.Form();
      try {
        await form.save({
          business,
          name,
          fields: [],
          active: true,
        });
        dispatch(
          Actions.creators.addForm(normalize(form.toJSON(), schema.form)),
        );
        return form.id;
      } catch (error) {
        console.log('ERROR: ', error);
      }
      console.log('FORM: ', form);
    },
    fetchForm: formId => async dispatch => {
      try {
        const query = new Parse.Query(Models.Form);
        query.include('fields');
        query.equalTo('objectId', formId);
        const form = await query.first();
        return dispatch(
          Actions.creators.receivedForm(normalize(form.toJSON(), schema.form)),
        );
      } catch (error) {
        console.log('ERROR: ', error);
      }
    },
    addField: params => async dispatch => {
      const { formId, number, label, type, required } = params;
      const form = Models.Form.createWithoutData(formId);
      const field = new Models.Field();
      await field.save({
        label,
        type,
        number,
        required,
        form,
        active: true,
      });

      dispatch(
        Actions.creators.upsertField(normalize(field.toJSON(), schema.field)),
      );

      form.addUnique('fields', field);
      await form.save();
      dispatch(
        Actions.creators.updateForm(normalize(form.toJSON(), schema.form)),
      );
      // Actions.thunks.fetchForm(formId)(dispatch);
    },
    updateField: (fieldId, data) => async dispatch => {
      const field = new Models.Field({ id: fieldId });
      Object.keys(data).forEach(key => {
        field.set(key, data[key]);
      });
      dispatch(
        Actions.creators.upsertField(normalize(field.toJSON(), schema.field)),
      );
      await field.save();
      dispatch(
        Actions.creators.upsertField(normalize(field.toJSON(), schema.field)),
      );
    },
    updateFields: (formId, fields) => async dispatch => {
      dispatch(
        Actions.creators.updateFields(
          normalize(fields.map(field => field.toJSON()), schema.fields),
        ),
      );
      await Parse.Object.saveAll(fields);
      dispatch(
        Actions.creators.updateFields(
          normalize(fields.map(field => field.toJSON()), schema.fields),
        ),
      );

      Actions.thunks.updateForm(
        { fields: filter(fields, field => field.get('active') !== false) },
        formId,
      )(dispatch);
    },
    updateForm: (data, formId) => async dispatch => {
      const form = new Models.Form();
      form.id = formId;
      try {
        await form.save(data);
        dispatch(
          Actions.creators.updateForm(normalize(form.toJSON(), schema.form)),
        );
      } catch (error) {
        console.log(error);
      }
    },
    fetchForms: business => async dispatch => {
      try {
        const query = new Parse.Query(Models.Form);
        query.equalTo('business', business);
        query.equalTo('active', true);
        query.include('fields');
        const forms = await query.find();
        const results = forms.map(form => form.toJSON());
        dispatch(
          Actions.creators.receivedForms(normalize(results, schema.forms)),
        );
        return results;
      } catch (error) {}
    },
    fetchRegisterForms: (programs, { account, students }) => async dispatch => {
      try {
        const results = await Parse.Object.fetchAllWithInclude(programs, [
          'studentForms',
          'studentForms.fields',
          'accountForms',
          'accountForms.fields',
          'discounts',
          'fees',
        ]);
        // console.log(results);
        let studentForms = [];
        let accountForms = [];

        results.forEach(program => {
          program.get('studentForms').forEach(form => studentForms.push(form));
          program.get('accountForms').forEach(form => accountForms.push(form));
        });

        // console.log(flattenDeep(studentFields));
        // console.log(accountFields);

        const [accountFormValues, studentFormValues] = await Promise.all([
          Private.queryFormValues(accountForms, { account }),
          Private.queryFormValues(studentForms, { account, students }),
        ]);

        const formValues = union(
          accountFormValues.map(fv => fv.toJSON()),
          studentFormValues.map(fv => fv.toJSON()),
        );

        dispatch(
          Actions.creators.receivedFormsAndValues(
            normalize(
              { programs: results.map(s => s.toJSON()), formValues },
              schema.formsAndFormValues,
            ),
          ),
        );
        return results;
      } catch (error) {
        console.error('ERROR ', error);
      }
    },
  },
};

export { Actions, Selectors };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.forms
  ) {
    return state.merge(action.data.entities.forms);
  }

  return state;
};
