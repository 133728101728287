import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Actions } from '../../ducks/currentUser';

const LogoutButtonContainer = props => {
  const Component = props.component;
  return <Component onClick={props.logout}>Log Out</Component>;
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ logout: Actions.thunks.logoutUser }, dispatch);
export default withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(LogoutButtonContainer),
);
