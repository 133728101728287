import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RegisterPaymentItem from "./RegisterPaymentItem";
import { Actions } from "../../../../../../../ducks/shoppingCart";
const { updateProgramShoppingCart } = Actions.creators;

const mapStateToProps = state => ({
  shoppingCart: state.shoppingCart
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateProgramShoppingCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPaymentItem);
