import React from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { bindActionCreators } from "redux";
import xor from "lodash/xor";
import { Actions as FieldValueActions } from "../../ducks/fieldValues";
import { Actions, Selectors } from "../../ducks/registrations";
import { Selectors as VisibleFieldSelectors } from "../../ducks/visibleFields";

import Models from "../../parse/models";

import Registrations from "./Registrations";

const { fetchVisibleFieldValues } = FieldValueActions.thunks;
const { fetchRegistrations } = Actions.thunks;

class RegistrationsContainer extends React.Component {
  state = { isLoading: false };

  componentDidMount = () => {
    const { programId } = this.props.match.params;
    this.setState({ isLoading: true });
    this.props
      .fetchRegistrations(new Models.Program({ id: programId }))
      .then(result => {
        this.setState({ isLoading: false });
        return result;
      });
  };
  componentDidUpdate = prevProps => {
    const prev = Object.keys(prevProps.visibleFields.registration);
    const next = Object.keys(this.props.visibleFields.registration);
    if (next.length < prev.length) {
      return;
    }

    // field needs to actually be custom
    const diff = xor(prev, next);
    const { fields } = this.props;
    const [el] = diff;
    if (!fields[el]) {
      return;
    }

    if (diff && diff.length > 0) {
      const studentIds = Object.keys(this.props.data).map(
        regId => this.props.data[regId].studentId
      );
      this.props.fetchVisibleFieldValues(studentIds, diff);
    }
  };
  handleFilter = values => {
    const { programId } = this.props.match.params;
    console.log(values);
    this.setState({ isLoading: true });
    const search = values.search ? values.search.toLowerCase() : null;
    const registrationStatus = values.registrationStatus;
    return this.props
      .fetchRegistrations(
        new Models.Program({ id: programId }),
        search,
        registrationStatus
      )
      .then(result => {
        this.setState({ isLoading: false });
        return result;
      });
  };

  render = () => {
    const { cols, data } = this.props;
    return (
      <Registrations
        cols={cols}
        data={data}
        isLoading={this.state.isLoading}
        handleFilter={this.handleFilter}
        submit={this.props.submit}
      />
    );
  };
}

const mapStateToProps = (state, props) => {
  return {
    business: state.business,
    data: Selectors.getRegistrations(state, props),
    cols: VisibleFieldSelectors.getColumns(
      state,
      Object.assign({ report: "registration" }, props)
    ),
    visibleFields: state.visibleFields,
    fields: state.fields
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRegistrations,
      fetchVisibleFieldValues,
      submit
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationsContainer);
