import React from 'react';
import { Form, Segment } from 'semantic-ui-react';
import { reduxForm, Field } from 'redux-form';
import TextArea from '../../TextArea';
import NumberFormatter from '../../NumberFormatter';
import Dropdown from '../../Dropdown';

const DiscountForm = ({ handleSubmit, submitting }) => (
  <Form onSubmit={handleSubmit}>
    <Segment basic padded>
      <Field name="name" label="Discount Name" component={Form.Input} />
      <Field name="description" label="Description" component={TextArea} />
      <Form.Group widths="equal">
        <Form.Field>
          <label>Discount Value</label>
          <div className="ui input">
            <Field name="value" component={NumberFormatter} />
          </div>
        </Form.Field>
        <Form.Field>
          <label>Discount Unit</label>
          <Field
            name="unit"
            component={Dropdown}
            fluid
            selection
            options={[
              { key: 'percent', value: 'percent', text: '%' },
              { key: 'dollars', value: 'dollars', text: '$' },
            ]}
          />
        </Form.Field>
      </Form.Group>

      <Form.Field>
        <label>Discount Type</label>
        <Field
          name="type"
          component={Dropdown}
          selection
          fluid
          options={[
            {
              key: 'multipleStudent',
              value: 'multipleStudent',
              text: 'Multiple Student',
            },
            {
              key: 'multipleCourse',
              value: 'multipleCourse',
              text: 'Multiple Course',
            },
            { key: 'special', value: 'special', text: 'Special' },
            { key: 'promo', value: 'promo', text: 'Promo Code' },
          ]}
        />
      </Form.Field>

      <Form.Field>
        <label>Can be combined with other discounts</label>
        <Field
          name="combine"
          component={Dropdown}
          selection
          options={[
            { key: 'yes', value: true, text: 'Yes' },
            { key: 'no', value: false, text: 'No' },
          ]}
        />
      </Form.Field>
      <Form.Button loading={submitting} content="Save" primary fluid />
    </Segment>
  </Form>
);

export default reduxForm({
  form: 'discountForm',
})(DiscountForm);
