import React from 'react';
import { Checkbox } from 'semantic-ui-react';

class CheckboxArray extends React.Component {
  handleClick = value => {
    const { input } = this.props;
    const arr = Array.isArray(input.value) ? [...input.value] : [];
    if (arr.indexOf(value) >= 0) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    input.onChange(arr);
  };
  render() {
    const { input, options } = this.props;
    const arr = Array.isArray(input.value) ? input.value : [];
    return (
      <div className="field">
        {options.map(option => {
          const { objectId, name } = option;
          return (
            <div className="field" key={objectId}>
              <Checkbox
                label={name}
                onClick={() => this.handleClick(objectId)}
                checked={arr.indexOf(objectId) >= 0}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default CheckboxArray;
