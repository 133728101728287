import Immutable from "seamless-immutable";
import { normalize } from "normalizr";
import moment from "moment";
import Parse from "parse";
import { createSelector } from "reselect";

import { SubmissionError } from "redux-form";
import schema from "../schema";
import Models from "../parse/models";
import { TransactionType } from "../parse/models/enums";

const ActionTypes = {
  ADD_TRANSACTION: "ADD_TRANSACTION",
  UPDATE_TRANSACTION: "UPDATE_TRANSACTION",
  RECEIVED_TRANSACTIONS: "RECEIVED_TRANSACTIONS"
};

const Private = {
  getTransactions: state => state.transactions,
  getTransactionItems: state => state.transactionItems,
  getRegistrations: state => state.registrations,
  getPrograms: state => state.programs,
  getAccounts: state => state.accounts,
  getUsers: state => state.users,
  getStudents: state => state.students,
  createTransactionsSummary: (
    transactions,
    transactionItems,
    registrations,
    programs,
    accounts,
    users,
    students
  ) => {
    let results = {};
    Object.keys(transactions).forEach(objectId => {
      const transaction = transactions[objectId];
      const { amount, amountPaid, amountRemaining, currency } = transaction;
      // console.log(accounts);
      // console.log('TRANSACTiONS: ', transaction);
      const account = accounts[transaction.account];
      const user = users[account.user];

      const dueDate = transaction.dueDate || " - ";
      // console.log(users, account.user);
      const accountName = user.name;
      // transaction = transactions[objectId].status ===

      // console.log('ONE TRANSACTIONS TRANSACTION ITEMS: ', transactionItems);
      const tis = transaction.transactionItems || [];
      const descriptions = tis.map(transactionItemId => {
        let itemDescription = "";
        const transactionItem = transactionItems[transactionItemId];
        // console.log(transactionItem);
        // const type = transactionItem.
        const student = students[transactionItem.student];
        const studentName = student.name;
        const program = programs[transactionItem.program];
        // const registration = registrations[transactionItem.registration];

        // console.log(' TRANSACTION ITEM: ', transactionItem);

        if (transactionItem.type === "deposit") {
          itemDescription = `${studentName} - ${program.name} Deposit`;
        } else if (transactionItem.type === "program") {
          itemDescription = `${studentName} - ${program.name}`;
        }
        return itemDescription;
      });

      let type = "Sale";
      if (transactions[objectId].type === TransactionType.manualInvoice) {
        type = "Invoice";
      } else if (
        transactions[objectId].type === TransactionType.subscriptionInvoice
      ) {
        type = "Invoice";
      }

      // reduce(transactionItems, (sum, item) => sum + item.amount, 0);
      // console.log(transactions[objectId]);
      const createdAt = moment(transactions[objectId].createdAt).format(
        "MMM D, YYYY"
      );
      results[objectId] = {
        objectId,
        accountName,
        description: descriptions.join(", "),
        amount: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency.toUpperCase()
        }).format(amount / 100),
        amountRemaining: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency.toUpperCase()
        }).format(amountRemaining / 100),
        amountPaid: new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency.toUpperCase()
        }).format(amountPaid / 100),
        type,
        createdAt,
        dueDate
        // status,
      };
    });
    return results;
  }
};

const Selectors = {
  getTransactions: createSelector(
    [
      Private.getTransactions,
      Private.getTransactionItems,
      Private.getRegistrations,
      Private.getPrograms,
      Private.getAccounts,
      Private.getUsers,
      Private.getStudents
    ],
    Private.createTransactionsSummary
  )
};

const Actions = {
  creators: {
    addTransaction: data => ({
      data,
      type: ActionTypes.ADD_TRANSACTION
    }),
    updateTransaction: data => ({
      data,
      type: ActionTypes.UPDATE_TRANSACTION
    }),
    receivedTransactions: data => ({
      data,
      type: ActionTypes.RECEIVED_TRANSACTIONS
    })
  },
  thunks: {
    addTransaction: data => async dispatch => {
      try {
        const transaction = new Models.Transaction();
        await transaction.save(data);
        const result = normalize(transaction.toJSON(), schema.transaction);
        dispatch(Actions.creators.addTransaction(result));
        return transaction.toJSON();
      } catch (error) {
        const err = {
          _error: error.message
        };
        throw new SubmissionError(err);
      }
    },
    updateTransaction: (transaction, transactionItems) => async dispatch => {
      await Parse.Object.saveAll(transactionItems);
      transaction.set("transactionItems", transactionItems);
      await transaction.save();
      // await transaction.save(data);
      const result = normalize(transaction.toJSON(), schema.transaction);
      dispatch(Actions.creators.addTransaction(result));
      return transaction.toJSON();
    },
    fetchTransactions: business => async dispatch => {
      try {
        const query = new Parse.Query(Models.Transaction);
        query.equalTo("business", business);
        query.include("transactionItems");
        query.include("payments");
        query.include("transactionItems.registration");
        query.include("transactionItems.student");
        query.include("transactionItems.program");
        query.include("account");
        query.include("account.user");
        const transactions = await query.find();
        return dispatch(
          Actions.creators.receivedTransactions(
            normalize(
              transactions.map(transaction => transaction.toJSON()),
              schema.transactions
            )
          )
        );
      } catch (error) {
        // const err = {
        //   _error: error.message,
        // };
        console.error(error);
      }
    }
  }
};

export { Actions, Selectors };

const defaultState = Immutable({});
export default (state = defaultState, action) => {
  if (
    action &&
    action.data &&
    action.data.entities &&
    action.data.entities.transactions
  ) {
    return state.merge(action.data.entities.transactions);
  }
  return state;
};
