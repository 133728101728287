import DefaultCellRenderer from '../Spreadsheet/DefultCellRenderer';
import TransactionEditCellRenderer from './TransactionEditCellRenderer';

const cols = [
  {
    dataKey: 'createdAt',
    label: 'Date',
    width: 90,
    minWidth: 90,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'accountName',
    label: 'Account Name',
    width: 130,
    minWidth: 130,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'type',
    label: 'Type',
    width: 90,
    minWidth: 90,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'description',
    label: 'Description',
    minWidth: 90,
    flexGrow: 1,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'dueDate',
    label: 'Due Date',
    width: 90,
    minWidth: 90,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'amount',
    label: 'Amount Due',
    width: 90,
    minWidth: 90,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'amountPaid',
    label: 'Amount Paid',
    width: 90,
    minWidth: 90,
    cellRenderer: DefaultCellRenderer,
  },
  {
    dataKey: 'objectId',
    label: 'Actions',
    width: 160,
    minWidth: 160,
    cellRenderer: TransactionEditCellRenderer,
    className: 'edit-cell-no-overflow',
    handleCellChange: true,
    // flexGrow: 1,
  },
];

export default cols;
