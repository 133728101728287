import Immutable from "seamless-immutable";
import { normalize } from "normalizr";
import Parse from "parse";
import { SubmissionError } from "redux-form"; // ES6
import Models from "../parse/models";
import schema from "../schema";
import validate from "../utils/validate";

export const ActionTypes = {
  HYDRATE: "HYDRATE",
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  SIGNUP_USER: "SIGNUP_USER",
  SIGNUP_BUSINESS_USER: "SIGNUP_BUSINESS_USER",
  FETCH_STAFF_USER: "FETCH_STAFF_USER"
};

const dataPackage = (currentAccount, currentStaff) => {
  const data = {
    currentStaff: currentStaff ? currentStaff.toJSON() : null,
    currentAccount: currentAccount ? currentAccount.toJSON() : null
  };
  return data;
};

const Actions = {
  creators: {
    hydrate: data => ({
      data,
      type: ActionTypes.HYDRATE
    }),
    logoutUser: data => ({
      data,
      type: ActionTypes.LOGOUT_USER
    }),
    loginUser: data => ({
      data,
      type: ActionTypes.LOGIN_USER
    }),
    signupUser: data => ({
      data,
      type: ActionTypes.SIGNUP_USER
    }),
    signupBusinessUser: data => ({
      data,
      type: ActionTypes.SIGNUP_BUSINESS_USER
    })
  },
  thunks: {
    logoutUser: () => async dispatch => {
      await Parse.User.logOut();
      dispatch(Actions.creators.logoutUser({}));
    },
    loginUser: ({ business, email, password }) => async dispatch => {
      const validEmail = validate.email(email);
      const validPw = validate.required(password);
      if (!validEmail) {
        throw new SubmissionError({
          _error: "Please enter your email address"
        });
      } else if (!validPw) {
        throw new SubmissionError({ _error: "Please enter your password" });
      }

      try {
        // await Actions.thunks.logoutUser()(dispatch);
        const token = await Parse.Cloud.run("login", {
          username: email,
          password
        });
        const user = await Parse.User.become(token);
        // const user = await Parse.User.logIn(email, password);
        if (user) {
          const params = {
            businessId: business && business.objectId ? business.objectId : null
          };
          const result = await Parse.Cloud.run("loginHelper", params);

          const { currentAccount, currentStaff } = result;
          const data = normalize(
            dataPackage(currentAccount, currentStaff),
            schema.session
          );
          dispatch(Actions.creators.loginUser(data));
          return user;
        }
      } catch (error) {
        console.log("ERROR: ", error);
        const err = {
          _error: error.message
        };
        throw new SubmissionError(err);
      }
    },

    signupUser: ({
      business,
      firstName,
      lastName,
      phone,
      email,
      password
    }) => async dispatch => {
      try {
        const user = new Parse.User();
        await user.signUp({
          firstName,
          lastName,
          phone: phone ? phone.toString() : null,
          email,
          password,
          username: email.toLocaleLowerCase()
        });
        const bus = new Models.Business({ id: business.objectId });
        const account = new Models.Account();
        account.set("user", new Models.User({ id: user.id }));
        account.set("business", bus);
        account.set("active", true);
        await account.save();
        const data = normalize(dataPackage(account), schema.session);
        dispatch(Actions.creators.signupUser(data));

        // Pendo
        // window.pendo.initialize({
        //   visitor: {
        //     id: user.id, // Required if user is logged in
        //     email: user.get('email'), // Recommended if using Pendo Feedback, or NPS Email
        //     full_name: user.get('firstName') + ' ' + user.get('lastName'), // Recommended if using Pendo Feedback
        //     role: 'customer',
        //   },

        //   account: {
        //     id: business.id, // Highly recommended
        //     name: business.get('name'), // Optional
        //     // is_paying:    // Recommended if using Pendo Feedback
        //     // monthly_value:// Recommended if using Pendo Feedback
        //     // planLevel:    // Optional
        //     // planPrice:    // Optional
        //     creationDate: business.createdAt,

        //     // You can add any additional account level key-values here,
        //     // as long as it's not one of the above reserved names.
        //   },
        // });

        return user;
      } catch (error) {
        const err = {
          _error: error.message
        };
        throw new SubmissionError(err);
      }
    }
  }
};

export { Actions };

const user = Parse.User.current()
  ? Immutable(Parse.User.current().toJSON())
  : {};
const defaultState = user;
export default (state = defaultState, action) => {
  if (action.type === ActionTypes.LOGIN_USER) {
    console.log("CURRENT USER");
    if (Parse.User.current()) {
      return Immutable(Parse.User.current().toJSON());
    }
  } else if (action.type === ActionTypes.HYDRATE && action.data.currentUser) {
    if (Parse.User.current()) {
      return Immutable(Parse.User.current().toJSON());
    }
  } else if (action.type === ActionTypes.SIGNUP_USER) {
    if (Parse.User.current()) {
      return Immutable(Parse.User.current().toJSON());
    }
  } else if (action.type === ActionTypes.SIGNUP_BUSINESS_USER) {
    if (Parse.User.current()) {
      return Immutable(Parse.User.current().toJSON());
    }
  } else if (action.type === ActionTypes.LOGOUT_USER) {
    return Immutable({});
  }
  return state;
};
