import React from "react";
import { reduxForm } from "redux-form";
import { Container, Form, Segment, Button } from "semantic-ui-react";
import ProgramDescription from "./ProgramDescription";
import ProgramRegistrationSettings from "./ProgramRegistrationSettings";
import ProgramForms from "./ProgramForms";
import ProgramDiscounts from "./ProgramDiscounts";
import ProgramFees from "./ProgramFees";
import ProgramBillingContainer from "./ProgramBillingContainer";
import ProgramChoicesContainer from "./ProgramChoicesContainer";
import ProgramScheduleContainer from "./ProgramScheduleContainer";

const ProgramSetup = props => {
  console.log("THIS PROPS: ", props);
  const { submitting } = props;
  return (
    <Container fluid>
      <Segment basic inverted color="grey">
        <Container text>
          <Form onSubmit={props.handleSubmit} loading={submitting}>
            <ProgramDescription />
            <ProgramScheduleContainer />
            <ProgramRegistrationSettings />
            <ProgramBillingContainer />
            <ProgramChoicesContainer />
            <ProgramDiscounts />
            <ProgramFees />
            <ProgramForms />
            <Container>
              <Container>
                <Segment basic className="place-holder" textAlign="left">
                  <Button
                    loading={props.submitting}
                    size="large"
                    primary
                    type="submit"
                  >
                    Save
                  </Button>
                </Segment>
              </Container>
            </Container>
          </Form>
        </Container>
      </Segment>
    </Container>
  );
};

export default reduxForm({ form: "programSetup", enableReinitialize: true })(
  ProgramSetup
);
