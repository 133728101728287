import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoginForm from "./LoginForm";
import { Actions } from "../../../ducks/currentUser";

const mapStateToProps = state => {
  return {
    currentAccount: state.currentAccount,
    currentStaff: state.currentStaff,
    enableReinitialize: true,
    initialValues: {
      business: state.business
      // email: "lauren@gmail.com",
      // password: "community"
      // email: "basedemo518@gmail.com",
      // password: "community"
    }
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators({ onSubmit: Actions.thunks.loginUser }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
